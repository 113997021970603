import {MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow} from "mdbreact";
import React, {useState} from "react";
import {Calendar} from "primereact/calendar";
import { BACK_URL } from "../services/ApiService";


export default function (props) {
    let [dates, setDates] = useState([])
    let new_date = (dates) => {
        let first_date = dates[0].getFullYear() + "-" + (+dates[0].getMonth() + 1) + "-" + dates[0].getDate();
        if (dates[1]) {
            let second_date = dates[1].getFullYear() + "-" + (+dates[1].getMonth() + 1) + "-" + dates[1].getDate();
            return [first_date, second_date]
        }
        return [first_date, first_date]
    }
    let getStat = async (dates) => {
        const res = await fetch(`${BACK_URL}/stats/att`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(new_date(dates)),
        }).catch( (e) => { console.log(e); throw(e) });
        if (await res.text() === "NoData") {
            alert("В данный период нет записей")
        } else {
            window.location = "/stats/att"
        }
    }
    let ru = {
        firstDayOfWeek: 1,
        dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
        dayNamesMin: ["В", "П", "В", "С", "Ч", "П", "С"],
        dayNamesShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
        monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    }
    return (
        <MDBCard color="info-color" text="white" className="" >
        <MDBCardBody>
        <MDBCardTitle>Аттетации</MDBCardTitle>
            <MDBRow>
                <MDBCol sm="7">
                    <Calendar readOnlyInput selectionMode="range" placeholder="Диапазон дат" maxDate={new Date()} locale="ru" id="dataPicker" inputStyle={{width: "100%"}} dateFormat="dd.mm.yy" value={dates} onChange={(e) => setDates(e.value)} className="my-1 w-100"/>
                </MDBCol>

                <MDBCol sm="5" className="text-center"  >
                    <MDBBtn color="white" disabled={dates.length !== 2 ? true : false} size="sm" className="w-100 mx-0" onClick={ () => getStat(dates)}>Выгрузить</MDBBtn>
                </MDBCol>
            </MDBRow>
        </MDBCardBody>
        </MDBCard>
    )
}
