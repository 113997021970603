import {MDBCol, MDBIcon, MDBTable, MDBTableBody} from "mdbreact";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import {InputSwitch} from "primereact/inputswitch";
import React from "react";
const DragHandle = sortableHandle(() => <span style={{cursor: "row-resize"}}>
    <MDBIcon icon="arrows-alt-v grey-text mt-1 ml-2" size="2x" className=""/>
</span>);

const SortableItem = sortableElement(({name, is_mandatory, value, setDefault_is_mandatory}) => (
    <tr style={{zIndex: 1060, }}>
        <td style={{maxWidth: 50, minWidth: 40, verticalAlign: "middle"}} className="p-0"><DragHandle /></td>
        <td style={{minWidth: 100, verticalAlign: "middle", fontSize: "12pt"}} className="align-items-center p-0">{name}</td>
        {
            setDefault_is_mandatory ?
            <td style={{minWidth: 100, verticalAlign: "middle"}} className="p-0">
                <InputSwitch id="switcher" className="mt-1"
                           checked={is_mandatory}
                           onChange={(e) => setDefault_is_mandatory(value, e.value)}/>
            </td> : ''
        }
    </tr>
));

const SortableContainer = sortableContainer(({children}) => {
    return <MDBTableBody>{children}</MDBTableBody>;
});

export default (props) => {
    return (
        <MDBCol size="10" className="align-middle unselectable">
            <MDBTable striped small>
                <SortableContainer useDragHandle onSortEnd={props.onSortEnd} lockAxis="y">
                    { props.internship_courses.map( (value, index) => (
                        <SortableItem key={`item-${index}`} index={index} value={index} setDefault_is_mandatory={props.setDefault_is_mandatory}
                                      name={value.structured_course_name}
                                      is_mandatory={value.default_is_mandatory}
                        />
                    ))}
                </SortableContainer>
            </MDBTable>
        </MDBCol>
    )
}
