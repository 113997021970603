import React, {useEffect, useState} from "react";
import {
    MDBBtn,
    MDBListGroup, MDBListGroupItem,
    MDBModal, MDBModalBody,
    MDBModalFooter, MDBModalHeader
} from "mdbreact";
import WelcomeSender from "../../WelcomeSender";
import ApiService from "../../../services/ApiService";


function createMarkup(html) {
    return {__html: html.replace(/\n/ig, "<br>")}
}


let ListGroupItem = (props) => {
    return (
        <MDBListGroupItem hover className="border-0 mt-3 z-depth-1 p-2">
            {/*<img src={props.file} alt="" className="img-fluid mb-1"/>*/}
            <span dangerouslySetInnerHTML={createMarkup(props.text)}/>
        </MDBListGroupItem>
    )
}


export default (props) => {
    let [welcomeMessages, setWelcomeMessages] = useState([])
    let [reload, setReload] = useState(false);

    useEffect( () => {
        let api = new ApiService()
        let get_data = async () => {
            return await api.getWelcomeMassages(props.selectedPosition?.position_id)
        }
        if (props.selectedPosition?.position_id) {
            get_data()
                .then((data) => setWelcomeMessages(data))
        }
    }, [props.selectedPosition?.position_id, reload]);

    return (
        <MDBModal isOpen={props.show} toggle={props.toggle} fullHeight position="right">
            <MDBModalHeader>Сообщение при стажировке</MDBModalHeader>
            <MDBModalBody>
                <WelcomeSender selectedPosition={props.selectedPosition?.position_id} reload={() => setReload(!reload)} addedWelcomeMassage={props.addedWelcomeMassage} intern={true}/>
                <MDBListGroup className="">
                    {
                        welcomeMessages.map( (item, index) => (
                            <ListGroupItem text={item.welcome_to_intern} key={index}/>
                        ))
                    }
                </MDBListGroup>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="secondary" size="sm" onClick={() => props.toggle(false)}>Закрыть</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    )
}
