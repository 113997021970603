import React from 'react';
import ReactDOM from 'react-dom';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';


import './index.css';
import App from './App';
import { BACK_URL } from "./services/ApiService";

window.localization =  {
    pagination: {
        labelDisplayedRows: '{from}-{to} из {count}'
    },
    grouping: {
        placeholder: "Перетащите заголовок сюда для группировки",
        groupedBy: "Групировать по:"
    },
    toolbar: {
        nRowsSelected: '{0} строк выбрано',
        searchTooltip: "Поиск",
        searchPlaceholder: "Поиск",
        exportTitle: "Экспорт",
        exportAriaLabel: "Экспорт",
        exportName: "Экспорт в CSV"
    },
    header: {
        actions: ''
    },
    body: {
        deleteTooltip: "Удалить",
        editRow: {
            deleteText: "Подтвердите удаление!"
        },
        emptyDataSourceMessage: "Загрузка данных"
    }
};

const client = new ApolloClient({
    uri: `${BACK_URL}/graphql`,
    cache: new InMemoryCache(),
    credentials: 'include',
});

ReactDOM.render(<ApolloProvider client={client}><Router><App /></Router></ApolloProvider>, document.getElementById('root'));
