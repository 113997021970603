import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCol, MDBIcon,
    MDBListGroup, MDBListGroupItem,
    MDBModal,
    MDBModalBody, MDBModalFooter,
    MDBModalHeader,
    MDBRow,
} from "mdbreact";
import ApiService, { BACK_URL } from "../../../services/ApiService";
import Panels from "../../Panels";
import "../../global_components.css"

const name_dish_class = (<>Назовите <strong>блюдо</strong></>);
const ingr = (<>Назовите недостающий <strong>ингредиент</strong></>);
function ImageComponent(props) {
    const [link, setLink] = useState();

    useEffect(() => {
        if (props.image) {
            props.image.indexOf('https://sushiwok') >= 0 ?
                setLink(props.image)
                :
                fetch(props.image, {
                    method: "GET",
                    headers: {
                        "Authorization": 'Basic T1RHQURcc2VydmljZXVzZXI6T2hqYWg3a3U=',
                    }
                }).catch(e => console.log(e))
                    .then((data) => data.blob())
                    .catch(e => console.log(e))
                    .then((img) => {
                        if (img) {
                            let reader = new FileReader();

                            reader.readAsDataURL(img);

                            reader.onload = function () {
                                setLink(reader.result);
                            };

                            reader.onerror = function () {
                                console.log(reader.error);
                            };
                        }
                    })
        }
    }, [props.image]);
    return (
        <img src={BACK_URL + link} className={"img-fluid " + props.className} alt=""/>
    )
}

export default function (props) {
    let [questions, setQuestions] = useState([]);

    useEffect(() => {
        let api = new ApiService();
        async function get() {
            let {data: { CertificationSessions }} = await api.getCertificationSessionById(props.certification);
            setQuestions(CertificationSessions[0].questions);
        }
        if (props.certification) {
            get()
        }
    }, [props.certification]);

    return (
        <MDBModal isOpen={props.modalIsOpen} toggle={() => props.setModalIsOpen(0)} size="lg">
            <MDBModalHeader toggle={() => props.setModalIsOpen(0)} className="py-1">Вопросы аттестации</MDBModalHeader>
            <MDBModalBody className="p-0">
                <MDBListGroup className="w-100">
                    {
                        questions.map( (item, index) => (
                            <MDBListGroupItem key={index} >
                                <MDBRow className={"border-left border_for_att_inf " +  (Boolean(item.is_correct) ? "border-success" : "border-danger")}>
                                    <MDBCol size="4" className={item.image ? "" : "d-none"}>
                                        <ImageComponent image={item.image}/>
                                    </MDBCol>
                                    <MDBCol size={item.image ? "8" : "12"} className={item.image ? "pr-4" : ""}>
                                        <MDBRow>
                                            <MDBCol xl="12" className="px-1">
                                                <Panels text={
                                                    item.mode !== "custom" ?
                                                        (<>
                                                            <h4 className="border-bottom">⁉️ { item.mode === "name_dish" ? name_dish_class : ingr }</h4>
                                                            <h5>{item.question}</h5>
                                                        </>)
                                                        :
                                                        <h4 className="border-bottom">{item.question}</h4>
                                                } className={"z-depth-0 border"} textAlign="left"/>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="mt-1 px-1">
                                            {
                                                item.answers.map( (value, index) => (
                                                    <MDBCol key={index} xl={value.answer.length < 60 ? "6" : "12"} lg={value.answer.length < 60 ? "6" : "12"} size="12" className="px-1">
                                                        {
                                                            item.correct_answers.includes(value.replyCode) ?
                                                                <MDBIcon icon="check" className="position-absolute green-text" style={{ zIndex: 100, left: "-0.3%", top: "-6%"}}/>
                                                                :
                                                                ""
                                                        }
                                                        <Panels text={value.answer} border={
                                                            (item.correct_answers.includes(value.replyCode) && item.user_answers?.includes(value.replyCode)) ?
                                                                "border-success" :
                                                                (item.user_answers?.includes(value.replyCode) && (item.correct_answers.includes(value.replyCode) == false))
                                                                    ? "border-danger" : ""
                                                        } className={"mt-1 z-depth-0 border"}/>
                                                    </MDBCol>
                                                ))
                                            }
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </MDBListGroupItem>
                        ))
                    }
                </MDBListGroup>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="secondary" size="sm" onClick={() => props.setModalIsOpen(0)}>Закрыть</MDBBtn>
            </MDBModalFooter>
        </MDBModal>)
}
