import React, {useState} from 'react';
import './sidebar.css'
import {
    MDBCol,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon,
    MDBNavItem,
    MDBNavLink, MDBRow
} from "mdbreact";
import LogPopover from "../LogPopover/LogPopover";
import {useHistory} from "react-router-dom";

export default function SideBar(props) {
    let [pathname, setPathname] = useState();
    let [isOpen, setIsOpen] = useState(false);
    let history = useHistory()

    return (
        <div className="side-nav bg-white fixed px-3">
            <img src={process.env.PUBLIC_URL + '/logo.png'} className="img-fluid z-depth-2 my-3"/>
            <MDBRow className="p-0 pb-1 border-bottom">
                <MDBCol size="6">
                    <h3 className="text-warning font-weight-bold pt-1 mb-0">{props.region}</h3>
                </MDBCol>
                <MDBCol size="6" className="text-right">
                    <LogPopover className="pr-0 mt-1 py-0 m-0 z-depth-0" size="3x" placement="right" sideBar/>
                </MDBCol>
            </MDBRow>

            <MDBRow className="my-2">
                <MDBCol size="12">
                    <ul className="nav nav-pills flex-column">
                        {
                            props.routes.map( (val, index ) => {
                                if (val.children.length === 0) {
                                    return (
                                        <MDBNavItem key={index} >
                                            <MDBNavLink to={val.route.route_path} >
                                                <i className={val.route.route_icon + " mr-3"}/>{val.route.route_name}
                                            </MDBNavLink>
                                        </MDBNavItem>
                                    )
                                } else {
                                    let chPaths = {};
                                    val.children.map( (val) => {
                                        chPaths = { [val.route_path] : 1, ...chPaths}
                                    });
                                    return (
                                        <MDBDropdown key={index}>
                                            <MDBDropdownToggle nav caret style={pathname in chPaths ? {backgroundColor: "#e5e6e5"} : {}}>
                                                <i className={val.route.route_icon + " mr-3"}/>{val.route.route_name}
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu>
                                                {
                                                    val.children.map( (val, index) => (
                                                        <MDBDropdownItem className="py-0 px-2" key={index}>
                                                            <MDBNavLink to={val.route_path}
                                                                        className="text-dark py-1 px-2"
                                                                        onClick={ () => setPathname(val.route_path)}>
                                                                {val.route_name}
                                                            </MDBNavLink>
                                                        </MDBDropdownItem>
                                                    ))
                                                }
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                    )
                                }
                            })
                        }
                    </ul>
                </MDBCol>
            </MDBRow>

            <MDBRow className="my-2 pt-3 border-top">
                <MDBCol size="12" onClick={() => history.push("/faq")} className="text-info pointer">
                        <MDBIcon icon="headset" className="mr-2"/>Помощь
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol size="12" className="pt-1 text-danger pointer" onClick={() => props.signOut()} >
                        <i className="fas fa-sign-out-alt mr-2 exit"/>Выход
                </MDBCol>
            </MDBRow>
        </div>
    );
}

