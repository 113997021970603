// Type из eventLog, меняем только если изменили в backend
export const
    COURSE_TYPE = 'course',
    VIDEO_TYPE = 'video',
    CUSTOM_TYPE = 'custom',
    MENU_TYPE = 'menu',
    SteadyControl_Register_TYPE = 'SteadyControl (registration)',
    SteadyControl_Message_TYPE = 'SteadyControl (sendMessage)',
    MAILING_TYPE = 'mailing',
    MAILING_1C_TYPE = 'mailing (1C)',
    SHOP_TYPE = 'shop',
    USER_TYPE = 'user',
    ATTESTATION_TYPE = 'attestation',
    INCREASE_TYPE = 'increase',
    DECREASE_TYPE = 'decrease',
    MULTIPLAYER_TYPE = 'multiplayer'

// Названия Типов логов в профиле пользователя, можно изменять будет влиять на отображение на front
export const
    COURSE = 'Курсы',
    VIDEO = 'Видео',
    CUSTOM = 'Функционал',
    MENU = 'Меню',
    SteadyControl_Register = 'SC - registration',
    SteadyControl_Message = 'SC - sendMessage',
    MAILING = 'Рассылки',
    MAILING_1C = '1C',
    SHOP = 'Магазин',
    USER = 'Пользователь',
    ATTESTATION = 'Аттестация',
    COOKIE = 'Печеньки',
    MULTIPLAYER = 'Батл'

// Название Типов для настроек региона
export const
   IS_SHOP = 'shop',
   IS_MENU = 'menu',
   IS_STEADY_CONTROL = 'steady_control',
   IS_MAILING_SALES = 'mailing_sales',
   IS_ATTESTATION = 'attestation',
   IS_DELETE_INACTIVE = 'delete_inactive'

