import { Link } from "react-router-dom";
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBContainer,
    MDBBtn,
    MDBIcon,
} from "mdbreact";
import './NavBarCSS.css';
import LogPopover from "../LogPopover/LogPopover";
import React, {Component} from "react";
import { BACK_URL } from "../../../services/ApiService";


class NavBar extends Component {

    state = {
        isOpen: false,
        pathname: window.location.pathname
    };

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    onButtonClick = () => {
        fetch(`${BACK_URL}/sing_out`, {
            credentials: 'include',
            method: 'POST',
        }).then((res) => {
            window.location.reload()
        })
    };
    onNavLincClick = (pathname) => {
        this.setState( {pathname})
    };

    render() {
        return (
            <MDBNavbar fixed="top" expand="lg" color="white" scrolling ligth="true" className="navbar">
                <MDBContainer fluid>
                    <MDBNavbarBrand>
                        <strong className="blue-text font-weight-bolder">SW-Bot: <span className="text-warning">{this.props.region}</span></strong>
                    </MDBNavbarBrand>

                    <MDBNavbarToggler onClick={this.toggleCollapse} className="toggle"/>

                    <MDBCollapse isOpen={this.state.isOpen} navbar>
                        <MDBNavbarNav left>
                            {
                                this.props.routes.map( (val, index ) => {
                                    if (val.children.length === 0) {
                                        return (<MDBNavItem key={index}
                                            style={this.state.pathname === val.route.route_path ? {backgroundColor: "#e5e6e5"} : {}}>
                                            <MDBNavLink to={val.route.route_path}
                                                        className="text-dark" onClick={ () => { this.onNavLincClick(val.route.route_path) } }>{val.route.route_name}</MDBNavLink>
                                        </MDBNavItem>)
                                    } else {
                                        let chPaths = {};
                                        val.children.map( (val) => {
                                            chPaths = { [val.route_path] : 1, ...chPaths}
                                        });
                                        return (
                                            <MDBNavItem key={index} style={this.state.pathname in chPaths ? {backgroundColor: "#e5e6e5"} : {}}>
                                                <MDBDropdown>
                                                    <MDBDropdownToggle nav caret className="text-dark">
                                                        <div className="d-inline">{val.route.route_name}</div>
                                                    </MDBDropdownToggle>
                                                    <MDBDropdownMenu>
                                                        {
                                                            val.children.map( (val, index) => (
                                                                <MDBDropdownItem key={index}><MDBNavLink to={val.route_path}
                                                                                             className="text-dark py-0"
                                                                                             onClick={() => { this.onNavLincClick(val.route_path)}}>
                                                                    {val.route_name}
                                                                </MDBNavLink></MDBDropdownItem>
                                                            ))
                                                        }
                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                            </MDBNavItem>
                                        )
                                    }
                                })
                            }
                        </MDBNavbarNav>
                        <MDBNavbarNav right>
                            <MDBNavItem onClick={() => this.onNavLincClick('/faq')}>
                                <MDBBtn className="my-0 px-2 py-1 mt-1 z-depth-0 text-warning" color="" size="sm">
                                    <Link to="/faq" >Помощь</Link>
                                </MDBBtn>
                            </MDBNavItem>
                            <MDBNavItem>
                                <LogPopover className="my-0 py-1 px-2 mr-2 z-depth-0" size="2x" placement="bottom"/>
                            </MDBNavItem>
                            <MDBNavItem onClick={() => this.props.signOut()}>
                                <MDBBtn className="my-0 py-1 px-2 z-depth-0 text-danger" color="" size="sm">
                                    <MDBIcon icon="sign-out-alt" size="2x" className=""/>
                                </MDBBtn>
                            </MDBNavItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        );
    }
}

export default NavBar;
