import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from 'antd';

export default function InfoPopup({text}) {

    return (
        <Tooltip placement="top" title={text} >
            <InfoIcon />
        </Tooltip>
    );
};
