import React, { useEffect, useState } from "react";
import { MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import ApiService, { BACK_URL } from "../services/ApiService";
import Panels from "./Panels";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function createMarkup(html) {
  if (html === null) return;
  return { __html: html.replace(/\n/gi, "<br>") };
}

const api = new ApiService();
export default (props) => {
  let [questions, setQuestions] = useState([]);
  let [hasBeen, setHasBeen] = useState(false);
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let getData = async () => {
      if (props.day) {
        let data = await api.getStructuredQuestions(
          props.day.structured_day_id
        );
        setQuestions(data.data.StructuredQuestions);
        setIsLoading(false);
      }
    };
    getData();
  }, [props.day?.structured_day_id, props.reloadQuestions]);

  const onDeleteCourse = async (oldData) => {
    try {
      if (!window.confirm("Вы точно хотите удалить ?")) {
        return;
      }
      let res = await api.removeStructuredQuestions(
        oldData.structured_question_id
      );
      if (res) {
        let data = questions.filter(
          (value) =>
            value.structured_question_id !== oldData.structured_question_id
        );
        setQuestions(data);
        props.addNotifications("Вопрос успешно удален!");
      } else {
        props.addNotifications("Произошла ошибка!");
      }
    } catch (e) {
      props.addNotifications("Произошла ошибка!");
    }
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Перетаскивание было отменено
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const updatedQuestions = [...questions];
    const [movedItem] = updatedQuestions.splice(startIndex, 1);
    updatedQuestions.splice(endIndex, 0, movedItem);

    if (JSON.stringify(updatedQuestions) !== JSON.stringify(questions)) {
      // Проверка на изменение
      setHasBeen(true);
    }

    updatedQuestions.map((item, index) => {
      // Присваиваем новый индекс элементам
      item.element_index = index;
    });
    setQuestions(updatedQuestions);
  };

  const onUpdateIndexCourses = async () => {
    for (const item of questions) {
      await api
        .editIndexStructuredQuestion({
          structured_question_id: +item.structured_question_id,
          element_index: +item.element_index,
        })
        .catch((e) => {
          props.addNotifications("Произошла ошибка " + e);
        });
    }
    setHasBeen(false);
    props.addNotifications("Успешно изменен порядок");
  };

  return (
    <>
      <MDBCardTitle className="p-3">
        <MDBIcon
          icon="angle-left"
          className="mr-3"
          style={{ cursor: "pointer", fontSize: "1.25rem" }}
          onClick={() => {
            props.backButton(2);
          }}
        />
        {props.day?.structured_day_name}
        <MDBIcon
          fas
          icon="plus"
          onClick={() => {
            props.showQuestionModal(questions, props.day.structured_day_id);
          }}
          style={{ cursor: "pointer", fontSize: "1.25rem" }}
          className="ml-3"
        />
        <MDBIcon
          fas
          icon="sync-alt"
          onClick={() => onUpdateIndexCourses()}
          style={{
            cursor: "pointer",
            fontSize: "1.25rem",
            color: hasBeen ? "red" : "",
          }}
          className="ml-4"
        />
      </MDBCardTitle>

      <DragDropContext onDragEnd={(res) => onDragEnd(res)}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {questions.map((item, index) => (
                <Draggable
                  key={item.structured_question_id}
                  draggableId={item.structured_question_id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MDBRow
                        className="align-items-center justify-content-center"
                        style={{
                          width: "100%",
                          minHeight: "150px",
                          marginBottom: "10px",
                        }}
                      >
                        <MDBCol md="1">
                          <MDBCardBody className="d-flex align-items-center justify-content-center">
                            <MDBIcon
                              fas
                              icon="align-justify"
                              className="mr-3"
                              style={{ width: 30, height: 30 }}
                            />
                            <MDBIcon
                              fas
                              icon="pen"
                              style={{
                                cursor: "pointer",
                                width: 30,
                                height: 30,
                              }}
                              onClick={() => {
                                props.setSelectedQuestion(item);
                                props.setShowModal(true);
                              }}
                            />
                            <MDBIcon
                              fas
                              icon="trash-alt"
                              style={{
                                cursor: "pointer",
                                width: 30,
                                height: 30,
                              }}
                              onClick={() => onDeleteCourse(item)}
                            />
                          </MDBCardBody>
                        </MDBCol>
                        <MDBCol
                          md="2"
                          style={{
                            background:
                              !item.file_id && item.file_path
                                ? "rgba(255,141,141,0.44)"
                                : "",
                          }}
                        >
                          <MDBCardBody>
                            {item.file_path ? (
                              <img
                                src={BACK_URL + item.file_path}
                                style={{ width: "80%", borderRadius: "10%" }}
                              />
                            ) : (
                              ""
                            )}
                          </MDBCardBody>
                        </MDBCol>
                        <MDBCol md="5">
                          <MDBCardBody style={{
                            backgroundColor:
                              item.is_editable === 1
                                ? "rgba(240,182,107,0.74)"
                                : "",
                          }}>
                            <span
                              dangerouslySetInnerHTML={createMarkup(
                                item.question
                              )}
                            />
                          </MDBCardBody>
                        </MDBCol>
                        <MDBCol md="4">
                          <MDBCardBody>
                            {item.answers.map((value2, index) => (
                              <Panels
                                text={value2}
                                key={index}
                                border={
                                  item.right_answers === index
                                    ? "border-success"
                                    : ""
                                }
                                className={
                                  index > 0
                                    ? "mt-1 z-depth-0 border"
                                    : "z-depth-0 border"
                                }
                              />
                            ))}
                          </MDBCardBody>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
