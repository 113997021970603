import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBContainer,
    MDBIcon
} from "mdbreact";

import React, {useState} from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

let Panels = (props) => (
    <MDBCard color={props.color ? props.color : "info-color"} text="white" className={"text-center p-0 z-depth-0 " + props.className}>
        <MDBCardBody className="p-1">
            {props.children}
        </MDBCardBody>
    </MDBCard>
)

export default function (props) {
    let [index, setIndex] = useState(0);
    return (
        <MDBContainer fluid>
            <MDBCard className="mb-2">
                <MDBCardHeader className="p-0 vert">
                    { props.infoIndex === 0 ?
                        (
                            <Tabs value={index}
                                  onChange={ (event, index) => setIndex(index) }
                                  className="w-100">
                                <Tab label="Пользователи" className="col" style={{maxWidth: "none"}} />
                                <Tab label="Стажеры" className="col" style={{maxWidth: "none"}} />
                                <Tab label="Ожидают авторизации" className="col" style={{maxWidth: "none"}} />
                            </Tabs>
                        ) :
                        <div className="my-3 ml-3">
                            <MDBIcon icon="angle-left mr-2 align-middle text-primary" />
                            <span className="text-primary align-middle mt-3" style={{
                                borderBottom: "1px solid #4185f3", cursor: "pointer"
                            }} onClick={() => {
                                props.setInfoIndex(0);
                                props.setSelecterUser(null)
                            }}>
                                            Назад</span>
                        </div>
                    }
                </MDBCardHeader>
            </MDBCard>
            <MDBCard className="mt-2">
                <MDBCardBody className="p-0">
                        <div className={ index === 0 ? "" : "d-none" }>
                            {props.userTable}
                        </div>
                        <div className={ index === 1 ? "" : "d-none" }>
                            {props.internshipTable}
                        </div>
                        <div className={index === 2 ? "" : "d-none" }>
                            {props.linkUserTable}
                        </div>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    )
}
