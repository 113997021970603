import React, { useRef } from 'react';
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow } from "mdbreact";
import { MultiSelect } from "primereact/multiselect";
import ApiService from "../../../services/ApiService";
import { Editor } from "primereact/editor";
import InfoPopup from "../../InfoPopup";
import { Form } from "react-bootstrap";
import { formateTextForTg } from "../../../utils/formateTextForTg";


const header = (
    <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-strike" aria-label="Strike"></button>
        {/*{props.intern && <button className="ql-link" aria-label="Link"></button>}*/}

        {/*<button className="ql-code-block" aria-label="CodeBlock"></button>*/}
        {/*<button className="ql-color" aria-label="Color"></button>*/}
        {/*<button onClick={ () => setText("<b>#РЕКЛАМА</b>" + (text ? text : "" ))}>#РЕКЛАМА</button>*/}
    </span>
);

const api = new ApiService();
const EditCourseModal = ({ show, toggle, selectedCourse, reloadPage }) => {
    const [positions, setPositions] = React.useState();
    const [users, setUsers] = React.useState();
    const [positionsCourse, setPositionsCourse] = React.useState([]);
    const [usersCourse, setUsersCourse] = React.useState([]);
    const [textCourse, setTextCourse] = React.useState(null);
    const [nameCourse, setNameCourse] = React.useState('');
    const [checkTimeCourse, setCheckTimeCourse] = React.useState();
    const [cookies, setCookies] = React.useState();
    const [file, setFile] = React.useState();
    const [fileError, setFileError] = React.useState('');
    const fileRef = useRef();

    React.useEffect(() => {
        getData();
        setTextCourse(selectedCourse?.structured_course_text?.replace(/\n/ig, "<br>"))
        setNameCourse(selectedCourse?.structured_course_name);
        setCheckTimeCourse(selectedCourse?.is_time_limit);
        setCookies(selectedCourse?.cookies);
        setFile(selectedCourse?.file);
        setFileError('')
    }, [selectedCourse]);
    const getData = async () => {
        const positions = await api.getSmallPositions();
        setPositions(positions.data.Positions);

        await api.getUsers().then((data) => {
            setUsers(data.data.Users);
        });

        const selectedPositions = [];
        await api.getPositionsForStructuredCourse(selectedCourse?.structured_course_id)
            .then(results => {
                results.map((i, index) => {
                    selectedPositions.push(i.position_id);
                })
                setPositionsCourse(selectedPositions);
            })
            .catch(error => {
                console.error(error);
            });

        const selectedUsers = [];
        await api.getUsersForStructuredCourse(selectedCourse?.structured_course_id)
            .then(results => {
                results.map((i, index) => {
                    selectedUsers.push(i.user_id);
                })
                setUsersCourse(selectedUsers);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const editCourse = async () => {
        const newDataPosition = {
            courseId: selectedCourse.structured_course_id,
            positions: positionsCourse,
        }

        const selectedUsers = [];
        const data = await api.getUsersForStructuredCourse(selectedCourse?.structured_course_id)
        data.map((i, index) => {
            selectedUsers.push(i.user_id);
        })

        const newDataUsers = {
            courseId: selectedCourse.structured_course_id,
            users: usersCourse,
            lastUsers: selectedUsers,
        }

        await api.editUsersCourseWithModal(newDataUsers);
        await api.editCourseWithModal(newDataPosition);
        const newTextCourse = formateTextForTg(textCourse);
        await api.editStructuredCourseText(selectedCourse.structured_course_id, newTextCourse);

        await api.editStructuredCourseNameAndCookie(selectedCourse.structured_course_id, nameCourse, +cookies, checkTimeCourse);

        if (fileRef.current.value === "" && file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('courseId', selectedCourse.structured_course_id);
            await api.uploadFileCourse(formData);
        }

        reloadPage();
        toggle(false);
    }

    const onUpload = (event) => {
        setFile(event.target.files[0]);
        fileRef.current.value = "";
    };
    const downloadFile = async () => {
        await api.downloadFileCourse({ courseId: selectedCourse?.structured_course_id })
          .then(async data => {
              if (data.message) {
                  setFile(null);
                  setFileError(data.message);
                  return
              }
              let url = URL.createObjectURL(data);

              const a = document.createElement('a');
              a.href = url;
              a.download = `file`;
              document.body.appendChild(a);
              a.click();
              a.remove();

              URL.revokeObjectURL(url);
          })
          .catch(e => {
              setFileError(e.message);
          });
    }

    return (
        <MDBModal isOpen={show} toggle={() => toggle(false)} size="lg" className='opacity-100' >
            <MDBModalHeader toggle={ () => toggle(false) } className="py-1 shadow-sm" >Редактирование</MDBModalHeader>
            <MDBModalBody className="pb-0">
                <MDBRow>
                    <MDBCol md="12"  middle className="font-weight-bolder mb-2">
                        <Form.Label>Название</Form.Label>
                        <Form.Control
                            type="text"
                            rows={3}
                            value={nameCourse}
                            onChange={(e) => setNameCourse(e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="12"  middle className="font-weight-bolder mb-2">
                        <Form.Label>Печеньки за курс</Form.Label>
                        <Form.Control
                            type="number"
                            rows={3}
                            value={cookies}
                            onChange={(e) => setCookies(e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="12"  middle className="font-weight-bolder mb-2">
                        <Form.Check
                            value={checkTimeCourse}
                            onChange={(e) => setCheckTimeCourse(e.target.checked)}
                            type="checkbox"
                            checked={checkTimeCourse}
                            label="Блокировка"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="12"  middle className="font-weight-bolder d-flex align-items-center">
                        <label style={{fontSize: "18px"}} htmlFor="editorSuccess" className='mr-1'>Описание</label>
                        <InfoPopup text={'Если описание не заполнено, при назначении пользователь получит: "Тебе доступен новый курс: <НАЗВАНИЕ КУРСА>". При заполненном описании сообщение будет выглядеть следующим образом: "Тебе доступен новый курс <НАЗВАНИЕ КУРСА>, там ты узнаешь <ОПИСАНИЕ>"'}/>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2 pb-2 border-bottom">
                    <MDBCol md="12"  middle className="font-weight-bolder">
                        <Editor style={{height: '150px', fontFamily: "'Roboto', sans-serif", fontSize: "12pt"}}
                                className={'mb-2'}
                                id="editorSuccess"
                                value={textCourse}
                                onTextChange={(e) => setTextCourse(e.htmlValue)}
                                headerTemplate={header}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2 pb-2 border-bottom">
                    <MDBCol md="2"  middle className="font-weight-bolder">
                        Пользователи:
                    </MDBCol>
                    <MDBCol md="10" >
                        <MultiSelect optionLabel="full_name" optionValue="user_id"
                                     options={users} value={usersCourse}
                                     onChange={(e) => setUsersCourse(e.value)}
                                     placeholder="Пусто"
                                     filter={true}
                                     className="col-12"
                                     filterPlaceholder="Поиск"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2 pb-2">
                    <MDBCol md="2"  middle className="font-weight-bolder">
                        Должности:
                    </MDBCol>
                    <MDBCol md="10" >
                        <MultiSelect optionLabel="position_name" optionValue="position_id"
                                     options={positions} value={positionsCourse}
                                     onChange={(e) => setPositionsCourse(e.value)}
                                     placeholder="Пусто"
                                     filter={true}
                                     className="col-12"
                                     filterPlaceholder="Поиск"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className='mt-3 mb-2'>
                    <MDBCol md="2"  middle className="font-weight-bolder">
                        Приложенный файл:
                    </MDBCol>
                    <MDBCol md="10" >
                        <div className="input-group">
                            <div className="custom-file">
                                <input
                                  type="file"
                                  multiple={false}
                                  className="custom-file-input"
                                  id="inputGroupFile01"
                                  aria-describedby="inputGroupFileAddon01"
                                  onChange={onUpload}
                                  ref={fileRef}
                                />
                                <label className="custom-file-label"
                                       htmlFor="inputGroupFile01"
                                       style={{ cursor: "pointer" }}
                                >
                                    {file?.name ? file?.name : file ? file : "Выберите файл или оставьте пустым"}
                                </label>
                            </div>
                            <div className="input-group-prepend">
                                {
                                  selectedCourse && selectedCourse.file && file &&
                                  <span className="input-group-text"
                                        id="inputGroupFileAddon01"
                                        onClick={downloadFile}
                                        style={{ cursor: "pointer" }}
                                  >
                                      Загрузить на ПК
                                  </span>
                                }
                            </div>
                        </div>
                        {fileError && <span style={{color: 'red'}}>{fileError}</span>}
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="py-1 grey lighten-3">
                <MDBBtn color="warning" size="sm"
                        onClick={editCourse}
                >Изменить
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
};

export default EditCourseModal;