import React, {useState} from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBRow, MDBTable, MDBTableBody, MDBTableHead,
} from "mdbreact";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {Tooltip} from "antd";

function createMarkup(html) {
    return {__html: html}
}

export default function PostsTable(props) {
    const [index, setIndex] = useState(0);

    return (
        <MDBRow className="mt-3">
            <MDBCol xl={'12'}>
                <Tabs
                    value={index}
                    onChange={(event, index) => setIndex(index)}
                    className="w-100"
                >
                    <Tab label="Текущие" className="col"
                         style={{maxWidth: "none"}}/>
                    <Tab label="Доставлено" className="col"
                         style={{maxWidth: "none"}}/>
                </Tabs>
            </MDBCol>
            <MDBCol xl={'12'}>
                <MDBTable striped bordered className="z-depth-1 white">
                    <MDBTableHead>
                        {
                            index === 0 ?
                                <tr className="">
                                    <th>Текст</th>
                                    <th style={{width: 150}}>Дата</th>
                                    <th style={{width: 100}}>Выбрано</th>
                                    <th style={{width: 500}}>Действие</th>
                                </tr> :
                                <tr className="">
                                    <th>Текст</th>
                                    <th style={{width: 150}}>Дата</th>
                                    <th style={{width: 100}}>Доставлено</th>
                                    <th style={{width: 300}}>Действие</th>
                                </tr>
                        }
                    </MDBTableHead>
                    {
                        index === 0 ?
                            <MDBTableBody>
                                {
                                    props.postsTemporary.map((item, index) => (
                                        <tr key={index} style={{cursor: "pointer"}}>
                                            <td><span dangerouslySetInnerHTML={createMarkup(item.body)}/></td>
                                            <td>{item.timezone_time}</td>
                                            <td>{item['Общее']}</td>
                                            <td className="card_buttons_mailing">
                                                <MDBBtn
                                                    onClick={() => props.selectPost(item.body, item.timezone_time, item.post_id)}>Редактировать</MDBBtn>
                                                <MDBBtn onClick={() => props.onInfo(item)} color='info'>Инфо</MDBBtn>
                                                <MDBBtn onClick={() => props.deletePost(item.post_id)}
                                                        color='danger'>Удалить</MDBBtn>
                                            </td>

                                        </tr>
                                    ))
                                }
                            </MDBTableBody> :
                            <MDBTableBody>
                                {
                                    props.postsHistory.map((item, index) => {
                                        const disabled = Math.round((new Date() - new Date(item.date)) / (1000 * 60 * 60 * 24)) >= 2
                                        return (
                                            <tr key={index} style={{cursor: "pointer"}}>
                                                <td><span dangerouslySetInnerHTML={createMarkup(item.body)}/></td>
                                                <td>{item.is_temporary === 0 ? item.timezone_date : item.timezone_time}</td>
                                                <td>{item['Общее']}</td>
                                                <td className="card_buttons_mailing">
                                                    <MDBBtn onClick={() => props.onInfo(item)}
                                                            color='info'>Инфо</MDBBtn>
                                                    <Tooltip placement="top"
                                                             title={disabled ? 'Удаление возможно только в течении первых двух суток после создания поста' : null}>
                                                        <MDBBtn
                                                            onClick={disabled ? null : () => props.deleteSentPost(item.post_id)}
                                                            color='danger'
                                                            className={disabled ? 'disabled-button' : null}>
                                                            Удалить
                                                        </MDBBtn>
                                                    </Tooltip>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                            </MDBTableBody>
                    }
                </MDBTable>
            </MDBCol>
        </MDBRow>
    );
}
