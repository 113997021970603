import React, { useEffect, useRef, useState } from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBRow,
} from "mdbreact";
import { Editor } from "primereact/editor";
import ApiService, { BACK_URL } from '../services/ApiService'
import { InputSwitch } from "primereact/inputswitch";
import FilterUser from './FilterUser';
import { Calendar } from "primereact/calendar";
import { formateTextForTg } from "../utils/formateTextForTg";
import './global_components.css';

const api = new ApiService();
export default function SendMassages(props) {
    let [positions, setPositions] = useState([]);
    let [loading, setLoading] = useState(false)
    useEffect(() => {
        let api = new ApiService();
        api.getSmallPositions().then(data => setPositions(data.data.Positions));
    }, []);

    let fileRef = useRef();

    let [file, setFile] = useState();
    let [text, setText] = useState();
    let [user_ids, setUsers_id] = useState([]);
    let [sendAsFile, setSendAsFile] = useState(false);
    let [oldFiles, setOldFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [checked, setChecked] = useState(false);
    const [date, setDate] = useState("");

    let onUpload = () => {
        const files = Array.from(fileRef.current.files);
        const containsImages = files.some(
            (file) => file.type.startsWith("image/")
        );
        const containsText = files.some(
            (file) => file.type.startsWith("text/")
        );
        const containsApplication = files.some(
            (file) => file.type.startsWith("application/")
        );
        const containsVideo = files.some(
            (file) => file.type.startsWith("video/")
        );
        if (files.length > 10) {
            alert("Можно отправить максимум 10шт");
        } else {
            if (containsImages && containsText ||
                containsApplication && containsImages ||
                containsVideo && containsImages ||
                containsVideo && containsApplication ||
                containsVideo && containsText) {
                alert("Выберите либо только фотографии, либо только файлы, либо только видео");
                const emptyFileList = new DataTransfer().files;
                fileRef.current.files = emptyFileList;
            } else {
                setSelectedFiles(files);
                setFile(fileRef.current.files);
            }
        }

    };

    const header = (
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-strike" aria-label="Strike"></button>
            <button className="ql-link" aria-label="Link"></button>
            {/*<button className="ql-code-block" aria-label="CodeBlock"></button>*/}
            {/*<button className="ql-color" aria-label="Color"></button>*/}
            <button style={{ marginTop: "-1.6%", color: "blue" }}
                    onClick={() => setText("<b>#РЕКЛАМА</b>" + (text ? text : ""))}>#РЕКЛАМА</button>
        </span>
    );

    let sendMassage = async () => {
        let formData = new FormData();
        if (!window.confirm(`Отправить рассылку?\nКоличество пользователей в рассылке: ${user_ids.length}`)) return;
        setLoading(true)
        formData.append('text', formateTextForTg(text));
        formData.append('user_ids', user_ids);
        formData.append('isTemporary', checked ? 1 : 0);
        if (checked && typeof date === 'string') {
            alert('Выберете нужное время');
            setLoading(false);
            return;
        }
        formData.append('send_to', date ? date : null);
        if (file) {
            for (const i of file) {
                formData.append('file', i);
            }
        }
        const res = await api.sendPost(formData)
        if (res.ok) {
            let json = await res.json();
            setLoading(false);
            clearDataForm();
            props.addPost(json);
            await props.fetchPosts();
        } else {
            setLoading(false);
            alert('Возникла ошибка: ' + await res.text());
        }
    };

    const editMessage = async () => {
        const formData = new FormData();
        if (!window.confirm(`Редактировать рассылку?\nКоличество пользователей в рассылке: ${user_ids.length}`)) return;
        setLoading(true);
        formData.append('user_ids', user_ids);
        formData.append('send_to', props.updatePost?.date ? props.updatePost.date : date);
        formData.append('send_to', typeof date === 'object' ? date : props.updatePost.date);
        formData.append('text', formateTextForTg(text));
        if (file) {
            for (const i of file) {
                formData.append('file', i);
            }
        }
        if (oldFiles) {
            formData.append('oldPath', JSON.stringify(oldFiles));
        }

        await api.editPost(formData, props.updatePost.postId)
            .then(data => {
                if (data.error) {
                    props.setQueueNotifications(props.queueNotifications.concat([data.error]))
                } else {
                    props.setQueueNotifications(props.queueNotifications.concat([data.success]));
                    props.fetchPosts();
                    props.setUodatePost(undefined);
                    clearDataForm();
                }
                setLoading(false);

            })
            .catch(e => {
                console.log(e)
                props.setQueueNotifications(props.queueNotifications.concat(['Ошибка']))
                setLoading(false);
            })
    }

    useEffect(async () => {
        if (props.updatePost?.date && props.updatePost?.body) {
            setChecked(true);
            setDate(props.updatePost.date);
            setText(props.updatePost.body);
            const userIds = await api.getUsersIds(props.updatePost.postId);
            const filePaths = await api.getFilePathsForPost(props.updatePost.postId);
            setOldFiles(filePaths);
            setUsers_id(userIds);
            const emptyFileList = new DataTransfer().files;
            setSelectedFiles([]);
            setFile(null);
            fileRef.current.files = emptyFileList;
        } else {
            clearDataForm();
        }
    }, [props.updatePost]);

    const clearDataForm = () => {
        setChecked(false);
        setText();
        setDate("");
        setOldFiles([]);
        const emptyFileList = new DataTransfer().files;
        setSelectedFiles([]);
        setFile(null);
        fileRef.current.files = emptyFileList;
    }

    const onDeleteOldImage = (id) => {
        if (!window.confirm(`Удалить файл?`)) return;
        setOldFiles(oldFiles.filter(i => i.id != id));
    };
    const onDeleteNowImage = (name) => {
        if (!window.confirm(`Удалить файл?`)) return;
        setSelectedFiles(selectedFiles.filter(i => i.name != name));

        const filesArray = Array.from(fileRef.current.files).filter(i => i.name != name);
        const dataTransfer = new DataTransfer();
        filesArray.forEach((file) => {
            dataTransfer.items.add(file);
        });
        setFile(dataTransfer.files)
        fileRef.current.files = dataTransfer.files;
    };

    const clearFileInput = () => {
        const emptyFileList = new DataTransfer().files;
        setSelectedFiles([]);
        setFile(null);
        fileRef.current.files = emptyFileList;
    };

    const currentDate = new Date();
    const minTime = new Date(currentDate);
    minTime.setHours(0, 0, 0, 0);
    return (
        <MDBRow className="z-depth-1 white py-3 rounded">
            <MDBCol md={'7'}>
                <Editor style={{ height: '200px', fontFamily: "'Roboto', sans-serif", fontSize: "12pt" }} value={text}
                        onTextChange={(e) => setText(e.htmlValue)} headerTemplate={header}/>

                <MDBRow className='mt-3'>
                    <div className="pl-3" style={{ width: '98.5%' }}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                            <span className="input-group-text"
                                  id="inputGroupFileAddon01"
                                  onClick={clearFileInput}
                                  style={{ cursor: "pointer" }}
                            >
                              Отчистить
                            </span>
                            </div>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    multiple={true}
                                    className="custom-file-input"
                                    id="inputGroupFile01"
                                    aria-describedby="inputGroupFileAddon01"
                                    onChange={onUpload}
                                    ref={fileRef}
                                />
                                <label className="custom-file-label"
                                       htmlFor="inputGroupFile01"
                                       style={{ cursor: "pointer" }}
                                >
                                    {file ? file.name : "Выберите файл или оставьте пустым"}
                                </label>
                            </div>
                        </div>
                    </div>
                </MDBRow>
                <MDBRow className="p-0 mb-2 align-middle d-flex mt-2 pl-3">
                    {selectedFiles?.map((file, index) => (
                        <div key={index}>
                            {file.type.startsWith('image/') ?
                                (
                                    <img src={BACK_URL + URL.createObjectURL(file)}
                                         style={{ width: 80, height: 80, marginRight: 5 }}
                                         alt={`Изображение ${++index}`}
                                         className='pointer image'
                                         onClick={e => onDeleteNowImage(file.name)}
                                    />
                                ) :
                                (
                                    <a className='mr-1' href={URL.createObjectURL(file)} download={file.name}>
                                        Файл- {++index}
                                    </a>
                                )
                            }

                        </div>
                    ))}
                </MDBRow>
                <MDBRow className="p-0 mb-2 align-middle d-flex pl-3">
                    {oldFiles?.map((file, index) => (
                        <div key={index}>
                            {file.path.split('.').pop().toLowerCase().includes('jpeg' || 'png') ?
                                (
                                    <img src={BACK_URL + file.path}
                                         style={{ width: 80, height: 80, marginRight: 5 }}
                                         className='pointer image'
                                         alt={`Изображение ${index}`}
                                         onClick={e => onDeleteOldImage(file.id)}
                                    />
                                ) :
                                (
                                    <div className='mr-1 pointer file'
                                         onClick={e => onDeleteOldImage(file.id)}
                                    >
                                        Файл{"." + file.path.split('.').pop().toLowerCase()}
                                    </div>
                                )
                            }

                        </div>
                    ))}
                </MDBRow>
                <MDBRow className='pl-3 d-flex'>
                    <strong className="pt-1 mr-2">*</strong> для вставки имени в текст, напишите <strong
                    className="ml-1">$$имя$$</strong>
                </MDBRow>
            </MDBCol>
            <MDBCol md={'5'} className="mt-md-0 mt-sm-3">
                <FilterUser setUsers_id={setUsers_id} user_ids={user_ids}/>

                <div className='d-flex align-items-end mt-2'>
                    <InputSwitch checked={checked}
                                 onChange={(e) => setChecked(!checked)}
                                 id="switcher"
                                 className="mr-2"
                                 disabled={(props.updatePost?.date && props.updatePost?.body) ? true : false}
                    />
                    <label htmlFor="statusInternLabel">Временная</label>
                </div>

                {checked &&
                    <MDBRow>
                        <MDBCol size='12' style={{ marginTop: 10 }}>
                            <Calendar value={date}
                                      onChange={(e) => setDate(e.target.value)}
                                      showTime
                                      hourFormat="24"
                                      dateFormat="dd-mm-yy"
                                      showIcon
                                      minDate={currentDate}
                                      minTime={minTime}
                            />
                        </MDBCol>
                    </MDBRow>
                }

                <div className="border-top mt-2 pt-2">
                    {
                        loading ?
                            (<div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Загрузка...</span>
                            </div>) :
                            (
                                <>
                                    {
                                        props.updatePost !== undefined ?
                                            <>
                                                <MDBBtn size="sm"
                                                        className="m-0 mr-2"
                                                        onClick={editMessage}
                                                        disabled={user_ids.length === 0 || text === undefined}>Редактировать
                                                </MDBBtn>
                                                <MDBBtn color='danger'
                                                        size="sm"
                                                        className="m-0"
                                                        onClick={() => props.setUodatePost(undefined)}
                                                        disabled={user_ids.length === 0 || text === undefined}>Отменить
                                                </MDBBtn>
                                            </>
                                            :
                                            <MDBBtn color="primary"
                                                    size="sm"
                                                    className="m-0"
                                                    onClick={sendMassage}
                                                    disabled={user_ids.length === 0 || text === undefined}>Отправить
                                            </MDBBtn>
                                    }
                                </>
                            )
                    }
                </div>
            </MDBCol>
        </MDBRow>
    );
}
