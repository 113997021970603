import React from 'react';
import {
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBRow
} from "mdbreact";

const Item = props => (
    <MDBListGroupItem className="d-flex p-1 px-2 text-dark">
        <div style={{ flexGrow: 8}} >
            <div className="font-weight-bolder" style={{flexGrow: 1, fontSize: 15}}>
                {props.title}
            </div>
            <div style={{flexGrow: 1, fontSize: 13}} className="text-muted ml-3">
                <u onClick={() => window.open("/user/" + props.userId, '_blank')} className='pointer'>{props.description[0]} </u>
                <u>{props.description[1] ? props.description[1] : ''}  </u>
            </div>
        </div>
        <div style={{ flexGrow: 2, alignSelf: "center", fontSize: 15}} className="text-right">
            <strong>{props.date} 📅</strong>
        </div>
    </MDBListGroupItem>
)

export default props => {
    return (
        <MDBRow className="m-0 no-gutters">
            <MDBCol size="12">
                <MDBListGroup className="w-100" >
                    {
                        props.notifications.map( (value, index) =>
                            <Item
                                key={index}
                                userId={value.user_id}
                                title={value.memo}
                                description={[value.full_name, value?.user_name]}
                                date={value.date}
                            />)
                    }
                </MDBListGroup>
            </MDBCol>
        </MDBRow>
    );
};
