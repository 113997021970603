import React from "react";
import {MDBNotification} from "mdbreact";

function Notif(props) {
    return (
        <MDBNotification
            show
            fade
            autohide={5000}
            iconClassName="text-primary"
            title="SW-Bot"
            message={props.text}
        />)
}

export default function NotificationsQueue(props) {
    return (
        <div className="" style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            zIndex: 9999
        }}>
            {props.queueNotifications.map((value, index) => {
                return (<Notif text={value} key={index}/>)
            } )}
        </div>
    )
}
