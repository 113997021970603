import React, { useEffect, useState } from 'react';
import './App.css';
import {
   Switch,
   Route,
   Redirect, useLocation
} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer/Footer.js';

import VideoPage from './Pages/VideoPage';
import IndexPage from './Pages/IndexPage';
import PositionPage from './Pages/PositionPage';
import MenuPage from './Pages/MenuPage';
import MassagesPage from './Pages/MassagesPage'
import StructuredCoursesPage from './Pages/StructuredCoursesPage'
import Certifications from './Pages/Certifications'
import CustomPage from './Pages/CustomPage'
import ApiService, { BACK_URL } from "./services/ApiService";
import UserInfoPage from "./Pages/UserInfoPage";
import FAQ_Page from "./Pages/FAQPage/FAQ_Page";
import EditFAQ from "./Pages/AddFAQ/EditFAQ";
import AddFAQ from "./Pages/AddFAQ/AddFAQ";
import Shop from './Pages/Shop';
import Report from "./Pages/Report";
import RegionSettingPage from "./Pages/RegionSettingPage";
import Store from "./Pages/Store";
import LoginPage from "./Pages/Login";

function App() {
   const api = new ApiService();
   const location = useLocation();

   const [region, setRegion] = useState();
   const [routes, setRoutes] = useState([]);
   const [loading, setLoading] = useState(true);
   const [isAuth, setIsAuth] = useState(false);

   useEffect(async () => {
      try {
         const res = await fetch(`${BACK_URL}/checkAuth`, {
            method: "GET",
            credentials: "include"
         })
         if (res.status === 200) {
            setIsAuth(true);
         }
      } catch (e) {
         console.error(e)
      } finally {
         setLoading(false)
      }
   }, [])

   useEffect(() => {
      if (isAuth) {
         try {
            async function fetchData() {
               const data = await api.getAdmin();

               if (data?.data) {
                  setRegion(data.data.Admin.admin_name);
                  setRoutes((await api.getRoutes()).data.ConsoleRoutes);
                  document.local = { admin: data.data.Admin };
                  setLoading(false)
               } else {
                  setLoading(false)
               }
            }

            fetchData();
         } catch (e) {
            console.error(e)
         }
      }
   }, [isAuth]);

   return (
     <>
        {isAuth && <Header region={region} routes={routes}/>}
        <Switch>
           {
              loading ?
                <div className="spinner-border text-primary" role="status">
                   <span className="sr-only">Loading...</span>
                </div> : isAuth ?
                  <>
                     {(location?.pathname === '/' || location?.pathname === '/login') && <Redirect to="/index"/>}
                     <Route exact path="/index">
                        <IndexPage/>
                     </Route>
                     <Route path="/user/:id">
                        <UserInfoPage/>
                     </Route>
                     <Route path="/videos">
                        <VideoPage/>
                     </Route>
                     <Route path="/positions">
                        <PositionPage/>
                     </Route>
                     <Route path="/dishes">
                        <MenuPage/>
                     </Route>
                     <Route path="/custom">
                        <CustomPage/>
                     </Route>
                     <Route path="/sending_massages">
                        <MassagesPage/>
                     </Route>
                     <Route path="/structured_courses">
                        <StructuredCoursesPage/>
                     </Route>
                     <Route path="/certifications">
                        <Certifications/>
                     </Route>
                     <Route path="/shop">
                        <Shop/>
                     </Route>
                     <Route path="/report">
                        <Report/>
                     </Route>
                     <Route path="/addFAQ">
                        <AddFAQ/>
                     </Route>
                     <Route path="/editFAQ/:id">
                        <EditFAQ/>
                     </Route>
                     <Route path="/faq">
                        <FAQ_Page/>
                     </Route>
                     <Route path="/region_settings">
                        <RegionSettingPage updateRoutes={setRoutes}/>
                     </Route>
                     <Route path="/store">
                        <Store/>
                     </Route>
                  </> : null
           }
           {!isAuth &&
             <>
                <Redirect exact from="*" to="/login"/>
                <Route path="/login">
                   <LoginPage setIsAuth={setIsAuth}/>
                </Route>
             </>
           }
        </Switch>
        {isAuth && <Footer/>}
     </>
   );
}

export default App;


