import React, { useEffect, useState } from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBContainer,
} from "mdbreact";
import InternshipCoursesTable from "../../components/InternshipCoursesTable";
import InternshipDaysTable from "../../components/InternshipDaysTable";
import InternshipQuestionsTable from "../../components/InternshipQuestionsTable";
import AddInternship from "../../components/AddInternship";

import SwipeableViews from "react-swipeable-views";
import Notifications from "../../components/Modals/Notifications";

import CustomEditModal from "../../components/Modals/CustomEditModal";
import ApiService from "../../services/ApiService";
import AddDayModal from '../../components/Modals/Internship/AddDayModal';
import EditCourseModal from "../../components/Modals/Internship/EditCourseModal";
import { formateTextForTg } from "../../utils/formateTextForTg";

const api = new ApiService();
export default () => {
    let [selectedCourse, setSelectedCourse] = useState();
    let [selectedDay, setSelectedDay] = useState();
    let [index, setIndex] = useState(0);
    let [reloadInternShip, setReloadInternShip] = useState(1);
    let [selectedForEdit, setSelectedForEdit] = useState();
    const [reloadQuestions, setReloadQuestions] = useState(false);

    const [file, setFile] = React.useState();

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedStructuredCourse, setSelectedStructuredCourse] = useState();

    let toDaysScreen = (Course) => {
        setSelectedCourse(Course)
        setIndex(1)
    }
    let toQuestionsScreen = (Day) => {
        setSelectedDay(Day)
        setIndex(2)
    }

    let backButton = (index) => {
        if (index > 0) setIndex(index - 1)
    }
    let [queueNotifications, setQueueNotifications] = useState([]);
    let addNotifications = (text) => {
        setQueueNotifications(queueNotifications.concat([text]));
    }

    let addedInternShip = () => {
        setIndex(0);
        setReloadInternShip(!reloadInternShip);
        setQueueNotifications(queueNotifications.concat(["Адаптация создана успешно"]));
    }
    let onClickEditButton = (elem) => {
        setSelectedForEdit(elem.internship_course_id);
        setIndex(3);
    }

    //Правка вопросов
    const [showModal, setShowModal] = useState(false);
    const [showAddModal, setAddShowModal] = useState(false);

    const [showAddDayModal, setShowAddDayModal] = useState(false);
    const toggleAddDayModal = () => setShowAddDayModal(!showAddDayModal);

    const [selectedQuestion, setSelectedQuestion] = useState();
    const [selectedDayForAddQuestion, setSelectedDayForAddQuestion] = useState();
    const [questionsList, setQuestionsList] = useState([]);

    let showQuestionModal = (question, day) => {
        setQuestionsList(question);
        setSelectedDayForAddQuestion(day);
        setAddShowModal(true);
    }
    let editQuestion = async (...any) => {
        let api = new ApiService()
        let url;
        if (any[0]) {
            if (typeof any[0] === "string") {
                url = any[0]
            } else {
                let res = await api.uploadFile(any[0])
                url = await res.text();
            }
        }
        let newData = await api.editStructuredQuestion({
            structured_question_id: any[7].structured_question_id,
            newDataQuestion: {
                question: formateTextForTg(any[1]),
                answers: any[2],
                right_answers: any[3],
            },
            image: url,
            is_editable: any[8] ? 1 : 0
        }).catch((e) => {
            addNotifications("Произошла ошибка!");
            throw e
        });
        if (!newData.errors) {
            setShowModal(false);
            setReloadQuestions(!reloadQuestions)
            addNotifications("Вопрос успешно изменен!");
            return true
        } else {
            addNotifications("Произошла ошибка!");
            console.error(newData.errors)
        }
    }

    let addQuestion = async (...any) => {
        let api = new ApiService()
        let url = "";
        if (any[0]) {
            if (typeof any[0] === "string") {
                url = any[0]
            } else {
                let res = await api.uploadFile(any[0])
                url = await res.text();
            }
        }
        let newData = await api.joinStructuredQuestion({
            structured_day_id: any[4],
            QuestionData: {
                question: formateTextForTg(any[1]),
                answers: any[2],
                right_answers: any[3],
            },
            image: url,
            is_editable: any[5] ? 1 : 0
        }).catch((e) => {
            setQueueNotifications(queueNotifications.concat(["Произошла ошибка!"]));
            console.log(e)
            throw e
        });
        if (!newData.errors) {
            setAddShowModal(false);
            setReloadQuestions(!reloadQuestions)
            addNotifications("Вопрос успешно добавлен!");
            return true
        } else {
            addNotifications("Произошла ошибка!");
            console.error(newData.errors)
        }
    }

    // Данные о днях(блоках) выбранного курса
    let [internshipDays, setInternshipDays] = useState([])
    let [isDaysLoading, setIsDaysLoading] = useState(true)

    const loadInternshipDaysData = async () => {


        if (selectedCourse) {
            let data = await api.getStructuredDays(selectedCourse.structured_course_id);
            setInternshipDays(data.data.StructuredDays)
            setIsDaysLoading(false)
        }
    }

    let [internshipCourses, setInternshipCourses] = useState([])
    let [internshipCoursesArchive, setInternshipCoursesArchive] = useState([])
    const getDataCourses = async () => {
        const data = await api.getStructuredCourses();
        const archiveCourses = await api.getStructuredCoursesArchive();
        setInternshipCoursesArchive(archiveCourses.data.StructuredCourses);
        setInternshipCourses(data.data.StructuredCourses);
    };


    useEffect(async () => {
        await loadInternshipDaysData();
    }, [selectedCourse?.structured_course_id])

    useEffect(async () => {
        await getDataCourses();
    }, [])

    const onUpload = (event) => {
        setFile(event.target.files[0]);
    };
    const clearFileInput = () => {
        setFile(null);
    };

    return (
        <>
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <Notifications queueNotifications={queueNotifications} />
                <AddDayModal
                    show={showAddDayModal}
                    toggle={toggleAddDayModal}
                    selectedCourse={selectedCourse}
                    addNotifications={addNotifications}
                    loadInternshipDaysData={loadInternshipDaysData}
                    data={internshipDays}
                />
                <CustomEditModal
                    show={showModal}
                    selectedQuestion={selectedQuestion}
                    toggle={setShowModal}
                    editQuestion={editQuestion}
                />
                <CustomEditModal
                    show={showAddModal}
                    questionsList={[{
                        element_index: -1,
                        question: "Вставить в начало"
                    }, { element_index: questionsList.length, question: "Вставить в конец" }, ...questionsList]}
                    selectedDayForAddQuestion={selectedDayForAddQuestion}
                    toggle={setAddShowModal}
                    addQuestion={addQuestion}
                />
                <EditCourseModal show={showEditModal} toggle={setShowEditModal} selectedCourse={selectedStructuredCourse} reloadPage={getDataCourses} />

                <MDBContainer fluid>
                    <MDBCard>
                        <MDBCardBody className='p-0'>
                            {
                                index !== 3 ?
                                    <SwipeableViews index={index} slideStyle={{ "overflow": "hidden" }}>
                                        <div className={index === 0 ? "" : "d-none"}>
                                            <InternshipCoursesTable
                                                onRowClick={toDaysScreen}
                                                reloadInternShip={reloadInternShip}
                                                onClickEditButton={onClickEditButton}
                                                toAddScreen={() => setIndex(3)}
                                                addNotifications={addNotifications}
                                                setSelectedStructuredCourse={setSelectedStructuredCourse}
                                                setShowEditModal={setShowEditModal}
                                                internshipCourses={internshipCourses}
                                                internshipCoursesArchive={internshipCoursesArchive}
                                                setInternshipCourses={setInternshipCourses}
                                                setInternshipCoursesArchive={setInternshipCoursesArchive}
                                                getData={getDataCourses}
                                            />
                                        </div>
                                        <div className={index === 1 ? "" : "d-none"}>
                                            <InternshipDaysTable
                                                backButton={backButton}
                                                course={selectedCourse}
                                                onRowClick={toQuestionsScreen}
                                                addNotifications={addNotifications}
                                                setShowAddDayModal={setShowAddDayModal}
                                                isDaysLoading={isDaysLoading}
                                                internshipDays={internshipDays}
                                                setInternshipDays={setInternshipDays}
                                            />
                                        </div>
                                        <div className={index === 2 ? "" : "d-none"}>
                                            <InternshipQuestionsTable
                                                setSelectedQuestion={setSelectedQuestion}
                                                setShowModal={setShowModal}
                                                backButton={backButton}
                                                day={selectedDay}
                                                addNotifications={addNotifications}
                                                reloadQuestions={reloadQuestions}
                                                showQuestionModal={showQuestionModal}
                                            />
                                        </div>
                                    </SwipeableViews> :
                                    <AddInternship addedInternShip={addedInternShip}
                                                   selectedForEdit={selectedForEdit}
                                                   backButton={backButton}
                                                   onUpload={onUpload}
                                                   clearFileInput={clearFileInput}
                                                   file={file}
                                    />
                            }
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </main>
        </>
    );
}
