import {useState} from "react";
import {
    MDBBtn,
    MDBCol,
    MDBModal, MDBModalBody,
    MDBModalFooter, MDBModalHeader, MDBRow
} from "mdbreact";
import ApiService from "../../../services/ApiService";
import React from "react";
import {Dropdown} from "primereact/dropdown";

export default function ChangeIngredientModal(props) {
    let api = new ApiService();
    let [ingredient, setIngredient] = useState(undefined);

    // Удаляем из массива ингредиент, который мы выбрали, чтобы заменить
    const newIngredients = props.ingredients.filter(e => e.ingredient_name !== props.item?.ingredient_name);

    return (

        <MDBModal isOpen={props.show} toggle={() => props.toggle(false)}>
            <MDBModalHeader toggle={() => props.toggle(false)} className="py-3 shadow-sm">Заменить
                ингредиент</MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    <MDBCol className="mx-auto px-3" style={{fontSize: "16pt"}}>
                        {props.item?.ingredient_name} <br/>
                        <span style={{color: "#fb3"}}>НА:</span>
                        <div className="grey-text">
                            <Dropdown
                                value={ingredient}
                                options={newIngredients}
                                optionLabel="ingredient_name"
                                optionValue="ingredient_id"
                                onChange={(e) => setIngredient(e.target.value)}
                                placeholder="Заменить на:"
                                className="w-100"
                                filter
                                scrollHeight="450px"
                                filterPlaceholder="Поиск"
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="py-1 shadow-sm">
                <MDBBtn color="primary" size="sm"
                        onClick={() => props.onClick(ingredient)}
                        className={ingredient === undefined ? "disabled" : ""}
                >
                    Заменить
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    )
}
