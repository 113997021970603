import React, {useEffect, useRef, useState} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer, MDBRow,
} from "mdbreact";
import "./index.css"
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import ApiService from "../../services/ApiService";
import TextField from "@material-ui/core/TextField";
import {Editor} from "primereact/editor";
import {Dropdown} from "primereact/dropdown";
import BackspaceIcon from '@material-ui/icons/Backspace';
import IconButton from "@material-ui/core/IconButton";

export default () => {
    const [title, setTitle] = useState();
    const [text, setText] = useState("");
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const [questionsList, setQuestionsList] = useState();
    const [elementIndex, setElementIndex] = useState();
    const [width, setWidth] = useState(0);
    const [groupName, setGroupName] = useState();
    const [addCategory, setAddCategory] = useState(false);

    const ref = useRef(null);
    let api = new ApiService();

    useEffect(() => {
        setWidth(window.document.getElementById("coller").clientWidth - ref.current?.clientWidth - 36);
        async function get() {
            let {data: {FAQContent}} = await api.FAQCategoriesForAdd();
            setGroups(FAQContent);
        }
        get();
    }, []);

    window.onresize = function( event ) {
        setWidth(window.document.getElementById("coller").clientWidth - ref.current?.clientWidth - 36);
    };

    let showTEST = (text) => {
        setText(text);
        // console.log(text.getElementsByTagName('img'))
    }
    let selectQuestion = (id) => {
        setSelectedGroup(id);
        let qw = groups.find(value => value.faq_category_id === id);
        setElementIndex(null);
        setQuestionsList([{element_index: -1, faq_content_question: "Первым"}, ...(qw ? qw.faq_content : [])]);
    }

    let addFAQ = async () => {
        let res = await api.addFAQQuestion({title, text, elementIndex, groupId: selectedGroup, groupName})
        if (res) {
            window.alert("Успешно добавлено");
            document.location.reload();
        } else {
            window.alert("Ошибка при добавлении");
        }
    }

    return (
        <>
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <MDBContainer fluid>
                    <MDBRow className="mb-3">
                        <MDBCol size="12">
                            <Typography variant="h5" component="h5">
                                Добавление FAQ
                            </Typography>
                        </MDBCol>
                    </MDBRow>

                    <MDBCard className="mt-2">
                        <MDBCardBody className="p-2">
                            <MDBRow>
                                <MDBCol size="12">
                                    <TextField
                                        label="Заголовок"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        className="m-0"
                                        value={title}
                                        onChange={ event => setTitle(event.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-2">
                                <MDBCol size="6" id="coller">
                                    {
                                    addCategory ? (
                                        <>
                                            <IconButton aria-label="Изменить" ref={ref} size="small" color="inherit" onClick={ ()=> {setAddCategory(false); setElementIndex(null); setGroupName(null);} } className="mt-1" >
                                                <BackspaceIcon  />
                                            </IconButton>
                                            <TextField
                                                label="Название новой категории"
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                style={{width, marginLeft: 5}}
                                                className="my-0"
                                                value={groupName}
                                                onChange={ event => setGroupName(event.target.value)}
                                            />
                                        </>) : (
                                            <>
                                                <IconButton aria-label="Изменить" ref={ref} size="small" color="inherit" onClick={ ()=> {setAddCategory(true); setElementIndex(null); setSelectedGroup(null) }} className="mb-1" >
                                                    <AddIcon  />
                                                </IconButton>
                                                <Dropdown
                                                    value={selectedGroup}
                                                    options={groups}
                                                    optionLabel="faq_category_name"
                                                    optionValue="faq_category_id"
                                                    onChange={ (e) => selectQuestion(e.target.value)}
                                                    placeholder="Категория"
                                                    style={{width, marginLeft: 5}}
                                                    filter
                                                    filterPlaceholder="Поиск"
                                                />
                                            </>
                                        )
                                    }
                                </MDBCol>
                                <MDBCol size="6">
                                    {
                                    addCategory ? (
                                        <Dropdown
                                            value={elementIndex}
                                            options={[{faq_category_name: "Первым", element_index: -1}, ...groups]}
                                            optionLabel="faq_category_name"
                                            optionValue="element_index"
                                            onChange={(e) => setElementIndex(e.target.value)}
                                            placeholder="После категории"
                                            className="w-100"
                                            filter
                                            filterPlaceholder="Поиск"
                                        />
                                    ) :
                                    (
                                        <Dropdown
                                            value={elementIndex}
                                            options={questionsList}
                                            optionLabel="faq_content_question"
                                            optionValue="element_index"
                                            onChange={(e) => setElementIndex(e.target.value)}
                                            placeholder="После вопроса"
                                            className="w-100"
                                            filter
                                            filterPlaceholder="Поиск"
                                        />)
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="mt-2">
                        <MDBCardBody className="p-2">
                            <MDBRow>
                                <MDBCol size="12">
                                    <Editor style={{height:'320px'}} value={text} onTextChange={(e) => showTEST(e.htmlValue)} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBRow className="mt-3">
                        <MDBCol size="12">
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                startIcon={<AddIcon />}
                                onClick={() => addFAQ()}
                            >Добавить
                            </Button>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </main>
        </>
    );
}
