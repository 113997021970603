import {MDBBtn, MDBCol, MDBIcon, MDBPopover, MDBPopoverBody, MDBRow} from "mdbreact";
import LogContainer from "../../LogContainer";
import React, {useEffect, useState} from "react";
import ApiService from "../../../services/ApiService";
import './LogPopover.css';
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";

addLocale('ru', {
    firstDayOfWeek: 1,
    dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    dayNamesMin: ["В", "П", "В", "С", "Ч", "П", "С"],
    dayNamesShort : ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
    monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
});
// let ru = {
//     firstDayOfWeek: 1,
//     dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
//     dayNamesMin: ["В", "П", "В", "С", "Ч", "П", "С"],
//     dayNamesShort : ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
//     monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
// }
let api = new ApiService();

export default (props) => {
    const [logItems, setLogItems] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [dateRange, setDateRange] = useState(new Date());
    async function selectDate(date) {
        let res = await api.getNotification(date)
        setNotifications(res);
        setDateRange(date)
    }
    useEffect(() => {
        const getData = async () => {
            let res = await api.getNotification([new Date()]);
            setNotifications(res);
            setLogItems(res.length)
        };
        getData()
    }, []);

    return (
        <MDBPopover
            placement={props.placement}
            popover
            clickable
        >
            <MDBBtn size="sm" color="" className={props.className}>
                <MDBIcon far icon="bell" size={props.size}/>
                {logItems}
            </MDBBtn>
            <div>
                <MDBRow between className="p-2">
                    <MDBCol size="auto" middle>
                        <strong className="h5 text-warning">Уведомления</strong>
                    </MDBCol>
                    <MDBCol size="auto">
                        <Calendar
                            value={dateRange}
                            onChange={(e) => selectDate(e.value)}
                            readOnlyInput
                            selectionMode="range"
                            placeholder="Диапазон дат"
                            maxDate={new Date()}
                            locale="ru"
                            monthNavigator
                            yearNavigator yearRange="2020:2030"
                            showOtherMonths={false}
                            inputClassName="py-1"
                            dateFormat="dd.mm.yy"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBPopoverBody className={"p-0 overflow-auto " + (props.sideBar ? "sideBarPopoverStyle" : "navBarPopoverStyle") }>
                    <LogContainer notifications={notifications} setLogItems={setLogItems}/>
                </MDBPopoverBody>
            </div>
        </MDBPopover>
    )
}
