import React, { useEffect } from 'react';
import { Form, Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";

const StoreEditModal = ({show, close, selectedStore, editStore}) => {
   const [name, setName] = React.useState("");

   useEffect(() => {
      if (selectedStore) {
         setName(selectedStore.name)
      }
   }, [selectedStore]);

   const editStoreModal = async () => {
      await editStore(selectedStore.id, name);
      setName("")
      close();
   }

   return (
     <>
        <Modal show={show} onHide={close}>
           <Modal.Header closeButton>
              <Modal.Title>Редактирование точки</Modal.Title>
           </Modal.Header>
           <Modal.Body>

              <Form>
                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Название точки"
                      autoFocus
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                 </Form.Group>
              </Form>
           </Modal.Body>
           <Modal.Footer>
              <Button
                variant="danger"
                onClick={editStoreModal}
              >
                 Редактировать
              </Button>
           </Modal.Footer>
        </Modal>
     </>
   );
};

export default StoreEditModal;