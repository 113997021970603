import {useEffect, useState} from "react";
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBModal, MDBModalBody,
    MDBModalFooter, MDBModalHeader, MDBRow
} from "mdbreact";
import ApiService from "../../../services/ApiService";
import React from "react";
import {MultiSelect} from "primereact/multiselect";
import {InputSwitch} from "primereact/inputswitch";

export default function PositionsAddEditModal(props) {
    let api = new ApiService();

    // //Запрашиваемые данные
    let [dishesCategories, setDishesCategories] = useState([]);

    // //Вводимые данные
    let [dish_category_id, setDish_category_id] = useState();
    let [dish_category_name, setDish_category_name] = useState("");
    let [icon_link, setIcon_link] = useState();
    let [is_banned, setIs_banned] = useState(0);
    let [dishes, setDishes] = useState();

    let addDishesCategory = () => {
        api.addDishCategory( {
                dish_category_name,
                icon_link: icon_link ? icon_link : "",
                is_banned,
                dishes
            }
        ).then( (data) => {
            props.onAdd(data);
        })
    };

    let editCategory = async () => {
        let dishCategory = await api.editDishCategory({
            dish_category_id,
            dish_category_name,
            icon_link: icon_link ? icon_link : "",
            is_banned
        });
        props.onEdite(dishCategory)
    };


    useEffect(() => {
        if (props.dishes) {
            let arr = [];
            props.dishes.forEach( item => {
                item.dishes.forEach( item2 => arr.push( { value: item2.dish_id, label: item.dish_category_name + ": " + item2.dish_name}))
            });
            setDishesCategories(arr)
        } else if (props.selectedCategory) {
            setDish_category_id(props.selectedCategory.dish_category_id);
            setDish_category_name(props.selectedCategory.dish_category_name);
            setIcon_link(props.selectedCategory.icon_link);
            setIs_banned(Number(props.selectedCategory.is_banned));
        }
    }, [props.dishes, props.selectedCategory]);

    return (
        <MDBModal isOpen={props.show} toggle={() => props.toggle(false)} >

            <MDBModalHeader toggle={ () => props.toggle(false) } className="py-1 shadow-sm" >{ props.selectedCategory?.dish_category_id ? "Редактирование категории" : "Новая категория"}</MDBModalHeader>

            <MDBModalBody>
                <MDBRow>
                    <MDBCol className="mx-auto px-3">
                        <form>
                            <div className="grey-text">
                                <MDBInput label="Название" icon="signature" group type="text" className={"mb-3"} value={dish_category_name} onChange={ (e) => setDish_category_name(e.target.value) }/>
                                <MDBInput label="Ссылка на иконку" icon="link" group type="text" className={"mb-3"} value={icon_link} onChange={ (e) => setIcon_link(e.target.value) }/>
                                {
                                    props.dishes ? (
                                        <MultiSelect
                                            options={dishesCategories} value={dishes}
                                            onChange={(e) => setDishes(e.value)}
                                            placeholder="Блюда для данной категории"
                                            filter={true}
                                            className="col-12 mb-3"
                                            filterPlaceholder="Поиск"
                                            scrollHeight="450px"
                                        />
                                    ) : ""
                                }


                                <MDBCol className="p-0 align-middle d-flex">
                                    <InputSwitch checked={is_banned}
                                                 onChange={(e) => { setIs_banned(Number(e.value))} }
                                                 id="switcher"
                                    />
                                    <label htmlFor="switcher" className="ml-2 my-auto">Не участвует в батлах</label>
                                </MDBCol>

                            </div>
                        </form>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="py-1 shadow-sm">
                {
                    // props.position ?
                    //     <MDBBtn color="primary" size="sm"
                    //             onClick={ editPosition }
                    //             className={position_name === "" ||
                    //             !dish_categories.length ||
                    //             !custom_categories.length ||
                    //             !video_trainings.length ? "disabled" : ""
                    //             }>Изменить</MDBBtn>
                    //     :
                    props.dishes ?
                        <MDBBtn color="primary" size="sm"
                                onClick={ addDishesCategory }
                                className={ dish_category_name === "" ? "disabled" : ""}
                        >Создать</MDBBtn>
                        :
                        <MDBBtn color="primary" size="sm"
                                 onClick={ editCategory }
                                 className={ dish_category_name === "" ? "disabled" : ""}
                        >Изменить</MDBBtn>

                }
            </MDBModalFooter>
        </MDBModal>
    )
}
