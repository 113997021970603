import React, {useEffect, useState} from 'react';
import {
    MDBCol,
    MDBRow,
} from "mdbreact";
import ApiService from "../services/ApiService";
import {MultiSelect} from 'primereact/multiselect';
import {Dropdown} from "primereact/dropdown";

export default (props) => {
    let [isComplited, setIsComplited] = useState(false)
    let [reload, setReload] = useState(false)
    const [checked, setChecked] = useState(false);
    const [date, setDate] = useState("");
    let [position_ids, setPosition_id] = useState([]);
    let [isActive_ids, setIsActive_id] = useState('1');
    let [isIntern_ids, setIsIntern_ids] = useState('0');
    let [store_ids, setStore_id] = useState([]);
    let [territory_ids, setTerritory_id] = useState([]);

    // Запрашиваемые данные
    let [positions, setPositions] = useState([]);
    let [stores, setStores] = useState([]);
    let [territoryes, setTerritoryes] = useState([]);
    let [users, setUsers] = useState([]);

    useEffect(() => {
        let api = new ApiService();
        api.getSmallPositions().then(data => setPositions(data.data.Positions));
        api.getStores().then(data => setStores(data.data.Stores));
        api.getTerritoryes().then(data => setTerritoryes(data.data.Territoryes));
        api.getUsersShortListByFilter().then(data => setUsers(data.data.Users));
    }, []);

    useEffect(() => {
        let api = new ApiService();
        api.getUsersShortListByFilter({
                position_ids: JSON.stringify(position_ids),
                store_ids: JSON.stringify(store_ids),
                territory_ids: JSON.stringify(territory_ids),
                is_active: +isActive_ids,
                is_intern: +isIntern_ids,
            }
        ).then(data => {
            setUsers(data.data.Users);
            props.setUsers_id(data.data.Users.map(val => val.user_id))
        });
    }, [position_ids, store_ids, territory_ids, isActive_ids, isIntern_ids]);

    useEffect(() => {
        let api = new ApiService();
        if (territory_ids?.length > 0) {
            api.getStoresByTerritoryId(territory_ids).then(data => setStores(data.data.Stores));
            setStore_id([])
        } else {
            api.getStores().then(data => setStores(data.data.Stores));
        }
    }, [territory_ids])

    return (<>
        <MDBRow className="mb-2">
            <MDBCol size="4">
                <MultiSelect value={position_ids}
                             options={positions}
                             optionLabel="position_name"
                             optionValue="position_id"
                             onChange={(e) => setPosition_id(e.target.value)}
                             placeholder="Должность"
                             className="col-12"
                             filter
                             filterPlaceholder="Поиск"
                />
            </MDBCol>
            <MDBCol size="4">
                <MultiSelect value={territory_ids}
                             options={territoryes}
                             optionLabel="number"
                             optionValue="territory_id"
                             onChange={(e) => setTerritory_id(e.target.value)}
                             placeholder="Тер."
                             className="col-12 "
                             filter
                             filterPlaceholder="Поиск"
                />
            </MDBCol>
            <MDBCol size="4">
                <MultiSelect value={store_ids}
                             options={stores}
                             optionLabel="number"
                             optionValue="store_id"
                             onChange={(e) => setStore_id(e.target.value)}
                             placeholder="ТТ"
                             className="col-12"
                             filter
                             filterPlaceholder="Поиск"
                />
            </MDBCol>
            <MDBCol size="4" style={{marginTop: 10}}>
                <label htmlFor="statusLabel">Тип пользователей</label>
                <Dropdown
                    id='statusLabel'
                    value={isActive_ids}
                    options={[
                        {id: '1', title: 'Активныe'},
                        {id: '0', title: 'Неактивныe'},
                        {id: '2', title: 'Все'}
                    ]}
                    optionLabel="title"
                    optionValue="id"
                    onChange={(e) => setIsActive_id(e.target.value)}
                    placeholder="Активные"
                    className="col-12"
                />
            </MDBCol>
            <MDBCol size="4" style={{marginTop: 10}}>
                <label htmlFor="statusInternLabel">Статус пользователей</label>
                <Dropdown
                    id='statusInternLabel'
                    value={isIntern_ids}
                    options={[
                        {id: '0', title: 'В штате'},
                        {id: '1', title: 'Стажер'},
                    ]}
                    optionLabel="title"
                    optionValue="id"
                    onChange={(e) => setIsIntern_ids(e.target.value)}
                    placeholder="Статус"
                    className="col-12"
                />
            </MDBCol>
        </MDBRow>

        <MDBRow>
            <MDBCol size="12">
                <MultiSelect value={props.user_ids}
                             options={users}
                             optionLabel="full_name"
                             optionValue="user_id"
                             onChange={(e) => props.setUsers_id(e.target.value)}
                             placeholder="Пользователи"
                             className="col-12"
                             filter
                             filterPlaceholder="Поиск"
                />
            </MDBCol>
        </MDBRow>
    </>);
}
