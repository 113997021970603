import React, {useEffect, useState} from 'react';
import ApiService, { BACK_URL } from "../services/ApiService";
import Panels from "./Panels";
import {MDBIcon} from "mdbreact";
import MaterialTable from "material-table";
import CustomEditModal from "./Modals/CustomEditModal";
import Notifications from "./Modals/Notifications";
import { formateTextForTg } from "../utils/formateTextForTg";

function createMarkup(html) {
    return {__html: html.replace(/\n/ig, "<br>")}
}

const api = new ApiService();
const CustomQuestionsTable = (props) => {

    const [questionList, setQuestionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [statusMsg, setStatusMsg] = useState('Загрузка данных');
    const [show, setShow] = useState(false); // Состояние модалки создания вопроса
    const [queueNotifications, setQueueNotifications] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setStatusMsg('Нет данных');
        }, 5000)
    }, [])

    const getData = async () => {
        let data = await api.getCustomCategorieById(props.selectedCustomCategorie.custom_category_id);
        setQuestionList(data.data.CustomQuestions)
        setIsLoading(false);
    };

    useEffect(async () => {
        if (!props.selectedCustomCategorie) return;
        await getData();
    }, [props.selectedCustomCategorie, props.rerender]);

    const addNotifications = (text) => {
        setQueueNotifications(queueNotifications.concat([text]));
    }

    const addQuestion = async (...any) => {
        let url="";
        if (any[0]) {
            if (typeof any[0] === "string") {
                url = any[0]
            } else {
                let res = await api.uploadFile(any[0])
                url = await res.text();
            }
        }
        let newData = await api.addCustomQuestion({
            custom_category_id: +any[4],
            newDataQuestion: {
                question: formateTextForTg(any[1]),
                answers: any[2],
                right_answers: any[3],
            },
            image: url,
            is_editable: +any[5]
        })
            .then(() => {
                getData();
                setShow(false);
                addNotifications("Вопрос успешно добавлен!");
            })
            .catch( (e) => {
            setQueueNotifications(queueNotifications.concat(["Произошла ошибка!"]));
            console.log(e)
            throw e
        });
    }

    let columns = [
        {
            field: 'image',
            title: 'Картинка',
            export: false,
            render: rowData => (rowData.image ?
                <img src={BACK_URL + rowData.image} style={{width: 100, borderRadius: '10%'}}/> : "")
        },
        {
            title: 'Вопрос',
            field: 'question',
            sort: "disabled",
            cellStyle: (e, rowData) => ({
                backgroundColor: rowData.is_editable ? 'rgba(240,182,107,0.74)' : ''
            }),
            render: rowData => <span dangerouslySetInnerHTML={createMarkup(rowData.question)} />,
        },
        {
            title: 'Ответы',
            field: 'answers',
            sort: "disabled",
            render: rowData => rowData
                .answers
                .map((value2, index) =>
                    <Panels text={value2} key={index} border={rowData.right_answers === index ? "border-success" : ""}
                            className={index > 0 ? "mt-1 z-depth-0 border" : "z-depth-0 border"}/>
                )
        },
    ];

    let editable = {
        onRowDelete: async oldData => {
            await api.removeCustomQuestion({custom_question_id: oldData.custom_question_id})
            let data = questionList.filter(value => value.custom_question_id !== oldData.custom_question_id)
            setQuestionList(data)
        }
    };

    let actions = [
        rowData => ({
            icon: 'edite',
            tooltip: 'Изменить',
            onClick: (event, rowData) => {
                props.setSelectedQuestion(rowData)
                props.setShowModal(true)
            }
        }),
        {
            icon: 'add',
            iconProps: {
                color: "primary"
            },
            tooltip: 'Добавить',
            isFreeAction: true,
            onClick: (event) => {
                setShow(true);
            }
        }
    ];

    return (
        <>
            <Notifications queueNotifications={queueNotifications} />
            <CustomEditModal show={show}
                             toggle={setShow}
                             questionsList={true}
                             addCustom={true}
                             addQuestion={addQuestion}
                             customCategoryId={props.selectedCustomCategorie}
            />
            <MaterialTable
                columns={columns}
                data={questionList}
                isLoading={isLoading}
                title={<div onClick={() => {
                    props.backButton(0);
                }} style={{cursor: "pointer", fontSize: "1.25rem"}} className=""><MDBIcon icon="angle-left"
                                                                                          className="mr-2"/> {props.selectedCustomCategorie?.custom_category_name}
                </div>}
                editable={editable}
                actions={actions}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}'
                    },
                    grouping: {
                        placeholder: "Перетащите заголовок сюда для группировки",
                        groupedBy: "Групировать по:"
                    },
                    toolbar: {
                        nRowsSelected: '{0} row(s) selected',
                        searchTooltip: "Поиск",
                        searchPlaceholder: "Поиск",
                        exportTitle: "Экспорт",
                        exportAriaLabel: "Экспорт",
                        exportName: "Экспорт в CSV"
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        deleteTooltip: "Удалить",
                        editRow: {
                            deleteText: "Подтвердите удаление!"
                        },
                        emptyDataSourceMessage: statusMsg
                    }
                }}
                options={{
                    paging: false,
                    padding: "dense",
                    toolbarButtonAlignment: "left",
                    actionsCellStyle: {
                        padding: "0px 15px"
                    }
                }}
            />
        </>
    )
};

export default CustomQuestionsTable;
