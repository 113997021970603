import {useEffect, useState} from "react";
import {
    MDBBtn,
    MDBCol,
    MDBModal, MDBModalBody,
    MDBModalFooter, MDBModalHeader, MDBRow
} from "mdbreact";
import React from "react";
import {InputSwitch} from "primereact/inputswitch";
import ApiService from "../../../services/ApiService";
import { MultiSelect } from "primereact/multiselect";
import { Form } from "react-bootstrap";

const api = new ApiService();
export default function (props) {
    const [mode, setMode] = useState(null);
    const [newIngredient, setNewIngredient] = useState("");
    const [ingredientRows, setIngredientRows] = useState([]);
    let [dishIngredients, setDishIngredients] = useState([]);

    useEffect( () => {
        setMode(props.dish.is_new);
        const arrIngreds = props.dish.ingredients?.map(i => i.ingredient_id.toString());
        setDishIngredients(arrIngreds);
    }, [props.dish.is_new])

    useEffect(() => {
        getIngredients();
    }, [props.selectedCategory]);

    const getIngredients = async () => {
        if (props.selectedCategory) {
            let data = await api.getDishesByCategoryId( props.selectedCategory?.dish_category_id );
            if (data.data?.Dishes) {
                let set = new Set();
                let ingredientsRows = [];
                data.data.Dishes.forEach( item => item.ingredients.forEach( item => {
                        if (!set.has(item.ingredient_id)) {
                            ingredientsRows.push(item)
                            set.add(item.ingredient_id)
                        }
                    })
                );
                // setSelectedIngredient(ingredientsRows[0]);
                setIngredientRows(ingredientsRows);
            }
        }
    };

    return (
        <MDBModal isOpen={props.show} toggle={() => props.toggle(false)} >
            <MDBModalHeader toggle={ () => props.toggle(false) } className="py-1 shadow-sm" >Настройка блюда:</MDBModalHeader>

            <MDBModalBody>
                <MDBRow>
                    <MDBCol className="align-middle d-flex">
                        <InputSwitch checked={mode}
                                     onChange={(e) => { setMode(Boolean(e.value))} }
                                     id="switcher"
                        />
                        <label htmlFor="switcher" className="ml-2 my-auto">Новое блюдо</label>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol className="align-middle d-flex">
                        <MultiSelect optionLabel="ingredient_name" optionValue="ingredient_id"
                                     options={ingredientRows}
                                     value={dishIngredients}
                                     onChange={(e) => setDishIngredients(e.value)}
                                     placeholder="Ингредиенты"
                                     filter={true}
                                     className="col-12 mb-3 mt-3"
                                     filterPlaceholder="Поиск"
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol className="align-middle d-flex">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Новый ингредиент</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Название ингредиента"
                                autoFocus
                                value={newIngredient}
                                onChange={(e) => setNewIngredient(e.target.value)}
                            />
                        </Form.Group>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="py-1 shadow-sm">
                    <MDBBtn color="primary" size="sm"
                            onClick={async () => {
                                props.onEditDishSetting(props.dish.dish_id, mode, dishIngredients, newIngredient)
                                setNewIngredient("");
                                await getIngredients();
                            } }
                    >Изменить</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    )
}
