import React, { useState, useEffect } from 'react';
import {
   MDBCard,
   MDBCardBody,
   MDBListGroup,
   MDBListGroupItem,
   MDBCardHeader,
   MDBCol,
   MDBRow,
   MDBCardTitle,
   MDBBtn,
   MDBInput,
} from 'mdbreact';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';

import ApiService from '../../../services/ApiService';
import AddVideoModal from "../AddModal/AddVideoModal.js";
import Notifications from "../../../components/Modals/Notifications";
import InfoPopup from "../../../components/InfoPopup";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EditVideoModal from "../EditModal/EditVideoModal";
import styles from './VideoList.module.css';

const apiService = new ApiService();
export const VideoList = () => {
   const [videoList, setVideoList] = useState([{}]);
   const [video, setVideo] = useState("");
   const [cookie, setCookie] = useState(0);
   const [timer, setTimer] = useState(null);
   const [video_training_name, setVideo_training_name] = useState("");
   const [video_training_id, setVideo_training_id] = useState();
   const [success, setSuccess] = useState("");
   const [failure, setFailure] = useState("");
   const [preface, setPreface] = useState("");
   const [questions, setQuestions] = useState([]);
   const [open, setOpen] = useState(false);
   const [modal, setModal] = useState(false);
   const [rerender, setRerender] = useState(false);
   const [queueNotifications, setQueueNotifications] = useState([]);

   const [modalEditVideo, setModalEditVideo] = useState(false);

   const getVideos = async () => {
      let res = await apiService.getVideos();
      setVideoList(res.data.VideoTrainingCategories);
      return res;
   }

   const openEditVideoModal = () => {
      setModalEditVideo(true);
   }

   const deleteVideoTraining = async (id) => {
      if (!window.confirm("Вы точно хотите удалить этот видео-тренинг?")) return;
      await apiService.deleteVideoTraining(id)
        .then(async data => {
           if (data.data.deleteVideoTraining) {
              await getVideos()
              setQueueNotifications(queueNotifications.concat(['Видео-тренинг успешно удален']));
           } else {
              setQueueNotifications(queueNotifications.concat(['Видео-тренинг не получилось удалить']));
           }
        })
   }

   const toggleEditModal = async () => {
      const res = await getVideos();
      await onItemSelected(res.data.VideoTrainingCategories[0].video_trainings[0].video_training_id)
      setModalEditVideo(!modalEditVideo);
   }

   useEffect(() => {
      try {
         const res = getVideos()
         onItemSelected(res.data.VideoTrainingCategories[0].video_trainings[0].video_training_id)
      } catch (e) {
         console.log('------', e)
      }
   }, [])

   useEffect(() => {
      try {
         getVideos()
      } catch (e) {
         console.log('------', e);
      }
   }, [rerender]);

   const onItemSelected = async (Id) => {
      try {
         const res = await apiService.getVideo(Id);
         const video_data = res.data.VideoTrainings[0];
         setVideo(video_data.video);
         setQuestions(video_data.questions);
         setVideo_training_name(video_data.video_training_name);
         setVideo_training_id(Id);
         setSuccess(video_data.success);
         setFailure(video_data.failure);
         setPreface(video_data.preface);
         setCookie(video_data.cookies);
         setTimer(dayjs(video_data.timer, 'HH:mm:ss'));
      } catch (e) {
         console.log('------', e)
      }
   };

   const editVideo = async (id) => {
      await apiService.editVideoTraining(id, cookie, dayjs(timer, 'mm:ss').format('00:mm:ss'))
        .then(() => {
           setQueueNotifications(queueNotifications.concat(["Печеньки/таймер изменены"]));
        })
        .catch((e) => {
           setQueueNotifications(queueNotifications.concat(["Ошибка при изменении печенек/таймера" + e]));
        });
   }

   const renderItems = (arr) => {
      return arr.map((item, index) => (
          <>
             <MDBListGroupItem
               className="info-color white-text py-2"
               key={index}
             >
                <b>{item.video_training_category_name}</b>
             </MDBListGroupItem>
             {
                item.video_trainings?.map(({ video_training_id, video_training_name }) => (
                  <MDBListGroupItem
                    hover
                    className="py-2 pl-5 border-right-0 border-left-0 d-flex align-items-center justify-content-between"
                    key={video_training_id}
                    onClick={() => onItemSelected(video_training_id)}
                  >
                                <span>
                                    {video_training_name}
                                </span>
                     <div className="d-flex align-items-center">
                        <button
                          onClick={openEditVideoModal}
                          className={styles['button']}
                        >
                           <EditIcon/>
                        </button>
                        <button
                          onClick={() => deleteVideoTraining(video_training_id)}
                          className={styles['button']}
                        >
                           <DeleteIcon/>
                        </button>
                     </div>
                  </MDBListGroupItem>
                ))
             }
          </>
        )
      );
   }

   const toggle = (number) => {
      if (number === open) {
         setOpen(false);
         return;
      }
      setOpen(number)
   };

   const addButtonClick = () => {
      setModal(true);
   }

   const toggleAddModal = () => {
      setModal(false);
   }
   const addedVideo = () => {
      setRerender(!rerender);
      setQueueNotifications(queueNotifications.concat(["Видео добавлено"]));
      setModal(false);
   }

   let new_link = video.split('/')

   new_link = "https://www.youtube.com/embed/" + new_link[new_link.length - 1];

   const onChangeTime = (time) => {
      setTimer(time);
   };

   return (
     <MDBRow>
        <Notifications queueNotifications={queueNotifications}/>
        <AddVideoModal modal={modal} toggle={toggleAddModal} rerender={addedVideo}/>
        <EditVideoModal modal={modalEditVideo}
                        toggle={toggleEditModal}
                        questionList={questions}
                        link={video}
                        title={video_training_name}
                        video_training_id={video_training_id}
                        success={success}
                        failure={failure}
                        timerTime={timer}
                        cookie={cookie}
                        queueNotifications={queueNotifications}
                        setQueueNotifications={setQueueNotifications}
        />
        <MDBCol md="12" lg='6' xl="4" className="mb-3">
           <MDBCard>
              <MDBCardHeader className="py-1">
                 <MDBRow className='d-flex align-items-center'>
                    <MDBCol md='6' className='d-flex align-items-center'>
                       Обучающие видео:
                       <MDBBtn outline color="primary" size="sm" onClick={() => {
                          addButtonClick()
                       }}>Добавить</MDBBtn>
                    </MDBCol>
                    <MDBCol md='1' className="ml-auto">
                       {/*  Тут я хотел добавить тултип, но он не нужен :(  */}
                    </MDBCol>
                 </MDBRow>
              </MDBCardHeader>
              <MDBCardBody className="p-0">
                 <MDBListGroup>
                    {renderItems(videoList)}
                 </MDBListGroup>
              </MDBCardBody>
           </MDBCard>
        </MDBCol>

        <MDBCol md="12" lg='6' xl="8">
           <MDBCard>
              <MDBCardHeader className="">Видео: <span
                className="text-warning font-weight-bolder">{video_training_name}</span></MDBCardHeader>
              <MDBCardBody>
                 <div className="mb-3 border-bottom border-light">
                    <h4>{preface}</h4>
                 </div>
                 {video !== "" ? <iframe width="100%" height="315" src={new_link} frameborder="0"
                                         allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                         allowfullscreen></iframe> : ""}

                 <MDBCard color="success-color" text="white" className="mt-3">
                    <MDBCardBody className="p-2">
                       {success}
                    </MDBCardBody>
                 </MDBCard>

                 <MDBCard color="danger-color" text="white" className="mt-3">
                    <MDBCardBody className="p-2">
                       {failure}
                    </MDBCardBody>
                 </MDBCard>

                 {questions.length ? <MDBCardTitle className="mt-3 mb-0">Вопросы: </MDBCardTitle> : ''}
                 <MDBListGroup className="border-0">
                    {questions.map(({
                                       video_training_question_id,
                                       question,
                                       answers,
                                       right_answers
                                    }, number) => {
                       return (
                         <MDBListGroupItem
                           className={`${styles['listElem']} border-top-0 border-right-0 border-left-0 px-2 py-2` + (number === open ? " active" : "")}
                           key={video_training_question_id}
                           style={{ cursor: "pointer" }}
                           onClick={() => toggle(number)}>
                            {question}
                            <MDBListGroup
                              className={number === open ? "mx-2 my-2 black-text z-depth-1" : "d-none"}>
                               {answers.map((e, key) => {
                                  return (
                                    <MDBListGroupItem
                                      className={`${styles['listElem']} border-top-0 border-right-0 border-left-0 px-2 py-1 ` + (key === right_answers ? "green white-text" : "")}>
                                       {e}
                                    </MDBListGroupItem>)
                               })}
                            </MDBListGroup>
                         </MDBListGroupItem>
                       );
                    })}
                 </MDBListGroup>

                 <MDBCard>
                    <MDBCardBody>
                       <MDBRow className='d-flex align-items-center'>
                          <MDBCol xl={'1'}>
                             <MDBInput label='Печеньки'
                                       id={video_training_id}
                                       type='number'
                                       value={cookie}
                                       onChange={e => setCookie(e.target.value)}
                                       min="0"
                                       style={{ borderBottom: 'none' }}
                               // validate={}
                             />
                          </MDBCol>
                          <MDBCol xl={'4'}>
                             <div className='d-flex flex-column justify-content-center align-items-start'>
                                <label htmlFor="time">Продолжительность видео (минуты:секунды)</label>
                                <TimePicker
                                  id="time"
                                  allowClear={false}
                                  value={timer}
                                  format={'mm:ss'}
                                  onChange={onChangeTime}
                                />
                             </div>
                          </MDBCol>
                          <MDBCol xl={'1'}>
                             <MDBBtn outline rounded style={{ borderRadius: 100 }}
                                     onClick={() => editVideo(video_training_id)}>
                                Сохранить
                             </MDBBtn>
                          </MDBCol>
                          <MDBCol xl='1' className="ml-auto">
                             <InfoPopup
                               text={'Здесь указываем количество печенек, которые будет получать пользователь за прохождение. Продолжительность видео нужно указывать, чтобы пользователь не смог пройти тренинг без просмотра видео. Он отвечает, через какой промежуток времени сотрудник сможет начать проходить дальше.'}/>
                          </MDBCol>
                       </MDBRow>
                       <MDBRow>


                       </MDBRow>
                    </MDBCardBody>
                 </MDBCard>

                 {/* <span role="img">✅</span> */}
              </MDBCardBody>
           </MDBCard>
        </MDBCol>
     </MDBRow>
   )
}

