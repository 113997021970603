import {useEffect, useState} from "react";
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBModal, MDBModalBody,
    MDBModalFooter, MDBModalHeader, MDBRow
} from "mdbreact";
import { MultiSelect } from 'primereact/multiselect';
import ApiService from "../../../services/ApiService";
import React from "react";
import {InputSwitch} from "primereact/inputswitch";
import InternShipDragTable from "../../InternShipDragTable";
import arrayMove from 'array-move';

export default function PositionsAddEditModal(props) {
    let api = new ApiService();

    //Запрашиваемые данные
    let [listCustomCategories, setListCustomCategories] = useState([]);
    let [listDishCategories, setListDishCategories] = useState([]);
    let [listVideoTrainings, setListVideoTrainings] = useState([]);
    let [listInternshipCourses, setListInternshipCourse] = useState([]);
    let [listCertificationCourses, setListCertificationCourse] = useState([]);
    //Вводимые данные
    let [position_id, setPosition_id] = useState();
    let [position_name, setPosition_name] = useState("");
    let [dish_categories, setDish_categories] = useState([]);
    let [custom_categories, setCustom_categories] = useState([]);
    let [internship_courses, setInternship_courses] = useState([]);
    let [structured_courses, setStructured_courses] = useState([]);
    let [video_trainings, setVideo_trainings] = useState([]);
    let [certifications, setCertifications] = useState([]);
    let [send_reating, setSend_reating] = useState(1);

    let addPosition = async () => {
        let coursesList = internship_courses.map( (value, index) => {
            return {
                structured_course_id: value.structured_course_id,
                default_element_index: Number(index),
                default_is_mandatory: value.default_is_mandatory ? value.default_is_mandatory : false,
            }
        })
        await api.addPosition( {"input": {
                position_name,
                send_reating,
                dish_categories_id: dish_categories,
                custom_categories_id: custom_categories,
                video_trainings_id: video_trainings,
                certifications_id: certifications,
                internship_courses: coursesList,
                structured_courses: structured_courses
            }}
        ).then(async (data) => {
            await props.addedNewPosition(data)
        })
    };
    let editPosition = () => {
        let coursesList = internship_courses.map( (value, index) => {
            return {
                structured_course_id: value.structured_course_id,
                default_element_index: Number(index),
                default_is_mandatory: value.default_is_mandatory ? value.default_is_mandatory : false,
            }
        })
        api.editPosition( {
            "position_id":  position_id,
            "input": {
                    position_name,
                    send_reating,
                    dish_categories_id: dish_categories,
                    custom_categories_id: custom_categories,
                    video_trainings_id: video_trainings,
                    certifications_id: certifications,
                    internship_courses: coursesList,
                    structured_courses: structured_courses
                }
            }
        ).then( (data) => {
                props.editedPosition(data)
        })
    };

    let setDefault_is_mandatory = (index, value) => {
        let copy = internship_courses.slice()
        copy[index]['default_is_mandatory'] = value
        setInternship_courses(copy)
    }
    let onSortEnd = ({oldIndex, newIndex}) => {
        setInternship_courses(arrayMove(internship_courses, oldIndex, newIndex))
    };

    useEffect(() => {
        let api = new ApiService();
        api.graphQuery(`
            {
              DishesCategories{
                dish_category_id
                dish_category_name
                icon_link
              }
              CustomCategories{
                custom_category_id
                custom_category_name
                icon_link
              }
              VideoTrainings{
                video_training_id
                video_training_name
              }
              StructuredCourses {
                structured_course_id
                structured_course_name
              }
              Certifications {
                certification_id
                certification_name
              }     
            }
        `).then( data => {
            setListDishCategories(data.data.DishesCategories);
            setListCustomCategories(data.data.CustomCategories);
            setListVideoTrainings(data.data.VideoTrainings);
            setListCertificationCourse(data.data.Certifications);
            setListInternshipCourse(data.data.StructuredCourses);
        })
    }, []);

    useEffect(() => {
        if (props.position) {
            setPosition_id(props.position.position_id);
            setPosition_name(props.position.position_name);
            setSend_reating(props.position.send_reating);
            setDish_categories(props.position.dish_categories.map( val => val.dish_category_id));
            setCustom_categories(props.position.custom_categories.map( val => val.custom_category_id));
            setVideo_trainings(props.position.video_trainings.map( val => val.video_training_id));
            setCertifications(props.position.certifications.map( val => val.certification_id));
            setInternship_courses(props.position.internship_courses.filter(val => val.default_is_internship));
            setStructured_courses(props.position.internship_courses.filter(val => !val.default_is_internship).map( val => val.structured_course_id));
        }
    }, [props.position]);

    return (
        <MDBModal isOpen={props.show} toggle={() => props.toggle(false)} size="lg" >
            <MDBModalHeader toggle={ () => props.toggle(false) } className="py-1 shadow-sm" >{ props.position ? "Редактирование" : "Новая должность"}</MDBModalHeader>
            <MDBModalBody className="pb-0">
                <MDBRow className="mb-2">
                    <MDBCol size="12">
                        <MDBInput label="Название" icon="address-card" type="text" value={position_name} onChange={ (e) => setPosition_name(e.target.value) }/>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2 pb-2 border-bottom">
                    <MDBCol md="2"  middle className="font-weight-bolder">
                        Меню:
                    </MDBCol>
                    <MDBCol md="10" >
                        <MultiSelect optionLabel="dish_category_name" optionValue="dish_category_id"
                                     options={listDishCategories} value={dish_categories}
                                     onChange={(e) => setDish_categories(e.value)}
                                     placeholder="Пусто"
                                     filter={true}
                                     className="col-12"
                                     filterPlaceholder="Поиск"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2 pb-2 border-bottom">
                    <MDBCol md="2"  middle className="font-weight-bolder">
                        Функционал:
                    </MDBCol>
                    <MDBCol md="10" >
                        <MultiSelect optionLabel="custom_category_name" optionValue="custom_category_id"
                                     options={listCustomCategories} value={custom_categories}
                                     onChange={(e) => setCustom_categories(e.value)}
                                     placeholder="Пусто"
                                     filter={true}
                                     className="col-12"
                                     filterPlaceholder="Поиск"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2 pb-2 border-bottom">
                    <MDBCol md="2"  middle className="font-weight-bolder">
                        Видео:
                    </MDBCol>
                    <MDBCol md="10" >
                        <MultiSelect optionLabel="video_training_name" optionValue="video_training_id"
                                     options={listVideoTrainings} value={video_trainings}
                                     onChange={(e) => setVideo_trainings(e.value)}
                                     placeholder="Пусто"
                                     filter={true}
                                     className="col-12"
                                     filterPlaceholder="Поиск"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2 pb-2 border-bottom">
                    <MDBCol md="2"  middle className="font-weight-bolder">
                        Аттестации:
                    </MDBCol>
                    <MDBCol md="10" >
                        <MultiSelect optionLabel="certification_name" optionValue="certification_id"
                                     options={listCertificationCourses} value={certifications}
                                     onChange={(e) => setCertifications(e.value)}
                                     placeholder="Пусто"
                                     filter={true}
                                     className="col-12"
                                     filterPlaceholder="Поиск"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2 pb-2">
                    <MDBCol md="2"  middle className="font-weight-bolder">
                        Курсы:
                    </MDBCol>
                    <MDBCol md="10" >
                        <MultiSelect optionLabel="structured_course_name" optionValue="structured_course_id"
                                     options={listInternshipCourses} value={structured_courses}
                                     onChange={(e) => setStructured_courses(e.value)}
                                     placeholder="Пусто"
                                     filter={true}
                                     className="col-12"
                                     filterPlaceholder="Поиск"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="2"  middle className="font-weight-bolder">
                        Адаптации:
                    </MDBCol>
                    <MDBCol md="10">
                        <MultiSelect optionLabel="structured_course_name" optionValue="structured_course_id"
                                     options={listInternshipCourses} value={internship_courses.map( val => val.structured_course_id)}
                                     onChange={(e) => {
                                         let new_list = e.value.map( value => listInternshipCourses.find( course => course.structured_course_id == value))
                                         let res = new_list.map( course =>
                                             internship_courses.findIndex( value => value.structured_course_id == course.structured_course_id) >= 0 ?
                                                 internship_courses.find( value => value.structured_course_id == course.structured_course_id) :
                                                 course
                                         )
                                         setInternship_courses(res)
                                     }}
                                     placeholder="Пусто"
                                     filter={true}
                                     className="col-12"
                                     filterPlaceholder="Поиск"
                        />
                    </MDBCol>
                </MDBRow>
                {
                    internship_courses.length > 0 ?
                    <MDBRow className="" end>
                        <InternShipDragTable internship_courses={internship_courses}
                                             listInternshipCourses={listInternshipCourses}
                                             setDefault_is_mandatory={setDefault_is_mandatory}
                                             onSortEnd={onSortEnd}
                                             title="Настройка адаптаций:"
                        />
                    </MDBRow> : ""
                }
                <MDBRow className="mt-2 pt-2 mb-2 border-top">
                    <MDBCol size="12" className="align-middle font-weight-bolder d-flex">
                        <label htmlFor="switcher" className="mr-2 my-auto">Отправка рейтингов</label>
                        <InputSwitch checked={send_reating}
                                     onChange={(e) => { setSend_reating(Number(e.value))} }
                                     id="switcher"
                        />
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="py-1 grey lighten-3">
                {
                    props.position ?
                        <MDBBtn color="warning" size="sm"
                                onClick={ editPosition }
                                className={"width-text " + position_name === "" ? "disabled" : ""
                                }>Изменить</MDBBtn>
                        :
                        <MDBBtn color="warning" size="sm"
                                onClick={ addPosition }
                                className={"width-text " + position_name === "" ? "disabled" : ""}
                        >Создать</MDBBtn>
                }

            </MDBModalFooter>
        </MDBModal>
    )
}
