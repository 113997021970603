import {
    MDBModal,
    MDBModalBody,
    MDBModalHeader
} from "mdbreact";
import React from "react";
import InternShipQuestionSender from "../InternShipQuestionSender";

export default (props) => {
    return (
        <MDBModal isOpen={props.show} toggle={() => props.toggle(false)} size="xl">
            <MDBModalHeader toggle={() => props.toggle(false)}
                            className="py-1 shadow-sm">{props.questionsList ? "Добавить вопрос" : "Редактировать"}</MDBModalHeader>
            <MDBModalBody className="mb-3 pb-0 px-0">
                <InternShipQuestionSender
                    selectForEditQuestion={props.selectedQuestion}
                    selectedDayForAddQuestion={props.selectedDayForAddQuestion}
                    editQuestion={props.editQuestion}
                    questionsList={props.questionsList}
                    addQuestion={props.addQuestion}
                    addCustom={props.addCustom}
                    customCategoryId={props.customCategoryId}
                />
            </MDBModalBody>
        </MDBModal>
    )
}
