import React, { useEffect, useState } from 'react';
import ApiService from "../services/ApiService";
import MaterialTable from "material-table";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBIcon } from "mdbreact";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const api = new ApiService();
export default (props) => {
    let [isLoading, setIsLoading] = useState(true);
    const [queueNotifications, setQueueNotifications] = useState([]); // Уведомление
    const [index, setIndex] = useState(0);


    useEffect(() => {
        setIsLoading(false);
    }, [props.reloadInternShip]);

    const downloadExcelFile = async () => {
        const excel = await api.getExcelTemporaryCourseQuiestionFile()
            .then(async data => {
                let url = URL.createObjectURL(data);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'Выгрузка изменяемых вопросов.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();

                URL.revokeObjectURL(url);
            });
    };

    let columns = [
        {
            title: 'Название',
            field: 'structured_course_name',
            editable: 'onUpdate',
            width: 150,
        },
        {
            title: 'Кол-во дней',
            field: 'structured_days',
            editable: 'never',
            render: rowData => rowData.structured_days.length,
        },
        {
            title: 'Печеньки за курс',
            field: 'cookies',
            editable: 'onUpdate'
        },
        {
            title: 'Блокировка',
            field: 'is_time_limit',
            editable: 'onUpdate',
            type: 'boolean',
        },
    ]
    let editable = {
        onRowArchive: async rowData => {
        },
        onRowFile: async rowData => {
        },
        onRowDelete: async oldData => {
            try {
                let res = await api.removeStructuredCourse(oldData.structured_course_id);
                if (res) {
                    let data = props.internshipCourses.filter(value => value.structured_course_id !== oldData.structured_course_id)
                    props.setInternshipCourses(data)
                    props.addNotifications("Курс успешно удален!")
                } else {
                    props.addNotifications("Произошла ошибка!")
                }
            } catch (e) {
                props.addNotifications("Произошла ошибка!")
            }
        },
        // onRowUpdate: async (newData, oldData) => {
        //     let res = await api.editStructuredCourseNameAndCookie(newData.structured_course_id, newData.structured_course_name, +newData.cookies, newData.is_time_limit);
        //     if (res) {
        //         const dataUpdate = [...props.internshipCourses];
        //         let index = props.internshipCourses.findIndex(value => {
        //             return value.structured_course_id === newData.structured_course_id
        //         });
        //         dataUpdate[index].structured_course_name = newData.structured_course_name;
        //         dataUpdate[index].cookies = newData.cookies;
        //         dataUpdate[index].is_time_limit = newData.is_time_limit;
        //         props.setInternshipCourses(dataUpdate);
        //         props.addNotifications("Курс успешно изменен!")
        //     } else {
        //         props.addNotifications("Произошла ошибка!")
        //     }
        // }
    }
    let actions = [
        {
            icon: 'add',
            iconProps: {
                color: "primary"
            },
            tooltip: 'Добавить',
            isFreeAction: true,
            onClick: (event) => props.toAddScreen()
        },
        {
            icon: () => <MDBIcon fas icon="file-upload"/>,
            iconProps: {
                color: "primary"
            },
            tooltip: 'Выгрузить изменяемые вопросы',
            isFreeAction: true,
            onClick: (event) => downloadExcelFile()
        },
        {
            icon: 'archive', // значок для архивирования
            tooltip: index === 0 ? 'Добавить в архив' : "Убрать из архива",
            onClick: (event, rowData) => changeArchiveCourse(rowData.structured_course_id, index === 0 ? true : false),
        },
        {
            icon: () => <MDBIcon fas icon="file-powerpoint" />, // значок для скачивания файла
            tooltip: 'Скачать файл',
            onClick: (event, rowData) => downloadFile(rowData.structured_course_id, rowData?.file),
        },
        {
            icon: () => <MDBIcon fas icon="pencil-alt" />,
            tooltip: 'Редактировать',
            onClick: (event, rowData) => {
                props.setSelectedStructuredCourse(rowData)
                props.setShowEditModal(true)
            }
        },

    ];

    const changeArchiveCourse = async (courseId, isArchive) => {
        await api.editStructuredCourseIsArchive(courseId, isArchive)
            .then(() => {
                props.getData();
                props.addNotifications(isArchive ? "Успешно добавили в архив" : "Успешно убрали из архива")
            });
    }

    const downloadFile = async (courseId, file) => {
        await api.downloadFileCourse({ courseId: courseId })
          .then(async data => {
              if (data.message) {
                  props.addNotifications("Файл отсутствует")
                  return
              }
              let url = URL.createObjectURL(data);

              const a = document.createElement('a');
              a.href = url;
              a.download = `file`;
              document.body.appendChild(a);
              a.click();
              a.remove();

              URL.revokeObjectURL(url);
          })
          .catch(e => {
              props.addNotifications("Файл отсутствует")
          });
    }

    return (

        <>
            {/*<main className="mt-5 mx-lg-3 mt-xl-0">*/}
                <MDBContainer fluid>
                    <MDBCard className="mb-2">
                        <MDBCardHeader className="p-0 vert">
                            <Tabs value={index}
                                  onChange={(event, index) => setIndex(index)}
                                  className="w-100"
                            >
                                <Tab label="Курсы" className="col" style={{ maxWidth: "none" }}/>
                                <Tab label="Архив" className="col" style={{ maxWidth: "none" }}/>
                            </Tabs>

                        </MDBCardHeader>
                        <MDBCardBody className="p-0">
                            <div className={index === 0 ? "" : "done"}>
                                <div className={index === 0 ? "" : "d-none"}>
                                    <MaterialTable
                                        columns={columns}
                                        data={props.internshipCourses}
                                        isLoading={isLoading}
                                        title="Курсы"
                                        editable={editable}
                                        actions={actions}
                                        options={{
                                            paging: false,
                                            exportDelimiter: ";",
                                            padding: "dense",
                                            toolbarButtonAlignment: "left",
                                            actionsCellStyle: {
                                                padding: "0px 15px"
                                            }
                                        }}
                                        localization={{
                                            pagination: {
                                                labelDisplayedRows: '{from}-{to} of {count}'
                                            },
                                            grouping: {
                                                placeholder: "Перетащите заголовок сюда для группировки",
                                                groupedBy: "Групировать по:"
                                            },
                                            toolbar: {
                                                nRowsSelected: '{0} row(s) selected',
                                                searchTooltip: "Поиск",
                                                searchPlaceholder: "Поиск",
                                                exportTitle: "Экспорт",
                                                exportAriaLabel: "Экспорт",
                                                exportName: "Экспорт в CSV"
                                            },
                                            header: {
                                                actions: 'Действия'
                                            },
                                            body: {
                                                deleteTooltip: "Удалить",
                                                editTooltip: 'Редактировать',
                                                editRow: {
                                                    deleteText: "Подтвердите удаление!"
                                                },
                                                emptyDataSourceMessage: "Загрузка данных"
                                            }
                                        }}
                                        onRowClick={(event, rowData) => {
                                            if (event.target.childNodes.length !== 2) props.onRowClick(rowData);
                                            // if (event.target.childNodes[0]?.nodeValue === rowData.structured_course_name) props.onRowClick(rowData);
                                        }}
                                    />
                                </div>

                                <div className={index === 1 ? "" : "d-none"}>
                                    <div className={index === 1 ? "" : "d-none"}>
                                        <MaterialTable
                                            columns={columns}
                                            data={props.internshipCoursesArchive}
                                            isLoading={isLoading}
                                            title="Архив"
                                            editable={editable}
                                            actions={actions}
                                            options={{
                                                paging: false,
                                                exportDelimiter: ";",
                                                padding: "dense",
                                                toolbarButtonAlignment: "left",
                                                actionsCellStyle: {
                                                    padding: "0px 15px"
                                                }
                                            }}
                                            localization={{
                                                pagination: {
                                                    labelDisplayedRows: '{from}-{to} of {count}'
                                                },
                                                grouping: {
                                                    placeholder: "Перетащите заголовок сюда для группировки",
                                                    groupedBy: "Групировать по:"
                                                },
                                                toolbar: {
                                                    nRowsSelected: '{0} row(s) selected',
                                                    searchTooltip: "Поиск",
                                                    searchPlaceholder: "Поиск",
                                                    exportTitle: "Экспорт",
                                                    exportAriaLabel: "Экспорт",
                                                    exportName: "Экспорт в CSV"
                                                },
                                                header: {
                                                    actions: 'Действия'
                                                },
                                                body: {
                                                    editTooltip: 'Редактировать',
                                                    deleteTooltip: "Удалить",
                                                    editRow: {
                                                        deleteText: "Подтвердите удаление!"
                                                    },
                                                    emptyDataSourceMessage: "Нет данных",
                                                }
                                            }}
                                            onRowClick={(event, rowData) => {
                                                if (event.target.childNodes.length !== 2) props.onRowClick(rowData);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            {/*</main>*/}
        </>
    );
}
