import React, { useEffect, useState } from 'react';
import { MDBCard, MDBCardBody, MDBContainer, MDBIcon } from "mdbreact";
import ApiService from "../../services/ApiService";
import Notifications from "../../components/Modals/Notifications";
import styles from "./Store.module.css";
import TerritoryAddModal from "./modals/TerritoryAddModal";
import TerritoryEditModal from "./modals/TerritoryEditModal";
import StoreAddModal from "./modals/StoreAddModal";
import StoreEditModal from "./modals/StoreEditModal"
import {
   Table,
   TableCell,
   TableRow,
   TableHead,
   TableBody,
} from '@mui/material';
import MaterialTable from "@material-table/core";

let options = {
   pageSize: 20,
   pageSizeOptions: [20, 50, 100],
   exportDelimiter: ";",
   exportAllData: true,
   exportButton: { pdf: false, csv: true },
   grouping: false,
   padding: "dense",
   toolbarButtonAlignment: "left",
   showDetailPanelIcon: false,
   actionsCellStyle: {
      padding: "0px 15px"
   }
};

const api = new ApiService();

const Store = () => {
   const [territoriesRows, setTerritoriesRows] = useState([]); // массив точек
   const [openRows, setOpenRows] = useState({});
   const [loading, setLoading] = useState(true);

   const [showAddTerritory, setShowAddTerritory] = useState(false); // состояние для модального окна
   const [showEditTerritory, setShowEditTerritory] = useState(false); // состояние для модального окна
   const [showAddStore, setShowAddStore] = useState(false); // состояние для модального окна
   const [showEditStore, setShowEditStore] = useState(false); // состояние для модального окна

   const [selectedTerritory, setSelectedTerritory] = useState(null);
   const [selectedStore, setSelectedStore] = useState(null);

   const [openTerritories, setOpenTerritories] = useState([]);
   const [storesByTerritory, setStoresByTerritory] = useState({});

   const [queueNotifications, setQueueNotifications] = useState([]); // Уведомление

   useEffect(() => {
      getTerritories();
   }, []);

   // const toggleStores = async (territoryId) => {
   //    if (openTerritories.includes(territoryId)) {
   //       // Закрываем территорию, убираем ее из массива открытых
   //       setOpenTerritories((prevOpenTerritories) =>
   //         prevOpenTerritories.filter(id => id !== territoryId)
   //       );
   //    } else {
   //       // Если точек для этой территории еще нет, делаем запрос
   //       if (!storesByTerritory[territoryId]) {
   //          try {
   //             const stores = await getStoresByTerritory(territoryId);
   //             setStoresByTerritory((prevStores) => ({
   //                ...prevStores,
   //                [territoryId]: stores,
   //             }));
   //          } catch (error) {
   //             console.error(`Ошибка получения точек для территории ${territoryId}:`, error);
   //          }
   //       }
   //
   //       // Открываем территорию, добавляем ее в массив открытых
   //       setOpenTerritories((prevOpenTerritories) => [...prevOpenTerritories, territoryId]);
   //    }
   // };

   const getTerritories = async () => {
      try {
         const res = await api.getTerritoriesByRegion()
         setTerritoriesRows(res);
         setLoading(false)
      } catch (e) {
         setQueueNotifications(queueNotifications.concat([e.message]));
         setLoading(false)
      }
   }

   const getStoresByTerritory = async (id) => {
      try {
         return await api.getStoresByTerritory(id)
      } catch (e) {
         setQueueNotifications(queueNotifications.concat([e.message]));
      }
   }

   const addStore = async (id, name) => {
      try {
         const data = {
            territory_id: id,
            name: name
         }
         await api.addStoreForTerritory(data)

         const stores = await getStoresByTerritory(id);
         setOpenRows((prevStores) => ({
            ...prevStores,
            [selectedTerritory.id]: { isOpen: true, stores },
         }));

         setSelectedStore(null)
         setSelectedTerritory(null)
      } catch (e) {
         setQueueNotifications(queueNotifications.concat([e.message]));
      }
   }

   const editStore = async (id, name) => {
      try {
         const data = {
            id: id,
            name: name
         }
         await api.editStore(data)

         const stores = await getStoresByTerritory(selectedTerritory.id);
         setOpenRows((prevStores) => ({
            ...prevStores,
            [selectedTerritory.id]: { isOpen: true, stores },
         }));

      } catch (e) {
         setQueueNotifications(queueNotifications.concat([e.message]));
      }
   }

   const deleteStore = async (id, territory_id) => {
      try {
         if (!window.confirm("Вы точно хотите удалить данную точку ?")) return;
         await api.deleteStoreForTerritory(id)

         const stores = await getStoresByTerritory(territory_id);
         setOpenRows({});
      } catch (e) {
         setQueueNotifications(queueNotifications.concat([e.message]));
      }
   }

   const addTerritory = async (name) => {
      try {
         const data = {
            name: name
         }
         await api.addTerritory(data)
         await getTerritories();
         setSelectedStore(null)
         setSelectedTerritory(null)
         setOpenRows({})
      } catch (e) {
         setQueueNotifications(queueNotifications.concat([e.message]));
      }
   }

   const editTerritory = async (id, name) => {
      try {
         const data = {
            id: id,
            name: name
         }
         await api.editTerritory(data)
         await getTerritories();
         setSelectedTerritory(null)
      } catch (e) {
         setQueueNotifications(queueNotifications.concat([e.message]));
      }
   }

   const deleteTerritory = async (id) => {
      try {
         if (!window.confirm("Вы точно хотите удалить данную территорию ?")) return;
         await api.deleteTerritory(id)
         await getTerritories();
         setOpenRows({});
      } catch (e) {
         setQueueNotifications(queueNotifications.concat([e.message]));
      }
   }


   const toggleRow = async (territoryId) => {
      setOpenRows((prevOpenRows) => ({
         ...prevOpenRows,
         [territoryId]: !prevOpenRows[territoryId],
      }));

      // Если еще не загрузили магазины для этой территории
      if (!openRows[territoryId]) {
         try {
            const stores = await getStoresByTerritory(territoryId);
            setOpenRows((prevOpenRows) => ({
               ...prevOpenRows,
               [territoryId]: { isOpen: true, stores },
            }));
         } catch (e) {
            setQueueNotifications(queueNotifications.concat([e.message]));
         }
      }
   };


   return (
     <>
        <TerritoryAddModal
          show={showAddTerritory}
          close={() => setShowAddTerritory(false)}
          addTerritory={addTerritory}
        />
        <TerritoryEditModal
          show={showEditTerritory}
          close={() => setShowEditTerritory(false)}
          editTerritory={editTerritory}
          selectedTerritory={selectedTerritory}
        />

        <StoreAddModal
          show={showAddStore}
          close={() => setShowAddStore(false)}
          addStore={addStore}
          selectedTerritory={selectedTerritory}
        />
        <StoreEditModal
          show={showEditStore}
          close={() => setShowEditStore(false)}
          editStore={editStore}
          selectedStore={selectedStore}
        />

        <Notifications queueNotifications={queueNotifications}/>

        <main className="mt-5 mx-lg-3 mt-xl-0">
           <MDBContainer fluid>
              <MDBCard className="mb-2">
                 <MDBCardBody className="p-0">
                    <MaterialTable
                      title="Территории"
                      data={territoriesRows}
                      options={options}
                      isLoading={loading}
                      localization={{
                         body: {
                            emptyDataSourceMessage: 'Нет данных',
                         },
                         header: {
                            actions: ''
                         }
                      }}
                      columns={[
                         { title: '', field: 'number' }
                      ]}
                      detailPanel={(rowData) => (
                           <Table>
                              <TableHead>
                                 <TableRow>
                                    {/*<TableCell style={{ width:50, textAlign: 'start' }}></TableCell>*/}
                                    {/*<TableCell ></TableCell>*/}
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {openRows[rowData.rowData.territory_id]?.stores?.map((store, index) => (
                                   <TableRow key={index}>
                                      <TableCell style={{ width:50, textAlign: 'start' }}>
                                         <TableRow className={'d-flex'}>
                                            <button className={styles['btn']} onClick={() => {
                                               setSelectedTerritory({
                                                  id: rowData.rowData.territory_id,
                                                  name: rowData.rowData.number
                                               });
                                               setSelectedStore({ id: store.store_id, name: store.number });
                                               setShowEditStore(true);
                                            }}>
                                               <MDBIcon fas icon="pen"/>
                                            </button>
                                            <button className={styles['btn']}
                                                    onClick={() => deleteStore(store.store_id, rowData.rowData.territory_id)}>
                                               <MDBIcon fas icon="trash"/>
                                            </button>
                                         </TableRow>
                                      </TableCell>
                                      <TableCell style={{ textAlign: 'start' }}>{store.number || 'Без названия'}</TableCell>
                                   </TableRow>
                                 ))}
                              </TableBody>
                           </Table>
                         )
                      }
                      onRowClick={async (event, rowData, togglePanel) => {
                         await toggleRow(rowData.territory_id)
                         togglePanel()
                      }}
                      actions={[
                         {
                            icon: 'add',
                            iconProps: {
                               color: "primary"
                            },
                            tooltip: 'Добавить новую территорию',
                            isFreeAction: true,
                            onClick: (event) => setShowAddTerritory(true)
                         },
                         {
                            icon: (rowData) => <MDBIcon fas icon="trash"/>,
                            tooltip: 'Удалить территорию',
                            onClick: (event, rowData) => deleteTerritory(rowData.territory_id),
                         },
                         {
                            icon: (rowData) => <MDBIcon fas icon="pen"/>,
                            tooltip: 'Редактировать территории',
                            onClick: (event, rowData) => {
                               setSelectedTerritory({ id: rowData.territory_id, name: rowData.number });
                               setShowEditTerritory(true);
                            },
                         },
                         {
                            icon: (rowData) => <MDBIcon fas icon="plus" />,
                            tooltip: 'Добавить новую точку',
                            onClick: (event, rowData) => {
                               setSelectedTerritory({ id: rowData.territory_id, name: rowData.number });
                               setShowAddStore(true)
                            },
                         }
                      ]}
                    />

                 </MDBCardBody>
              </MDBCard>
           </MDBContainer>
        </main>
     </>
   );
};

export default Store;