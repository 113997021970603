import React, {useEffect, useState} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBContainer, MDBRow, MDBCol,
} from "mdbreact";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DishesAndIngredients from '../../components/DishesAndIngredients'
import ApiService from "../../services/ApiService";
import Notifications from '../../components/Modals/Notifications';
import AddEditDishesCategoriesModal from '../../components/Modals/Menu/AddEditDishesCategoriesModal';
import ChangeDishIsNewStatus from '../../components/Modals/Menu/ChangeDishIsNewStatus'
import MaterialTable, {MTableBodyRow} from "material-table";
import "./index.css"

let localization = {
    pagination: {
        labelDisplayedRows: '{from}-{to} of {count}'
    },
    grouping: {
        placeholder: "Перетащите заголовок сюда для группировки",
        groupedBy: "Групировать по:"
    },
    toolbar: {
        nRowsSelected: '{0} row(s) selected',
        searchTooltip: "Поиск",
        searchPlaceholder: "Поиск",
        exportTitle: "Экспорт",
        exportAriaLabel: "Экспорт",
        exportName: "Экспорт в CSV"
    },
    header: {
        actions: ''
    },
    body: {
        deleteTooltip: "Удалить",
        editRow: {
            deleteText: "Подтвердите удаление!"
        },
        emptyDataSourceMessage: "Загрузка данных"
    }
};
let options = {
    paging: false,
    draggable: false,
    padding: "dense",
    sorting: false,
    toolbarButtonAlignment: "left",
    actionsCellStyle: {
        padding: "0px 15px"
    }
};

export default function IndexPage() {
    let [showAddModal, setShowAddModal] = useState(false);
    let [selectedCategory, setSelectedCategory] = useState();
    let [isLoading, setIsLoading] = useState(true);
    let [queueNotifications, setQueueNotifications] = useState([]);
    let [rerender, setRerender] = useState(false)

    //Удаление категорий-------------------------------------------------------
    async function removedCategory(category) {
        let api = new ApiService;
        try {
            let data = await api.removeDishCategory( {
                dish_category_id: category.dish_category_id
            })
            let removeId = data.data.removeDishCategory;
            if (removeId >= 0) {
                setRows(rows.filter( value => value.dish_category_id !== removeId))
                setQueueNotifications(queueNotifications.concat(["Категория успешно удалена"]))
                setSelectedCategory(rows[0]);
            } else {
                alert("Произошла ошибка");
            }
        } catch (e) {
            window.alert("Произошла ошибка");
            console.error(e)
        }
    }
    //-------------------------------------------------------------------------

    //Изменение категории
    let [showEditModal, setShowEditModal] = useState(false);
    let [selectedCategoryForEdit, setSelectedCategoryForEdit] = useState();

    function editedCategory(data) {
        setShowEditModal(false);
        let new_rows = rows.concat([]);
        let old_item_index = new_rows.findIndex( (item, index, array) => {
            if (item.dish_category_id === data.data.editDishCategory.dish_category_id) {
                return true
            }
        });
        new_rows[old_item_index] = data.data.editDishCategory;
        setRows(new_rows);
        setQueueNotifications(queueNotifications.concat(["Данные категории успешно изменены"]))
    }
    //-------------------------------------------------------------------------

    //Добавление категории-----------------------------------------------------
    function addCategory(data) {
        setShowAddModal(false);
        setRows([data.data.addDishCategory, ...rows]);
        setSelectedCategory(data.data.addDishCategory);
        setQueueNotifications(queueNotifications.concat(["Категория успешно добавлена"]))
    }
    //-------------------------------------------------------------------------
    //Замена ингредиента
    let onChangeIngredient = (category) => {
        setSelectedCategory(rows[rows.length - 1]);
        setSelectedCategory(category);
        setQueueNotifications(queueNotifications.concat(["Ингредиент успешно заменен"]))
    };
    //-------------------------------------------------------------------------
    //Удаление ингредиента
    let onDeleteIngredient = (ingredient_id) => {

    }

    //Настройка блюда
    let [showDishSettingsModal, setShowDishSettingsModal] = useState(false);
    let [selectedDishForSetting, setSelectedDishForSetting] = useState({})
    let onClickDishSettings = (dish) => {
        setSelectedDishForSetting(dish);
        setShowDishSettingsModal(true)
    };
    let onEditDishSetting = async (dish_id, mode, ingredients, newIngredient) => {
        let api = new ApiService();
        await api.changeDishStatus({dish_id, mode}).catch( (e) => {
            console.log(e)
            alert("Произошла ошибка")
        });
        await api.editDishIngredients({dish_id, ingredients, newIngredient});

        setShowDishSettingsModal(false)
        setRerender(!rerender)
        setQueueNotifications(queueNotifications.concat(["Статус блюда успешно изменен"]))
    }
    //-------------------------------------------------------------------------

    //Данные для таблицы
    let [rows, setRows] = useState([]);
    let categoryColumns = [
        {
            title: 'Иконка',
            render: elem => <img src={elem.icon_link} className="img-fluid"/>,
        },
        {
            title: 'Название',
            field: 'dish_category_name',
        },
        {
            title: 'Кол-во блюд',
            render: elem => elem.dishes.length,
        },
        {
            title: 'Участие в батле',
            render: elem => elem.is_banned == 1 ? "❌" : "✅",
        },
    ];
    let actions = [
        rowData => ({
            icon: 'edite',
            tooltip: 'Изменить',
            onClick: (event, rowData) => {
                setShowEditModal(true);
                setSelectedCategoryForEdit(rowData)
            }
        }),
        {
            icon: 'add',
            iconProps: {
                color: "primary"
            },
            tooltip: 'Добавить',
            isFreeAction: true,
            onClick: (event) => setShowAddModal(true)
        }
    ];
    let editable= {
        onRowDelete: removedCategory
    };
    useEffect(() => {
        let api = new ApiService();
        let getdata = async () => {
            let data = await api.getDishesCategories();
            setRows(data.data.DishesCategories);
            setSelectedCategory(data.data.DishesCategories[0]);
            setIsLoading(false);
        };
        getdata()
    }, []);
    //-------------------------------------------------------------------------

    //Удаление блюда-------------------------------------------------------
    async function removeDish({dish_id}) {
        let api = new ApiService;
        api.removeDish( {
            dish_id
        }).then( (data) => {
            let removeId = data.data.removeDish;
            if (removeId >= 0) {
                setRerender(!rerender)
                setQueueNotifications(queueNotifications.concat(["Блюдо успешно удалено"]))
            } else {
                alert("Произошла ошибка");
            }
        })
    }
    //-------------------------------------------------------------------------

    let [index, setIndex] = useState(0);
    return (
        <>
            <Notifications queueNotifications={queueNotifications} />
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <AddEditDishesCategoriesModal show={showAddModal} toggle={setShowAddModal} dishes={rows} onAdd={addCategory}/>
                <AddEditDishesCategoriesModal show={showEditModal} toggle={setShowEditModal} selectedCategory={selectedCategoryForEdit} onEdite={editedCategory}/>
                <ChangeDishIsNewStatus show={showDishSettingsModal} dish={selectedDishForSetting} selectedCategory={selectedCategory} toggle={setShowDishSettingsModal} onEditDishSetting={onEditDishSetting}/>

                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol xl="5" lg="6">
                            <MDBCard>
                                <MDBCardBody className="p-0">
                                    <MaterialTable
                                        columns={categoryColumns}
                                        isLoading={isLoading}
                                        title="Категории"
                                        editable={editable}
                                        actions={actions}
                                        localization={localization}
                                        options={options}
                                        data={rows}
                                        onRowClick={ (event, rowData) => {
                                            setSelectedCategory(rowData)
                                        }}
                                        components={{
                                            Row: props => (
                                                <>
                                                    <MTableBodyRow {...props} className={selectedCategory?.tableData.id === props.index ? "lightBac" : ""} />
                                                </>
                                            )
                                        }}
                                    />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol xl="7" lg="6">
                            <MDBCard className="mb-2">
                                <MDBCardHeader className="p-0 vert">
                                    <Tabs value={index} fullWidth onChange={ (event, index) => setIndex(index)} className="w-100">
                                        <Tab label="Блюда" className="w-50" style={{maxWidth: "none"}}/>
                                        <Tab label="Ингредиенты" className="w-50" style={{maxWidth: "none"}} />
                                    </Tabs>
                                </MDBCardHeader>
                            </MDBCard>
                            <MDBCard>
                                <MDBCardBody className="p-0">
                                    {
                                        <DishesAndIngredients index={index}
                                                              categoryOpdater={ onChangeIngredient }
                                                              selectedCategory={ selectedCategory }
                                                              removeDish={removeDish}
                                                              onClickDishSettings={onClickDishSettings}
                                                              rerender={rerender}
                                        />
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </main>
        </>
    );
}
