import React, {useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import Button from "@material-ui/core/Button";

function createMarkup(html) {
    return {__html: html?.replace(/<\/?span.*?>/ig, "").replace(/<br>/ig, "").replace(/<p>/ig, "").replace(/<\/p>/ig, "<br>")}
}

const CustomModal = ({show, close, addCustom}) => {
    const [nameCustom, setNameCustom] = useState('');


    return (
        <>
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Создать категорию</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Название</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Название функционала"
                                autoFocus
                                value={nameCustom}
                                onChange={(e) => setNameCustom(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        addCustom(nameCustom);
                        setNameCustom('')
                    }}>
                        Создать
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CustomModal;