
export function formateDateToString(date) {
    const dbDate = new Date(date);

    const day = dbDate.getDate();
    const month = dbDate.getMonth() + 1;
    const year = dbDate.getFullYear();
    const hours = dbDate.getHours();
    const minutes = dbDate.getMinutes();

    const formattedDay = (day < 10 ? "0" : "") + day;
    const formattedMonth = (month < 10 ? "0" : "") + month;
    const formattedHours = (hours < 10 ? "0" : "") + hours;
    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    const formattedDate = `${formattedDay}.${formattedMonth}.${year} ${formattedHours}:${formattedMinutes}`;

    return formattedDate;
}

export function formateDateFromUTC(timestamp, timeZone) {
    const dbDate = new Date(timestamp);

    const options = {
        timeZone: timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };

    const formattedDate = dbDate.toLocaleString('ru-RU', options);

    return formattedDate;
}