import React, {useState} from 'react';
import {
    useParams
} from "react-router-dom";
import {
    MDBContainer,
} from "mdbreact";
import UserInfo from "../../components/UserInfo";
import UserAddEditModal from "../../components/Modals/Users/UserAddEditModal";
import Notifications from "../../components/Modals/Notifications";
import ApiService from "../../services/ApiService";
import AtistationInfo from "../../components/Modals/Users/AtistationInfo";

import '../../index.css'

export default function UserInfoPage(props) {
    let { id } = useParams();
    let [showEditModal, setShowEditModal] = useState(false);
    let [selectedUser, setSelectedUser] = useState();
    const [rerender, setRerender] = useState(false);
    const [showAttInfoModal, setShowAttInfoModal] = useState(false);
    const [selectedAttId, setSelectedAttId] = useState();

    let editedUser = () => {
        setRerender(!rerender);
        setShowEditModal(false);
        setQueueNotifications(queueNotifications.concat(["Данные пользователя успешно изменены"]));
    }
    //Произошло удаление пользователя
    let removeUser = (user) => {
        let api = new ApiService;
        api.removeUser( {
                user_id: user.user_id,
            }
        ).then( (data) => {
            let removeUserId = data.data.removeUser;
            if (removeUserId >= 0) {
                setRerender(!rerender);
                setQueueNotifications(queueNotifications.concat(["Пользователь успешно удален"]));
            } else {
                window.alert("Произошла ошибка");
            }
        })
    };

    let showQuestionsInfoModal = (id) => {
        setSelectedAttId(id);
        setShowAttInfoModal(true)
    }

    let [queueNotifications, setQueueNotifications] = useState([]);
    return (
        <>
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <Notifications queueNotifications={queueNotifications}/>
                <MDBContainer fluid>
                    <UserInfo userId={id}
                              setSelectedUser={setSelectedUser}
                              rerender={rerender}
                              setShowEditModal={setShowEditModal}
                              removeUser={removeUser}
                              showQuestionsInfoModal={showQuestionsInfoModal}
                    />
                </MDBContainer>
                <AtistationInfo modalIsOpen={showAttInfoModal} setModalIsOpen={setShowAttInfoModal} certification={selectedAttId}/>
                <UserAddEditModal show={showEditModal} toggle={setShowEditModal} user={selectedUser} editedUser={editedUser}/>
            </main>
        </>
    );
}
