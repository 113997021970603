import React from 'react';
import { MDBFooter } from "mdbreact";
import './Footer.css';


function Footer() {
    return (
        <MDBFooter color="blue" className="font-small" id="footer">
            <div className="footer-copyright text-center py-3">
                    &copy; {new Date().getFullYear()} SW-Bot by MSK:<a href="/main"> Cистема обучения </a>
            </div>
        </MDBFooter>
    );
  }

  export default Footer;
