import React, { useEffect, useState } from 'react';
import {
   MDBBtn,
   MDBModal,
   MDBModalBody,
   MDBModalHeader,
   MDBModalFooter,
   MDBInput,
   MDBCol,
   MDBIcon,
} from 'mdbreact';
import './AddVideoModal.css';
import { Dropdown } from "primereact/dropdown";
import ApiService from "../../../services/ApiService";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import InfoPopup from "../../../components/InfoPopup";
import QuestionItem from "../components/QuestionItem/QuestionItem";


export default function AddVideoModal(props) {
   let [video_training_name, setVideo_training_name] = useState("");
   let [video, setVideo] = useState("");
   let [video_training_category_id, setVideo_training_category_id] = useState();
   let [preface, setPreface] = useState("Внимательно посмотри видео и ответь на вопросы теста.");
   let [success, setSuccess] = useState("Поздравляю! Ты ответил верно на все вопросы!🎉\nПомни, все необходимые инструкции ты всегда сможешь найти в Библиотеке СушиWok по ссылке: https://drive.google.com/open?id=0B5Jj-aFdDOH-amVEUTViOW5VeU0");
   let [failure, setFailure] = useState("Ты ошибся в каком-то вопросе. Не расстраивайся, просто внимательно посмотри видео еще раз и попробуй ответить снова!\nДля этого нажми на кнопку Видео «». У тебя всё получится 👌");
   let [questions, setQuestions] = useState([]);
   const [timer, setTimer] = useState(null);

   const addQuestion = () => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy.push({
         question: "",
         answers: [""],
         right_answers: 0,
      });
      setQuestions(QuestionsCopy);
   };

   const removeQuestion = (id) => {
      let QuestionsCopy = questions.filter(function (elem, index) {
         return index !== id;
      });
      setQuestions(QuestionsCopy)
   };
   const OnChangeQuestion = (id, val) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[id].question = val;
      setQuestions(QuestionsCopy)
   };

   const addAnswer = (qwId) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[qwId].answers.push("");
      setQuestions(QuestionsCopy)
   };
   const removeAnswer = (qwId, ansId) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[qwId].answers = QuestionsCopy[qwId].answers.filter(function (elem, index) {
         return index !== ansId;
      });
      setQuestions(QuestionsCopy)
   };
   const OnChangeAnswer = (qwId, ansId, val) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[qwId].answers[ansId] = val;
      setQuestions(QuestionsCopy)
   };
   const OnChangeRightAnswer = (qwId, answerId) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[qwId].right_answers = answerId;
      setQuestions(QuestionsCopy)
   };

   const AddVideo = async () => {
      try {
         let api = new ApiService()
         let { data: { addVideo: { video_training_id } } } = await api.addVideo({
            video_training_name,
            video,
            preface,
            success,
            failure,
            video_training_category_id,
            timer: dayjs(timer, 'mm:ss').format('00:mm:ss'),
         })
         questions.map((value, index) => {
            questions[index].answers = value.answers.map((value) => value.replace(/"/ig, "\\\""))
         })
         let qw = await api.addVideoQuestions(video_training_id, questions)
         props.rerender();

         setVideo_training_name("")
         setVideo("")
         setQuestions([])
         setVideo_training_category_id()
         setTimer(null)
         // alert("Добавлено");
         // document.location.reload()
      } catch (e) {
         console.log(e)
      }
   };

   let [CategoryRows, setCategoryRows] = useState([])
   useEffect(() => {
      let api = new ApiService();
      let getdata = async () => {
         let data = await api.getFullVideoCategory();
         setCategoryRows(data.data.FullVideoTrainingCategories);
      };
      getdata()
   }, []);

   let validateQuestions = (questions) => {
      if (questions.length === 0) return false
      for (let i of questions) {
         if (i.question === "") return false
         if (i.right_answers > i.answers.length - 1) return false
         if (i.answers.length === 0) return false
         for (let j of i.answers) {
            if (j === "") return false
         }
      }
      return true
   }

   const onChangeTime = (time) => {
      setTimer(time);
   };

   return (
     <MDBModal isOpen={props.modal} toggle={props.toggle} size="lg">
        <MDBModalHeader toggle={props.toggle}>Добавить вопрос</MDBModalHeader>
        <MDBModalBody className="pb-2">
           <form>
              <MDBInput value={video_training_name} onChange={(text) => setVideo_training_name(text.target.value)}
                        label="Название" outline icon="signature" className="mb-3"/>
              <MDBInput value={video} onChange={(text) => setVideo(text.target.value)}
                        label="Ссылка https://youtu.be/Brsrr6YdZG8" outline icon="link" className="my-3"/>
              <div className="d-flex">
                 <MDBIcon icon="grip-horizontal" className="mt-1" style={{ width: 33, fontSize: "1.5em" }}/>
                 <Dropdown value={video_training_category_id}
                           options={CategoryRows}
                           optionLabel="video_training_category_name"
                           optionValue="video_training_category_id"
                           onChange={(e) => setVideo_training_category_id(e.target.value)}
                           placeholder="Категория"
                           className="w-100"
                           filter
                           filterPlaceholder="Поиск"
                 />
              </div>
              <div className='d-flex align-items-center mt-3'>
                 <MDBIcon far icon="clock" className="mt-1" style={{ width: 33, fontSize: "1.5em" }}/>
                 <TimePicker
                   id="time"
                   placeholder="Продолжительность видео (минуты:секунды)"
                   allowClear={false}
                   className="w-100 mr-2"
                   // value={dayjs(timer, 'mm:ss')}
                   format={'mm:ss'}
                   onChange={onChangeTime}
                 />
                 <InfoPopup
                   text={"Продолжительность видео необходимо указывать, чтобы пользователь не смог пройти тест без просмотра видео. Данный параметр отвечает, через какой промежуток времени сотрудник сможет начать проходить тестирование."}/>
              </div>
              <MDBInput value={preface} onChange={(text) => setPreface(text.target.value)} type="textarea"
                        label="Фраза перед началом курса" outline icon="play" className="my-3"/>
              <MDBInput value={success} onChange={(text) => setSuccess(text.target.value)} rows="4" type="textarea"
                        label="Тест пройден" outline icon="check" className="my-3"/>
              <MDBInput value={failure} onChange={(text) => setFailure(text.target.value)} rows="4" type="textarea"
                        label="Тест не пройден" outline icon="times" className="my-3"/>
           </form>

           <MDBCol size={12} className="border-light border-bottom mb-4">
              <h4 className="font-weight-bolder mt-2">Вопросы:</h4>
           </MDBCol>

           <MDBCol size={12}>
              {
                 questions.map((elem, id) =>
                   <QuestionItem key={id}
                                 answers={elem.answers}
                                 right_answers={elem.right_answers}
                                 question={elem.question}
                                 number={id}
                                 OnClickRemove={removeQuestion}
                                 OnChangeQuestion={OnChangeQuestion}
                                 OnAddAnswer={addAnswer}
                                 OnRemoveAnswe={removeAnswer}
                                 OnChangeAnswer={OnChangeAnswer}
                                 OnChangeRightAnswer={OnChangeRightAnswer}
                   />
                 )
              }
           </MDBCol>
           <MDBCol size={12} className="border-light border-top text-right">
              <MDBBtn onClick={() => addQuestion()} size="sm" className="mt-3" outline>Добавить вопрос</MDBBtn>
           </MDBCol>
        </MDBModalBody>
        <MDBModalFooter>
           <MDBBtn color="secondary" size="sm" onClick={props.toggle}>Закрыть</MDBBtn>
           <MDBBtn color="primary" size="sm" disabled={
              video_training_name !== "" &&
              video !== "" &&
              video_training_category_id != undefined &&
              preface !== "" &&
              success !== "" &&
              failure !== "" &&
              timer !== null && validateQuestions(questions) ? false : true
           } onClick={async () => {
              await AddVideo()
           }}>Добавить</MDBBtn>
        </MDBModalFooter>
     </MDBModal>
   )
}
