import React, {useEffect, useState} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBRow,
} from "mdbreact";
import "./index.css"
import MaterialTable from "material-table";
import ApiService from "../../services/ApiService";
import StatPanel from "../../components/StatPanel";
function DropDownMenus(props) {
    return (
        props.title === 1 ?
            props.data[0]
            :
        <MDBDropdown size="sm">
            <MDBDropdownToggle caret color="" className="fontSizerForDropDown m-0 p-2 z-depth-0" disabled={props.disable}>
                {props.title}
            </MDBDropdownToggle>
            <MDBDropdownMenu color="info" className="zIndexForDropDown p-0">
                { props.data.map( (val, index) => (
                    <MDBDropdownItem disabled key={index} className={"text-dark py-0 px-2 border-bottom " + (index % 2 > 0 ? "grey lighten-3" : "")}>{ val }</MDBDropdownItem>
                )) }
            </MDBDropdownMenu>
        </MDBDropdown>
    )
}

export default () => {
    let [isLoading, setIsLoading] = useState(true)

    //Данные для таблицы
    let [rows, setRows] = useState([]);
    useEffect(() => {
        let api = new ApiService();
        let getdata = async () => {
            let data = await api.getCertification1cLink();
            setRows(data.data.Certification1cLink);
            setIsLoading(false)
        };
        getdata()
    }, []);
    let columns = [
        {
            title: 'Логин',
            grouping: false,
            field: 'user_name',
        }, {
            title: 'ФИО',
            grouping: false,
            field: 'full_name',
        }, {
            title: 'ТТ',
            field: 'store_name',
        }, {
            title: 'Должность',
            field: 'position_name',
        },
        {
            title: 'Тесты',
            grouping: false,
            render: elem => (<DropDownMenus data={elem.links}
                                            title={elem.links.length > 0 ? elem.links.length : "0"}
                                            disable={!elem.links.length} path="dish_category_name"/>),
        },
    ]
    return (
        <>
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol lg="4" className="mb-xl-0 mb-md-3 mb-2">
                            <StatPanel/>
                        </MDBCol>
                    </MDBRow>
                    <MDBCard className="mt-3">
                        <MDBCardBody className='p-0'>
                            <MaterialTable
                                columns={columns}
                                data={rows}
                                isLoading={isLoading}
                                title="Аттестации через 1С"
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}'
                                    },
                                    grouping: {
                                        placeholder: "Перетащите заголовок сюда для группировки",
                                        groupedBy: "Групировать по:"
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) selected',
                                        searchTooltip: "Поиск",
                                        searchPlaceholder: "Поиск",
                                        exportTitle: "Экспорт",
                                        exportAriaLabel: "Экспорт",
                                        exportName: "Экспорт в CSV"
                                    },
                                    header: {
                                        actions: ''
                                    },
                                    body: {
                                        deleteTooltip: "Удалить",
                                        editRow: {
                                            deleteText: "Подтвердите удаление!"
                                        },
                                        emptyDataSourceMessage: "Загрузка данных"
                                    }
                                }}
                                options={{
                                    pageSize: 20,
                                    pageSizeOptions: [20, 50, 100],
                                    exportDelimiter: ";",
                                    exportAllData: true,
                                    grouping: true,
                                    padding: "dense",
                                    toolbarButtonAlignment: "left",
                                }}
                            />
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </main>
        </>
    );
}
