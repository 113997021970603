import {
    MDBBtn,
    MDBCol,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow, MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdbreact";
import React, { useEffect, useState } from "react";
import ApiService from '../../../services/ApiService'
import { traslateErrorTg } from "../../../utils/traslateErrorTg";

function InfoTable(props) {
    return (
        <MDBTable striped bordered small className="mt-3">
            <MDBTableHead>
                <tr>
                    <th>Имя</th>
                    <th>Должность</th>
                    <th>ТТ</th>
                    <th>Статус</th>
                    <th>Точное время</th>
                    <th>Ошибка</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {props.info.length !== 0 &&
                    props.info.map((item, index) => (
                        <tr key={index}>
                            <td>{item.full_name}</td>
                            <td>{item.position_name ?? 'отсутствует'}</td>
                            <td>{item.number ?? 'отсутствует'}</td>
                            <td>{+item.is_active === 1 ? 'Активный' : 'Неактивный'}</td>
                            <td>{item.timezone_date ? item.timezone_date : 'отсутствует'}</td>
                            <td style={{ backgroundColor: item.error === null && item.date === null ? "" : item.error ? "rgba(255,141,141,0.44)" : "rgba(141,255,141,0.44)" }}>
                                {item.error === null && item.timezone_date === null ? "" : item.error ? traslateErrorTg(item.error) : 'Нет'}
                            </td>
                        </tr>
                    ))
                }
            </MDBTableBody>
        </MDBTable>
    )
}


export default function InfoModal(props) {
    let [allUsers, setAllUsers] = useState([]);

    useEffect(async () => {
        let api = new ApiService();
        if (!props.selectedPost) return;
        await api.getPostsById(props.selectedPost.now_id).then((data) => {
            setAllUsers(data);
        });
    }, [props.selectedPost]);

    return (
        <MDBModal isOpen={props.infoModalIsOpen} toggle={() => props.setInfoModalIsOpen(false)} size="lg" fullHeight
                  position="right">
            <MDBModalHeader toggle={() => props.setInfoModalIsOpen(false)}>Подробности по оповещению</MDBModalHeader>
            <MDBModalBody>
                <MDBRow className="justify-content-around">
                    <MDBCol xl={'12'} className="border-right text-center overflow-hidden">
                        <span className="font-weight-bold">Кому было/будет отправлено</span>
                        <InfoTable info={allUsers} />
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="secondary" size="sm" onClick={() => props.setInfoModalIsOpen(false)}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    )
}
