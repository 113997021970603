import React from 'react';
import {MDBContainer} from "mdbreact";

import {VideoList} from "./VideoList/VideoList";

function VideoPage() {
    return (
        <div>
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <MDBContainer fluid>
                    <VideoList/>
                </MDBContainer>
            </main>
        </div>
  );
}
export default VideoPage;
