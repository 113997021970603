import React, {useEffect, useState} from 'react';
import {MDBIcon} from "mdbreact";
import ApiService from "../services/ApiService";
import MaterialTable from "material-table";

export default (props) => {
    const [statusMsg, setStatusMsg] = useState('Загрузка данных');
    useEffect(() => {
        setTimeout(() => {
            setStatusMsg('Нет данных');
        }, 5000)
    }, [])

    let columns = [
        {
            title: 'Название',
            field: 'structured_day_name',
            sort: "disabled",
            editable: 'onUpdate'
        },
        {
            title: 'Кол-во вопросов',
            field: 'structured_questions',
            sort: "disabled",
            editable: 'never',
            render: rowData => rowData.structured_questions.length,
        },
    ]
    let editable={
        onRowDelete: async oldData => {
            let api = new ApiService();
            try {
                let res = await api.removeStructuredDay(oldData.structured_day_id);
                if (res) {
                    let data = props.internshipDays.filter( value => value.structured_day_id !== oldData.structured_day_id)
                    props.setInternshipDays(data)
                    props.addNotifications("Раздел успешно удален!")
                } else {
                    props.addNotifications("Произошла ошибка!")
                }
            } catch (e) {
                props.addNotifications("Произошла ошибка!")
            }
        },
        onRowUpdate: async (newData, oldData) => {
            const api = new ApiService()
            let res = await api.editStructuredDayName(newData.structured_day_id, newData.structured_day_name);
            if (res) {
                const dataUpdate = [...props.internshipDays];
                let index = props.internshipDays.findIndex(value => { return value.structured_day_id === newData.structured_day_id});
                dataUpdate[index].structured_day_name = newData.structured_day_name;
                props.setInternshipDays(dataUpdate);
                props.addNotifications("Имя раздела успешно изменено!")
            } else {
                props.addNotifications("Произошла ошибка!")
            }
        }
    }

    let actions = [
        {
            icon: 'add',
            iconProps: {
                color: "primary"
            },
            tooltip: 'Добавить',
            isFreeAction: true,
            onClick: (event) => {
                props.setShowAddDayModal(true)
            }
        }
    ]

    return (
        <MaterialTable
            columns={columns}
            data={props.internshipDays}
            isLoading={props.isDaysLoading}
            title={<div onClick={ () => {
                props.backButton(1);
            }} style={{cursor: "pointer", fontSize: "1.25rem"}} className=""><MDBIcon icon="angle-left" className="mr-2 text-"/> {props.course?.structured_course_name}</div>}
            editable={editable}
            actions={actions}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} of {count}'
                },
                grouping: {
                    placeholder: "Перетащите заголовок сюда для группировки",
                    groupedBy: "Групировать по:"
                },
                toolbar: {
                    nRowsSelected: '{0} row(s) selected',
                    searchTooltip: "Поиск",
                    searchPlaceholder: "Поиск",
                    exportTitle: "Экспорт",
                    exportAriaLabel: "Экспорт",
                    exportName: "Экспорт в CSV"
                },
                header: {
                    actions: ''
                },
                body: {
                    deleteTooltip: "Удалить",
                    editRow: {
                        deleteText: "Подтвердите удаление!"
                    },
                    emptyDataSourceMessage: statusMsg
                }
            }}
            options={{
                paging: false,
                padding: "dense",
                toolbarButtonAlignment: "left",
                actionsCellStyle: {
                    padding: "0px 15px"
                }
                // padding: false,
            }}
            onRowClick={(event, rowData) => {
                if (event.target.childNodes.length !== 2) props.onRowClick(rowData);
            }}
        >
        </MaterialTable>
    );
}
