import React from 'react';
import {
   MDBCard,
   MDBCardBody, MDBCardFooter,
   MDBCardText,
   MDBCardTitle,
   MDBCol,
   MDBContainer, MDBInput,
   MDBRow
} from "mdbreact";
import ApiService from "../../services/ApiService";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
   IS_ATTESTATION,
   IS_DELETE_INACTIVE,
   IS_MAILING_SALES,
   IS_MENU,
   IS_SHOP,
   IS_STEADY_CONTROL
} from "../../utils/globalVars";

const AntSwitch = withStyles((theme) => ({
   root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
   },
   switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
         transform: 'translateX(12px)',
         color: theme.palette.common.white,
         '& + $track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
         },
      },
   },
   thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
   },
   track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
   },
   checked: {},
}))(Switch);

const api = new ApiService();
const RegionSetting = ({ updateRoutes }) => {
   const [isMenu, setIsMenu] = React.useState(); // Вкл/Выкл меню
   const [isShop, setIsShop] = React.useState(); // Вкл/Выкл магазин
   const [isMailingSales, setIsMailingSales] = React.useState(); // Вкл/Выкл рассылку плана продаж
   const [isSC, setIsSC] = React.useState(); // Вкл/Выкл SteadyControl
   const [isAttestationMailing, setAttestationMailing] = React.useState(); // Вкл/Выкл рассылку для должностей по прохождении аттестации
   const [isDeleteInactiveUsers, setIsDeleteInactiveUsers] = React.useState(); // Вкл/Выкл рассылку для должностей по прохождении аттестации
   const [daysInactive, setDaysInactive] = React.useState(30); // Вкл/Выкл рассылку для должностей по прохождении аттестации
   const [listCourses, setListCourses] = React.useState([]); // Массив курсов
   const [listPositions, setListPosition] = React.useState([]); // Массив должностей
   const [courseId, setCourseId] = React.useState(); // Выбранный курс
   const [attestationPositionId, setAttestationPositionId] = React.useState([]); // Выбранные должности
   const [defaultCourseValue, setDefaultCourseValue] = React.useState('Не назначено'); //

   // Получаем начальные настройки региона
   const getRegionSettings = async () => {
      await api.getRegionSettings()
        .then(({ regionSettings }) => {
           if (!regionSettings) return;
           setIsMenu(+regionSettings.is_active_menu);
           setIsShop(+regionSettings.is_active_shop);
           setIsMailingSales(+regionSettings.is_active_mailingSales);
           setIsSC(+regionSettings.is_active_sc);
           setAttestationMailing(regionSettings.is_mailing_admin);
           setIsDeleteInactiveUsers(+regionSettings.is_delete_inactive);
           setDaysInactive(regionSettings.days_inactive);
           setAttestationPositionId(regionSettings.at_position_id ? regionSettings.at_position_id.split(',') : []);
           setCourseId(regionSettings.sc_course_id);
           regionSettings.sc_course_id && api.getStructuredCourseById(regionSettings.sc_course_id).then(data => {
              setDefaultCourseValue(data.data.StructuredCourses.length > 1 ? "Не назначено" : data.data.StructuredCourses[0].structured_course_name);
           });
        });
   };

   // При первом запуске
   // получаем курсы и должности
   // получаем начальные настройки
   React.useEffect(() => {
      api.getStructuredCourses().then(data => setListCourses(data.data.StructuredCourses));
      api.getSmallPositions().then(data => setListPosition(data.data.Positions))
      const fetchData = async () => {
         await getRegionSettings();
      };

      fetchData();
   }, []);

   // Обновляем настройки
   const updateRegionSetting = async (type, is_enable, typeData, value) => {
      if (Array.isArray(value)) {
         if (value.length > 0) {
            value = value.join(',');
         } else {
            value = null;
         }
      }

      let dataSend = {
         type,
         data: {
            is_enable,
            [typeData]: value,
         }
      };

      await api.editRegionSettings(dataSend)
        .then(async () => {
           await getRegionSettings();
        });
   };

   return (
     <main className="mt-5 mx-lg-3 mt-xl-0">
        <MDBContainer fluid>
           <MDBRow>
              <MDBCol sm='3'>
                 <MDBCard className='mb-1'>
                    <MDBCardBody>
                       <MDBCardTitle>Магазин {isShop ? '🟢' : '🔴'}</MDBCardTitle>
                       <MDBCardText>
                          Включаем/Выключаем магазин пользователям в боте
                       </MDBCardText>
                       <Typography component="div">
                          <Grid component="label" container alignItems="center" spacing={1}>
                             <Grid item>Выключить</Grid>
                             <Grid item>
                                <AntSwitch checked={Boolean(isShop)}
                                           onChange={() => updateRegionSetting(IS_SHOP, !Boolean(isShop))}
                                           name="checkedShop"/>
                             </Grid>
                             <Grid item>Включить</Grid>
                          </Grid>
                       </Typography>
                    </MDBCardBody>
                 </MDBCard>

                 <MDBCard className='mb-1'>
                    <MDBCardBody>
                       <MDBCardTitle>Меню {isMenu ? '🟢' : '🔴'}</MDBCardTitle>
                       <MDBCardText>
                          Включаем/Выключаем меню пользователям в боте
                       </MDBCardText>
                       <Typography component="div">
                          <Grid component="label" container alignItems="center" spacing={1}>
                             <Grid item>Выключить</Grid>
                             <Grid item>
                                <AntSwitch checked={Boolean(isMenu)}
                                           onChange={() => updateRegionSetting(IS_MENU, !Boolean(isMenu))}
                                           name="checkedMenu"/>
                             </Grid>
                             <Grid item>Включить</Grid>
                          </Grid>
                       </Typography>
                    </MDBCardBody>
                 </MDBCard>

                 <MDBCard className='mb-1'>
                    <MDBCardBody>
                       <MDBCardTitle>Рассылка выполнения плана продаж {isMailingSales ? '🟢' : '🔴'}</MDBCardTitle>
                       <MDBCardText>
                          Включаем/Выключаем рассылку плана продаж
                       </MDBCardText>
                       <Typography component="div">
                          <Grid component="label" container alignItems="center" spacing={1}>
                             <Grid item>Выключить</Grid>
                             <Grid item>
                                <AntSwitch checked={Boolean(isMailingSales)}
                                           onChange={() => updateRegionSetting(IS_MAILING_SALES, !Boolean(isMailingSales))}
                                           name="checkedMailingSales"/>
                             </Grid>
                             <Grid item>Включить</Grid>
                          </Grid>
                       </Typography>
                    </MDBCardBody>
                 </MDBCard>

                 <MDBCard className='mb-1'>
                    <MDBCardBody>
                       <MDBCardTitle>Steady Control {isSC ? '🟢' : '🔴'}</MDBCardTitle>
                       <MDBCardText>
                          Включаем/Выключаем Steady Control
                       </MDBCardText>
                       <Typography component="div">
                          <Grid component="label" container alignItems="center" spacing={1}>
                             <Grid item>Выключить</Grid>
                             <Grid item>
                                <AntSwitch checked={Boolean(isSC)}
                                           onChange={() => updateRegionSetting(IS_STEADY_CONTROL, !Boolean(isSC), 'course', courseId)}
                                           name="checkedSC"/>
                             </Grid>
                             <Grid item>Включить</Grid>
                          </Grid>
                       </Typography>
                    </MDBCardBody>
                    <MDBCardFooter>
                       <form className={'form-success flex align-items-start flex-column justify-content-start'}>
                          <Dropdown optionLabel="structured_course_name" optionValue="structured_course_id"
                                    options={listCourses}
                                    value={courseId}
                                    onChange={(e) => setCourseId(e.value)}
                                    placeholder={defaultCourseValue}
                                    filter={true}
                                    className="col-12 mb-2"
                                    filterPlaceholder="Поиск"
                          />
                       </form>
                    </MDBCardFooter>
                 </MDBCard>
              </MDBCol>

              <MDBCol sm='3'>
                 <MDBCard className='mb-1'>
                    <MDBCardBody>
                       <MDBCardTitle>Рассылка об аттестации {isAttestationMailing ? '🟢' : '🔴'}</MDBCardTitle>
                       <MDBCardText>
                          Включаем/Выключаем рассылку
                       </MDBCardText>
                       <Typography component="div">
                          <Grid component="label" container alignItems="center" spacing={1}>
                             <Grid item>Выключить</Grid>
                             <Grid item>
                                <AntSwitch checked={Boolean(isAttestationMailing)}
                                           onChange={() => updateRegionSetting(IS_ATTESTATION, !Boolean(isAttestationMailing), 'position', attestationPositionId)}
                                           name="checkedC"/>
                             </Grid>
                             <Grid item>Включить</Grid>
                          </Grid>
                       </Typography>
                    </MDBCardBody>
                    <MDBCardFooter>
                       <form
                         className={'form-success flex align-items-start flex-column justify-content-start'}>
                          <MultiSelect optionLabel="position_name" optionValue="position_id"
                                       options={listPositions}
                                       value={attestationPositionId}
                                       onChange={(e) => setAttestationPositionId(e.value)}
                                       placeholder="Должности"
                                       filter={true}
                                       className="col-12 mb-3 mt-3"
                                       filterPlaceholder="Поиск"
                          />
                       </form>
                    </MDBCardFooter>
                 </MDBCard>

              </MDBCol>

              <MDBCol sm='3'>
                 <MDBCard className='mb-1'>
                    <MDBCardBody>
                       <MDBCardTitle>Удаление неактивных
                          пользователей {isDeleteInactiveUsers ? '🟢' : '🔴'}</MDBCardTitle>
                       <MDBCardText>
                          Включаем/Выключаем удаление
                       </MDBCardText>
                       <Typography component="div">
                          <Grid component="label" container alignItems="center" spacing={1}>
                             <Grid item>Выключить</Grid>
                             <Grid item>
                                <AntSwitch checked={Boolean(isDeleteInactiveUsers)}
                                           onChange={() => updateRegionSetting(IS_DELETE_INACTIVE, !Boolean(isDeleteInactiveUsers), 'day', daysInactive)}
                                           name="checkedC"/>
                             </Grid>
                             <Grid item>Включить</Grid>
                          </Grid>
                       </Typography>
                    </MDBCardBody>
                    <MDBCardFooter>
                       <form
                         className={'form-success flex align-items-start flex-column justify-content-start'}>
                          <MDBInput
                            className='mb-3 mt-3'
                            value={daysInactive}
                            onChange={e => setDaysInactive(+e.target.value)}
                            type='number'
                            min={0}
                          />
                       </form>
                    </MDBCardFooter>
                 </MDBCard>
              </MDBCol>

              <MDBCol sm='3'>

              </MDBCol>
           </MDBRow>
        </MDBContainer>
     </main>
   );
};

export default RegionSetting;