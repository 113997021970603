import React, {useEffect, useRef, useState} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer, MDBRow,
} from "mdbreact";
import "./index.css"
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import DoneIcon from '@material-ui/icons/Done';
import ApiService from "../../services/ApiService";
import TextField from "@material-ui/core/TextField";
import {Editor} from "primereact/editor";
import { useParams } from "react-router-dom";

export default () => {
    const [title, setTitle] = useState();
    const [text, setText] = useState("");
    let {id} = useParams();
    const [groupName, setGroupName] = useState();
    const [addCategory, setAddCategory] = useState(false);

    const ref = useRef(null);
    let api = new ApiService();

    useEffect(() => {

        async function get() {
            let {data} = await api.FAQParagraph(id);
            setText(data.FAQParagraph.faq_content_answer);
            setTitle(data.FAQParagraph.faq_content_question);
        }
        get();
    }, []);


    let showTEST = (text) => {
        setText(text);
        // console.log(text.getElementsByTagName('img'))
    }

    let editFAQ = async () => {
        let res = await api.editFAQParagraph({title, text, faq_content_id: id})
        if (res) {
            window.alert("Успешно изменено");
            document.location.reload();
        } else {
            window.alert("Ошибка при изменении");
        }
    }

    return (
        <>
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <MDBContainer fluid>
                    <MDBRow className="mb-3">
                        <MDBCol size="12">
                            <Typography variant="h5" component="h5">
                                Изменение FAQ
                            </Typography>
                        </MDBCol>
                    </MDBRow>

                    <MDBCard className="mt-2">
                        <MDBCardBody className="p-2">
                            <MDBRow>
                                <MDBCol size="12">
                                    <TextField
                                        label="Заголовок"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className="m-0"
                                        value={title}
                                        onChange={ event => setTitle(event.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="mt-2">
                        <MDBCardBody className="p-2">
                            <MDBRow>
                                <MDBCol size="12">
                                    <Editor style={{height:'320px'}} value={text} onTextChange={(e) => showTEST(e.htmlValue)} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBRow className="mt-3">
                        <MDBCol size="12">
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                startIcon={<DoneIcon />}
                                onClick={() => editFAQ()}
                            >Изменить
                            </Button>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </main>
        </>
    );
}
