import React, {useRef, useState} from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBRow,
} from "mdbreact";
import {Editor} from "primereact/editor";
import ApiService from '../services/ApiService'


export default function SendMassages(props) {
    let [loading, setLoading] = useState(false)

    let fileRef = useRef();

    let [file, setFile] = useState();
    let [text, setText] = useState("");

    let onUpload = () => {
        setFile(fileRef.current.files[0])
    };
    const header = (
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-strike" aria-label="Strike"></button>
            {/*{props.intern && <button className="ql-link" aria-label="Link"></button>}*/}

            {/*<button className="ql-code-block" aria-label="CodeBlock"></button>*/}
            {/*<button className="ql-color" aria-label="Color"></button>*/}
            {/*<button onClick={ () => setText("<b>#РЕКЛАМА</b>" + (text ? text : "" ))}>#РЕКЛАМА</button>*/}
        </span>
    );
    // Прошлая отправка смс, для стажеров с учетом загрузки фото, документов и текста
    const sendMassage = async () => {
        const api = new ApiService()
        let formData = new FormData();
        setLoading(true);
        formData.append('text', text?.replace(/<\/?span.*?>/ig, "").replace(/<br>/ig, "").replace(/<p>/ig, "").replace(/<\/p>/ig, "\n"));
        formData.append('position_id', props.selectedPosition);
        if (file) {
            formData.append('file', file, file.name);
        }
        formData.append('intern', props.intern)
        let res = await api.addWelcomeMassage(formData)

        if (res.ok) {
            setLoading(false)
            props.addedWelcomeMassage()
            props.reload()
        } else {
            alert('Возникла ошибка: ' + await res.text());
        }
    };

    const sendMassageState = async () => {
        const api = new ApiService()
        let formData = new FormData();

        setLoading(true);

        formData.append('position_id', props.selectedPosition);
        formData.append('text', text?.replace(/<\/?span.*?>/ig, "").replace(/<br>/ig, "").replace(/<p>/ig, "").replace(/<\/p>/ig, "\n"));
        formData.append('intern', props.intern);

        let res = await api.addWelcomeMassageState(formData)
        if (res.ok) {
            setLoading(false)
            props.addedWelcomeMassage()
            props.reload()
        } else {
            alert('Возникла ошибка: ' + await res.text());
        }
    };

    return (
        <MDBRow className="border-bottom">
            <MDBCol xl="12">
                <Editor style={{height: '150px', fontFamily: "'Roboto', sans-serif", fontSize: "12pt"}}
                        value={text}
                        onTextChange={(e) => setText(e.htmlValue)}
                        headerTemplate={header}
                />
            </MDBCol>
            <MDBCol xl="12" className="mt-3">
                <MDBRow className="px-3" between>
                    {/*{*/}
                    {/*    props.intern && <MDBCol size="8" className="custom-file">*/}
                    {/*        <input*/}
                    {/*            type="file"*/}
                    {/*            className="custom-file-input mx-auto"*/}
                    {/*            id="inputGroupFile01"*/}
                    {/*            aria-describedby="inputGroupFileAddon01"*/}
                    {/*            onChange={onUpload}*/}
                    {/*            ref={fileRef}*/}
                    {/*        />*/}
                    {/*        <label className="custom-file-label" htmlFor="inputGroupFile01">*/}
                    {/*            {file ? file.name : "Выберите файл"}*/}
                    {/*        </label>*/}
                    {/*    </MDBCol>*/}
                    {/*}*/}

                    <MDBCol size="4" className="p-0 mb-3 align-middle d-flex">
                        {
                            loading ?
                                (<div className="spinner-border" role="status">
                                    <span className="sr-only">Загрузка...</span>
                                </div>) :
                                (<MDBBtn color="primary" size="sm" className="m-0 mx-auto mt-1"
                                         disabled={text === "" || text == null}
                                         onClick={sendMassageState}>Добавить</MDBBtn>)
                        }
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    );
}
