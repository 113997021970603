import React, {useEffect, useRef, useState} from 'react';
import {
    MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow,
} from "mdbreact";
import Form from 'react-bootstrap/Form';
import {Dropdown} from "primereact/dropdown";
import {RadioButton} from "primereact/radiobutton";
import {Editor} from "primereact/editor";

import './global_components.css'
import { BACK_URL } from "../services/ApiService";

function AnswerItem(props) {
    return (
        <MDBRow className="no-gutters p-0" end>
            <MDBCol size={12} className="d-flex align-items-center">
                <div style={{}} className="text-right mr-1">
                    <RadioButton onChange={() => props.OnChangeRightAnswer(props.number)}
                                 checked={props.right === props.number}
                    />
                </div>
                <div style={{flexGrow: "90"}}>
                    <MDBInput value={props.answer}
                              onChange={(elem) => props.OnChangeAnswer(props.number, elem.target.value)}
                              label={"Ответ: " + (Number(props.number) + 1)}
                              outline
                        // icon="check-circle"
                              className="my-0"
                    />
                </div>
                <div style={{}}>
                    <MDBBtn onClick={() => props.OnRemoveAnswe(props.number)}
                            outline color="danger"
                            size="sm"
                            className="px-2">
                        <MDBIcon size="2x" icon="trash-alt"/>
                    </MDBBtn>
                </div>
            </MDBCol>
        </MDBRow>
    )
}

const header = (
    <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button className="ql-strike" aria-label="Strike"></button>
        <button className="ql-link" aria-label="Link"></button>
        {/*<button className="ql-code-block" aria-label="CodeBlock"></button>*/}
        {/*<button className="ql-color" aria-label="Color"></button>*/}
        {/*<button onClick={ () => setText("<b>#РЕКЛАМА</b>" + (text ? text : "" ))}>#РЕКЛАМА</button>*/}
    </span>
);

function createMarkup(html) {
    return {__html: html.replace(/\n/ig, "<br>")}
}

export default (props) => {
    useEffect(() => {
        if (props.selectForEditQuestion) {
            setQuestion(props.selectForEditQuestion.question)
            setAnswers(props.selectForEditQuestion.answers)
            setRight_answers(props.selectForEditQuestion.right_answer >= 0 ? props.selectForEditQuestion.right_answer : props.selectForEditQuestion.right_answers)
            if (props.selectForEditQuestion.file) {
                setFile(props.selectForEditQuestion.file);
            } else if (props.selectForEditQuestion.file_path || props.selectForEditQuestion.image) {
                setOldFile(props.selectForEditQuestion.image ? props.selectForEditQuestion.image : props.selectForEditQuestion.file_path)
                setFile(props.selectForEditQuestion.image ? props.selectForEditQuestion.image : props.selectForEditQuestion.file_path)
            }
        }
    }, [props.selectForEditQuestion])

    let fileRef = useRef();

    let [file, setFile] = useState();
    let [question, setQuestion] = useState("");
    let [answers, setAnswers] = useState([""]);
    let [right_answers, setRight_answers] = useState(0);
    let [day, setDay] = useState();
    const [questionNumber, setQuestionNumber] = useState();
    const [oldFile, setOldFile] = useState();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (props?.selectForEditQuestion?.is_editable === 1) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [props.selectForEditQuestion])

    let onUpload = () => {
        setFile(fileRef.current.files[0])
    };

    const addAnswer = () => {
        let AnswersCopy = answers.slice();
        AnswersCopy.push("");
        setAnswers(AnswersCopy)
    };

    const removeAnswer = (ansId) => {
        let AnswersCopy = answers.slice();
        AnswersCopy = AnswersCopy.filter(function (elem, index) {
            return index !== ansId;
        });
        setAnswers(AnswersCopy)
    };

    const OnChangeAnswer = (ansId, val) => {
        let AnswersCopy = answers.slice();
        AnswersCopy[ansId] = val;
        setAnswers(AnswersCopy)
    };

    let validateQuestions = (question) => {
        // if (question.question === "") return false
        if (question.right_answers > question.answers.length - 1) return false
        if (question.answers.length === 0) return false
        for (let j of question.answers) {
            if (j === "") return false
        }
        return true
    };

    return (
        <>
            <MDBRow center className="px-3">
                <MDBCol size="12">
                    <Editor style={{height: '150px', fontFamily: "'Roboto', sans-serif", fontSize: "12pt"}}
                            value={question} onTextChange={(e) => setQuestion(e.htmlValue)} headerTemplate={header}/>
                </MDBCol>
            </MDBRow>


            <MDBRow center className="px-4 mt-1">
                <MDBCol size="12" className="pl-5">
                    {answers.map((elem, id) =>
                        <AnswerItem answer={elem}
                                    key={id}
                                    number={id}
                                    OnRemoveAnswe={removeAnswer}
                                    OnChangeAnswer={OnChangeAnswer}
                                    OnChangeRightAnswer={setRight_answers}
                                    right={right_answers}
                        />
                    )}
                </MDBCol>
            </MDBRow>

            <MDBRow className="px-4" end>
                <MDBCol size="12">
                    <MDBBtn size="sm" className="m-0 mt-1 ml-5" outline onClick={addAnswer}>Добавить ответ</MDBBtn>
                </MDBCol>
            </MDBRow>


            <MDBRow className="px-4">
                <MDBCol size="12" style={{paddingLeft: 10}}>
                    <Form.Check
                        className='myCheckbox'
                        label="Изменяемое сообщение"
                        checked={checked}
                        onChange={e => setChecked(!checked)}
                    />
                </MDBCol>
            </MDBRow>


            <MDBRow className="mt-2 mx-0 border-bottom">
                <MDBCol size="12" className="border-light">
                    <h5>Фото:</h5>
                </MDBCol>
            </MDBRow>

            <MDBRow className="mx-0 border-bottom">
                <MDBCol size="12" className="py-2">
                    {
                        oldFile ? (
                            <div className="">
                                <img src={BACK_URL + oldFile} width="150" className="ml-5"/>
                                <MDBBtn color="warning" size="sm"
                                        className="m-0 mt-1 ml-2"
                                        outline
                                        onClick={() => {
                                            setOldFile(false);
                                            setFile(null)
                                        }}>Изменить</MDBBtn>
                            </div>
                        ) : (
                            <MDBCol className="custom-file">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupFileAddon01"
                                              onClick={() => setFile(null)} style={{cursor: "pointer"}}>
                                            Отчистить
                                        </span>
                                    </div>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile01"
                                            aria-describedby="inputGroupFileAddon01"
                                            onChange={onUpload}
                                            ref={fileRef}
                                        />
                                        <label className="custom-file-label" htmlFor="inputGroupFile01"
                                               style={{cursor: "pointer"}}>
                                            {file ? file.name : "Выберите файл или оставьте пустым"}
                                        </label>
                                    </div>
                                </div>
                            </MDBCol>
                        )
                    }
                </MDBCol>
            </MDBRow>

            <MDBRow className="mx-0 mt-2">
                <MDBCol xl="12">
                    {
                        props.selectForEditQuestion ?
                            (<MDBRow className="">
                                <MDBCol className="">
                                    <MDBBtn color="primary" size="sm"
                                            className="m-0"
                                            disabled={!validateQuestions({question, answers, right_answers})}
                                            onClick={() => props.editQuestion(file, question, answers, right_answers, {
                                                ...props.selectForEditQuestion.day_id,
                                                day_list_id: props.selectForEditQuestion.day_list_id
                                            }, props.selectForEditQuestion.index, () => {
                                                setAnswers([""]);
                                                setQuestion("");
                                                setRight_answers(0);
                                                setFile(null);
                                            }, props.selectForEditQuestion, checked)}>
                                        Сохранить
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>) :
                            props.questionsList ? (
                                    <MDBRow className="" between>
                                        <MDBCol size="6">
                                            {/*{props.addCustom ?*/}
                                            {/*    null :*/}
                                            {/*    <Dropdown value={questionNumber}*/}
                                            {/*              options={props.questionsList}*/}
                                            {/*              optionValue="element_index"*/}
                                            {/*              optionLabel="question"*/}
                                            {/*              valueTemplate={((option, props1) => option ? <span*/}
                                            {/*                  dangerouslySetInnerHTML={createMarkup(option.question)}/> : props1.placeholder)}*/}
                                            {/*              itemTemplate={option => <span*/}
                                            {/*                  dangerouslySetInnerHTML={createMarkup(option.question)}/>}*/}
                                            {/*              panelClassName="w-50"*/}
                                            {/*              onChange={(e) => setQuestionNumber(e.target.value)}*/}
                                            {/*              placeholder="Вставить после:"*/}
                                            {/*              className="w-100 mx-auto"*/}
                                            {/*              filter*/}
                                            {/*              filterPlaceholder="Поиск"*/}
                                            {/*    />}*/}
                                        </MDBCol>
                                        <MDBCol size="6" className="text-right">
                                            {props.addCustom ?
                                                <MDBBtn color="primary" size="sm"
                                                        className="m-0 mx-auto mt-1"
                                                        disabled={!validateQuestions({
                                                            question,
                                                            answers,
                                                            right_answers
                                                        })}
                                                        onClick={() => props.addQuestion(file, question, answers, right_answers, props.customCategoryId.custom_category_id, checked)}
                                                >Создать вопрос</MDBBtn>
                                                :
                                                <MDBBtn color="primary" size="sm"
                                                        className="m-0 mx-auto mt-1"
                                                        disabled={!validateQuestions({
                                                            question,
                                                            answers,
                                                            right_answers
                                                        })}
                                                        onClick={() => props.addQuestion(file, question, answers, right_answers, props.selectedDayForAddQuestion, checked)}
                                                >Создать вопрос</MDBBtn>
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                ) :
                                (
                                    <MDBRow className="" between>
                                        <MDBCol size="">
                                            <Dropdown value={day}
                                                      options={props.days}
                                                      onChange={(e) => setDay(e.target.value)}
                                                      placeholder="Блок:"
                                                      className="w-100 mx-auto"
                                            />
                                        </MDBCol>
                                        <MDBCol size="" className="p-0">
                                            <MDBBtn color="primary" size="sm"
                                                    className="m-0 mx-auto mt-1"
                                                    disabled={!validateQuestions({
                                                        question,
                                                        answers,
                                                        right_answers
                                                    }) || day == undefined}
                                                    onClick={() => props.addQuestion(day, file, question, answers, right_answers, checked, () => {
                                                        setAnswers([""]);
                                                        setQuestion("");
                                                        setRight_answers(0);
                                                        setFile(undefined);
                                                        setChecked(false);
                                                    })}>Создать вопрос</MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                )
                    }
                </MDBCol>
            </MDBRow>
        </>
    );
}
