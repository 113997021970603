import React from 'react';
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdbreact";
import { RadioButton } from "primereact/radiobutton";

const AnswerItem = (props) => {
   return (
     <MDBRow className="no-gutters align-items-center py-0">
        <MDBCol size={'11'} className="d-flex justify-content-center align-items-center">
           <div style={{flexGrow: "5"}}>
              <RadioButton onChange={() => props.OnChangeRightAnswer(props.qwId, props.number)} checked={props.right === props.number} />
           </div>
           <div style={{flexGrow: "95"}}>
              <MDBInput value={ props.answer }
                        onChange={ (elem) => props.OnChangeAnswer(props.qwId, props.number, elem.target.value) }
                        label={"Ответ: " + (Number(props.number) + 1)}
                        outline
                        className="my-0"/>
           </div>
        </MDBCol>
        <MDBCol size={1} className="text-right">
           <MDBBtn onClick={ () => props.OnRemoveAnswe(props.qwId, props.number) } outline color="danger" size="sm" className="px-2"><MDBIcon size="2x" icon="trash-alt" /></MDBBtn>
        </MDBCol>
     </MDBRow>
   );
};

export default AnswerItem;