import React, { useEffect, useState } from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol, MDBIcon, MDBPageItem, MDBPageNav, MDBPagination,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from "mdbreact";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import './global_components.css';

import ApiService from '../services/ApiService'
import { formateDateToString } from "../utils/formateDateToString";
import {
    ATTESTATION,
    ATTESTATION_TYPE, COOKIE,
    COURSE, COURSE_TYPE,
    CUSTOM, CUSTOM_TYPE, DECREASE_TYPE, INCREASE_TYPE,
    MAILING,
    MAILING_1C, MAILING_1C_TYPE, MAILING_TYPE, MENU, MENU_TYPE, MULTIPLAYER, MULTIPLAYER_TYPE, SHOP, SHOP_TYPE,
    SteadyControl_Message, SteadyControl_Message_TYPE,
    SteadyControl_Register, SteadyControl_Register_TYPE, USER, USER_TYPE,
    VIDEO, VIDEO_TYPE
} from "../utils/globalVars";
import Notifications from "./Modals/Notifications";

function createMarkup(html) {
    return { __html: html?.replace(/<\/?span.*?>/ig, "").replace(/<br>/ig, "").replace(/<p>/ig, "").replace(/<\/p>/ig, "<br>") }
}

let api = new ApiService();
export default function (props) {
    let [userInfo, setUserInfo] = useState([]);
    let [logUser, setlogUser] = useState([]);
    let [lengthLogs, setLengthLogs] = useState(0);
    let [complited, setComplited] = useState(false);
    let [nextStatus, setNextStatus] = useState(true);
    let [userPhoto, setUserPhoto] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);

    const [queueNotifications, setQueueNotifications] = useState([]);

    // Подгружаем начальную информацию
    useEffect(() => {
        async function get() {
            let { data: { Users } } = await api.getUserById(props.userId);
            if (Users[0]) {
                setUserInfo(Users);
                props.setSelectedUser(Users[0])
            }
            const photoUser = await api.getUserPhotoById(Users[0]['telegram_id']);
            setUserPhoto(photoUser?.photo);
            const messages = await api.getMessagesByUser(props.userId, currentPage);
            setlogUser(messages.history);
            setLengthLogs(messages.length);
            setComplited(true);
        }

        if (props.userId) {
            setComplited(false)
            get()
        }
    }, [props.userId, props.rerender]);

    const downloadHistoryExcel = async () => {
        const file = await api.getHistoryUserExcel(props.userId)
            .then(async data => {
                if (data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    let url = URL.createObjectURL(data);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Выгрузка взаимодействия с ботом - ${userInfo[0]['full_name']}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();

                    URL.revokeObjectURL(url);
                } else {
                    if (data?.error) {
                        setQueueNotifications(queueNotifications.concat([data?.error]));
                    } else {
                        setQueueNotifications(queueNotifications.concat(["Отчет не найден"]));
                    }
                }
            });
    }

    // При изменении будем подгружать след страницу в "Взаимодействие с ботом"
    useEffect(async () => {
        const messages = await api.getMessagesByUser(props.userId, currentPage);
        const checkNextPage = await api.getMessagesByUser(props.userId, currentPage + 1);
        if (checkNextPage.history.length > 0) {
            setNextStatus(true);
        } else {
            setNextStatus(false);
        }
        setlogUser(messages.history);
    }, [currentPage]);

    // Конвертируем Типы в Название которые будем отображать пользователю
    const convertTypeLog = (type) => {
        switch (type) {
            case COURSE_TYPE:
                return COURSE;

            case VIDEO_TYPE:
                return VIDEO;

            case CUSTOM_TYPE:
                return CUSTOM;

            case MENU_TYPE:
                return MENU;

            case SteadyControl_Message_TYPE:
                return SteadyControl_Message;

            case SteadyControl_Register_TYPE:
                return SteadyControl_Register;

            case MAILING_1C_TYPE:
                return MAILING_1C;

            case MAILING_TYPE:
                return MAILING;

            case SHOP_TYPE:
                return SHOP;

            case USER_TYPE:
                return USER;

            case ATTESTATION_TYPE:
                return ATTESTATION;

            case INCREASE_TYPE:
                return COOKIE;

            case DECREASE_TYPE:
                return COOKIE;

            case MULTIPLAYER_TYPE:
                return MULTIPLAYER;

            default:
                return type;
        }
    }

    // Скрываем/Показываем: меню, видео, функционал, курсы (тек. сезон/история)
    const [indexCourses, setIndexCourses] = useState(0);
    const [indexVideo, setIndexVideo] = useState(0);
    const [indexCustom, setIndexCustom] = useState(0);

    const [hideCourses, setHideCourses] = useState(false);
    const [hideVideo, setHideVideo] = useState(false);
    const [hideCustom, setHideCustom] = useState(false);
    const [hideMenu, setHideMenu] = useState(false);
    const [hideСertification, setHideСertification] = useState(false);
    const [hideLogUser, setHideLogUser] = useState(false);
    return (
        <>
            <Notifications queueNotifications={queueNotifications} />
            {
                complited ?
                    <MDBRow>
                        {
                            userInfo[0] ?
                                (<>
                                    <MDBCol xl="3">
                                        <MDBCard className="w-100">
                                            <MDBCardHeader className="py-1">
                                                <MDBRow>
                                                    <MDBCol size="12">
                                                        <IconButton aria-label="Изменить" color="inherit"
                                                                    onClick={() => props.setShowEditModal(true)}>
                                                            <EditIcon fontSize="small"/>
                                                        </IconButton>
                                                        <IconButton aria-label="delete" color="inherit">
                                                            <DeleteIcon fontSize="small"
                                                                        onClick={() => props.removeUser(userInfo[0])}/>
                                                        </IconButton>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol size="12">
                                                        {userInfo[0].full_name}
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCardHeader>
                                            <MDBCardBody className="p-0">
                                                <MDBTable striped className="mb-0">
                                                    <MDBTableBody>
                                                        <tr>
                                                            <td>Фото</td>
                                                            <td>{userPhoto ?
                                                                <img src={userPhoto} alt={userPhoto}/> : 'Нет'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Логин</td>
                                                            <td>{userInfo[0].user_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Телефон</td>
                                                            <td>{userInfo[0].phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Тер.</td>
                                                            <td>{userInfo[0].store?.territory?.number}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ТТ</td>
                                                            <td>{userInfo[0].store?.number}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Должность</td>
                                                            <td>{userInfo[0].position?.position_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Очки</td>
                                                            <td>{userInfo[0].score}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Последняя активность</td>
                                                            <td>{userInfo[0].last_activity}</td>
                                                        </tr>
                                                        {userInfo[0].become_staff_date &&
                                                            (<tr>
                                                                <td>Переведен в штат</td>
                                                                <td>{userInfo[0].become_staff_date}</td>
                                                            </tr>)
                                                        }
                                                        {userInfo[0].became_member_date &&
                                                            (<tr>
                                                                <td>Добавлен в бот</td>
                                                                <td>{userInfo[0].became_member_date}</td>
                                                            </tr>)
                                                        }
                                                        <tr>
                                                            <td>Текущий статус</td>
                                                            <td>{!userInfo[0].is_intern ? "В штате" : userInfo[0].telegram_id ? "Стажёр" : "Выслано смс"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Приглашён в SC</td>
                                                            <td>{userInfo[0].is_invite_to_steady_control ? '✅' : '❌'}</td>
                                                        </tr>
                                                    </MDBTableBody>
                                                </MDBTable>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol xl="9" className="mt-3 mt-xl-0">
                                        <MDBCard className="w-100 mb-3">
                                            <MDBCardHeader
                                                style={{ cursor: 'pointer' }}
                                                className="container-text"
                                                onClick={() => setHideСertification(!hideСertification)}
                                            >
                                                <div>Аттестации</div>
                                                <div>{userInfo[0].certifications.length}</div>
                                            </MDBCardHeader>
                                            {hideСertification && (
                                                <MDBCardBody className="p-0">
                                                    <MDBTable striped small>
                                                        <MDBTableHead>
                                                            <tr>
                                                                <th>АТТ</th>
                                                                <th>Дата</th>
                                                                <th>Видео</th>
                                                                <th>%</th>
                                                            </tr>
                                                        </MDBTableHead>
                                                        <MDBTableBody>
                                                            {userInfo[0].certifications?.map((item, index) => (
                                                                <tr key={index} style={{ cursor: "pointer" }}
                                                                >
                                                                    <td onClick={() => props.showQuestionsInfoModal(item.certification_session_id)}>{item.certification_name}</td>
                                                                    <td onClick={() => props.showQuestionsInfoModal(item.certification_session_id)}>{item.date}</td>
                                                                    <td onClick={() => (!item.video_link ? props.showQuestionsInfoModal(item.certification_session_id) : "")}>
                                                                        {
                                                                            item.video_link ? (
                                                                                <video src={item.video_link}
                                                                                       className="img-fluid" controls/>
                                                                            ) : ""
                                                                        }
                                                                    </td>
                                                                    <td onClick={() => props.showQuestionsInfoModal(item.certification_session_id)}
                                                                        className={Math.round(item.number_of_correct_answers / item.number_of_questions * 100) < 80 ? "text-danger" : "text-success"}>{Math.round(item.number_of_correct_answers / item.number_of_questions * 100)}%
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </MDBTableBody>
                                                    </MDBTable>
                                                </MDBCardBody>
                                            )}
                                        </MDBCard>
                                        <MDBRow className="mt-3 mt-xl-0">
                                            <MDBCol xl="12">
                                                <MDBCard className="w-100">
                                                    <MDBCardHeader
                                                        onClick={() => setHideMenu(!hideMenu)}
                                                        style={{ cursor: 'pointer' }}
                                                        className="container-text"
                                                    >
                                                        <div>Меню</div>
                                                        <div>{userInfo[0].completed_dish_categories?.completed.length}/{userInfo[0].completed_dish_categories?.completed.length + userInfo[0].completed_dish_categories?.no_completed.length}
                                                        </div>
                                                    </MDBCardHeader>
                                                    <MDBCardBody className="p-0">
                                                        <MDBTable striped small className="mb-0">
                                                            {hideMenu && (
                                                                <MDBTableBody>
                                                                    {userInfo[0].completed_dish_categories?.completed?.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="pl-3">✅</td>
                                                                            <td>{item.dish_category_name}</td>
                                                                            <td className="text-right pr-3">{item.date}</td>
                                                                        </tr>
                                                                    ))}
                                                                    {userInfo[0].completed_dish_categories?.no_completed?.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="pl-3">❌</td>
                                                                            <td>{item.dish_category_name}</td>
                                                                            <td className="text-right pr-3">{item.completed_pick_ingredient?.length + item.completed_name_dish?.length}/{item.completed_pick_ingredient?.length + item.completed_name_dish?.length + item.no_completed_pick_ingredient?.length + item.no_completed_name_dish?.length}</td>
                                                                        </tr>
                                                                    ))}
                                                                </MDBTableBody>
                                                            )
                                                            }
                                                        </MDBTable>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="mt-3">
                                            <MDBCol xl="12">
                                                <MDBCard className="w-100">
                                                    <MDBCardHeader
                                                        onClick={() => setHideCustom(!hideCustom)}
                                                        style={{ cursor: 'pointer' }}
                                                        className="container-text"
                                                    >
                                                        <div>Функционал</div>
                                                        {indexCustom === 0 ?
                                                            <div>{userInfo[0].completed_custom_categories?.completed.length}/{userInfo[0].completed_custom_categories?.completed.length + userInfo[0].completed_custom_categories?.no_completed.length}
                                                            </div> :
                                                            <div>{userInfo[0].completed_custom_categories?.completed_prev_season.length}</div>
                                                        }
                                                    </MDBCardHeader>
                                                    {
                                                        hideCustom && (
                                                            <Tabs
                                                                value={indexCustom}
                                                                onChange={(event, index) => setIndexCustom(index)}
                                                                className="w-100"
                                                            >
                                                                <Tab label="Текущий сезон" className="col"
                                                                     style={{ maxWidth: "none" }}/>
                                                                <Tab label="История" className="col"
                                                                     style={{ maxWidth: "none" }}/>
                                                            </Tabs>
                                                        )
                                                    }
                                                    <MDBCardBody className="p-0">
                                                        <MDBTable striped small className="mb-0">
                                                            {
                                                                hideCustom && (
                                                                    <MDBTableBody>
                                                                        {
                                                                            indexCustom === 0 ? (
                                                                                <>
                                                                                    {userInfo[0].completed_custom_categories?.completed?.map((item, index) => (
                                                                                        <tr key={index}>
                                                                                            <td className="pl-3">✅</td>
                                                                                            <td>{item.custom_category_name}</td>
                                                                                            <td className="text-right pr-3">{item.date}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                    {userInfo[0].completed_custom_categories?.no_completed?.map((item, index) => (
                                                                                        <tr key={index}>
                                                                                            <td className="pl-3">❌</td>
                                                                                            <td>{item.custom_category_name}</td>
                                                                                            <td className="text-right pr-3">{item.completed_questions?.length} / {item.no_completed_questions?.length + item.completed_questions?.length}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                        {
                                                                            indexCustom === 1 && userInfo[0].completed_custom_categories?.completed_prev_season?.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="pl-3">✅</td>
                                                                                    <td>{item.custom_category_name}</td>
                                                                                    <td className="text-right pr-3">{formateDateToString(+item.date)}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </MDBTableBody>
                                                                )
                                                            }
                                                        </MDBTable>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="mt-3">
                                            <MDBCol xl="12">
                                                <MDBCard className="w-100">
                                                    <MDBCardHeader onClick={() => setHideVideo(!hideVideo)}
                                                                   style={{ cursor: 'pointer' }}
                                                                   className="container-text"
                                                    >
                                                        <div>Видео</div>
                                                        {indexVideo === 0 ?
                                                            <div>{userInfo[0].completed_video_trainings?.completed.length}/{userInfo[0].completed_video_trainings?.completed.length + userInfo[0].completed_video_trainings?.no_completed.length}
                                                            </div> :
                                                            <div>{userInfo[0].completed_video_trainings?.completed_prev_season.length}</div>
                                                        }
                                                    </MDBCardHeader>
                                                    {
                                                        hideVideo && (
                                                            <Tabs
                                                                value={indexVideo}
                                                                onChange={(event, index) => setIndexVideo(index)}
                                                                className="w-100"
                                                            >
                                                                <Tab label="Текущий сезон" className="col"
                                                                     style={{ maxWidth: "none" }}/>
                                                                <Tab label="История" className="col"
                                                                     style={{ maxWidth: "none" }}/>
                                                            </Tabs>
                                                        )
                                                    }
                                                    <MDBCardBody className="p-0">
                                                        <MDBTable striped small className="mb-0">
                                                            {
                                                                hideVideo && (
                                                                    <MDBTableBody>
                                                                        {
                                                                            indexVideo === 0 ? (
                                                                                <>
                                                                                    {userInfo[0].completed_video_trainings?.completed?.map((item, index) => (
                                                                                        <tr key={index}>
                                                                                            <td className="pl-3">✅</td>
                                                                                            <td>{item.video_training_name}</td>
                                                                                            <td className="text-right pr-3">{item.date}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                    {userInfo[0].completed_video_trainings?.no_completed?.map((item, index) => (
                                                                                        <tr key={index}>
                                                                                            <td className="pl-3">❌</td>
                                                                                            <td>{item.video_training_name}</td>
                                                                                            <td className="text-right pr-3">{item.date}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                        {
                                                                            indexVideo === 1 && userInfo[0].completed_video_trainings?.completed_prev_season?.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="pl-3">✅</td>
                                                                                    <td>{item.video_training_name}</td>
                                                                                    <td className="text-right pr-3">{formateDateToString(+item.date)}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </MDBTableBody>
                                                                )
                                                            }
                                                        </MDBTable>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="mt-3">
                                            <MDBCol xl="12">
                                                <MDBCard>
                                                    <MDBCardHeader
                                                        onClick={() => setHideCourses(!hideCourses)}
                                                        style={{ cursor: 'pointer' }}
                                                        className="container-text"
                                                    >
                                                        <div>Курсы</div>
                                                        {indexCourses === 0 ?
                                                            <div>{userInfo[0].curses_info.completed_internships.length}/{userInfo[0].curses_info.completed_internships.length + userInfo[0].curses_info.active_internship.length}
                                                            </div> :
                                                            <div>{userInfo[0].curses_info.completed_internships_prev_season.length}</div>
                                                        }
                                                    </MDBCardHeader>
                                                    {
                                                        hideCourses && (
                                                            <Tabs
                                                                value={indexCourses}
                                                                onChange={(event, index) => setIndexCourses(index)}
                                                                className="w-100"
                                                            >
                                                                <Tab label="Текущий сезон" className="col"
                                                                     style={{ maxWidth: "none" }}/>
                                                                <Tab label="История" className="col"
                                                                     style={{ maxWidth: "none" }}/>
                                                            </Tabs>
                                                        )
                                                    }
                                                    <MDBCardBody className="p-0">
                                                        <MDBTable striped small className="mb-0">
                                                            {
                                                                hideCourses && (
                                                                    <MDBTableBody>
                                                                        {
                                                                            indexCourses === 0 ? (
                                                                                <>
                                                                                    {userInfo[0].curses_info.completed_internships?.map((item, index) => (
                                                                                        <tr key={index}>
                                                                                            <td className="pl-3">✅</td>
                                                                                            <td>{item.structured_course_name}</td>
                                                                                            <td className="text-right pr-3">{formateDateToString(+item.finished_date)}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                    {userInfo[0].curses_info.active_internship?.map((item, index) => (
                                                                                        <tr key={index}>
                                                                                            <td className="pl-3">❌</td>
                                                                                            <td>{item.structured_course_name}</td>
                                                                                            <td className="text-right pr-3">{item.date}</td>
                                                                                        </tr>))}
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                        {
                                                                            indexCourses === 1 && userInfo[0].curses_info.completed_internships_prev_season?.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="pl-3">✅</td>
                                                                                    <td>{item.structured_course_name}</td>
                                                                                    <td className="text-right pr-3">{formateDateToString(+item.finished_date)}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </MDBTableBody>
                                                                )
                                                            }
                                                        </MDBTable>
                                                    </MDBCardBody>
                                                </MDBCard>
                                                {/*{*/}
                                                {/*    hideCourses && (*/}
                                                {/*        <MDBCard className="mb-2">*/}
                                                {/*            <MDBCardBody className="p-0">*/}
                                                {/*                <div className={indexCourses === 0 ? "" : "done"}>*/}
                                                {/*                    <div className={indexCourses === 0 ? "" : "d-none"}>*/}
                                                {/*                        Table1*/}
                                                {/*                    </div>*/}
                                                {/*                    <div className={indexCourses === 1 ? "" : "d-none"}>*/}
                                                {/*                        <div className={indexCourses === 1 ? "" : "d-none"}>*/}
                                                {/*                            Table 2*/}
                                                {/*                        </div>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*            </MDBCardBody>*/}
                                                {/*        </MDBCard>*/}
                                                {/*    )*/}
                                                {/*}*/}

                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow className="mt-3">
                                            <MDBCol xl="12">
                                                <MDBCard className="w-100">
                                                    <MDBCardHeader
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setHideLogUser(!hideLogUser)
                                                        }}
                                                        className="container-text pointer"
                                                    >
                                                        <div>Взаимодействие с ботом</div>
                                                        <div className='d-flex'>
                                                            <div className='mr-3 pointer' onClick={(event) => {
                                                                event.stopPropagation();
                                                                downloadHistoryExcel()
                                                            }}
                                                            >
                                                                <MDBIcon size={'lg'} fas icon="file-upload"/></div>
                                                            <div>{lengthLogs}</div>
                                                        </div>
                                                    </MDBCardHeader>
                                                    <MDBCardBody className="p-0">
                                                        <MDBTable striped small className="mb-0">
                                                            {
                                                                hideLogUser && (
                                                                    <MDBTableBody>
                                                                        {
                                                                            logUser.length !== 0 ? logUser?.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="pl-3 font-weight-bold"
                                                                                        dangerouslySetInnerHTML={createMarkup(convertTypeLog(item.event))}></td>
                                                                                    <td><span
                                                                                        dangerouslySetInnerHTML={createMarkup(item.text)}/>
                                                                                    </td>
                                                                                    <td className="text-right pr-3"
                                                                                        style={{ width: 150 }}>{item.date}</td>
                                                                                </tr>
                                                                            )) : (
                                                                                <div style={{ textAlign: 'center' }}>История
                                                                                    взаимодействия отсутствует</div>
                                                                            )
                                                                        }
                                                                    </MDBTableBody>
                                                                )
                                                            }
                                                        </MDBTable>
                                                    </MDBCardBody>
                                                    {
                                                        hideLogUser && (
                                                            <MDBPagination
                                                                className="justify-content-center">
                                                                <MDBPageItem>
                                                                    <MDBPageNav aria-label="Previous">
                                                                        {currentPage > 1 &&
                                                                            <span aria-hidden="true"
                                                                                  onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</span>}
                                                                    </MDBPageNav>
                                                                </MDBPageItem>
                                                                <MDBPageItem>
                                                                    <MDBPageNav
                                                                        onClick={() => setCurrentPage(1)}>
                                                                        {currentPage}
                                                                    </MDBPageNav>
                                                                </MDBPageItem>
                                                                <MDBPageItem>
                                                                    <MDBPageNav aria-label="Previous">
                                                                        {logUser.length !== 0 && nextStatus &&
                                                                            <span aria-hidden="true"
                                                                                  onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</span>}
                                                                    </MDBPageNav>
                                                                </MDBPageItem>
                                                            </MDBPagination>
                                                        )
                                                    }
                                                </MDBCard>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </>) :
                                (
                                    <h3>Пользователь не найден</h3>
                                )
                        }
                    </MDBRow>
                    :
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Загрузка...</span>
                    </div>
            }
        </>
    )
        ;
}

