import React, {useEffect, useState} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBContainer, MDBIcon,
} from "mdbreact";
import CustomQuestionsTable from "../../components/CustomQuestionsTable";
import "./index.css"
import Notifications from "../../components/Modals/Notifications";
import CustomEditModal from "../../components/Modals/CustomEditModal";
import ApiService, { BACK_URL } from "../../services/ApiService";
import MaterialTable from "material-table";
import CustomModal from "../../components/Modals/Custom/CustomModal";
import { formateTextForTg } from "../../utils/formateTextForTg";

const api = new ApiService();
export default () => {
    let [custom_categories_list, setCustom_categories_list] = useState([])
    let [selectedCustomCategorie, setSelectedCustomCategorie] = useState();
    let [index, setIndex] = useState(0);
    const [show, setShow] = useState(false); // состояние для модального окна Создания Функционала

    // Показываем и скрываем товар
    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => {
        setShow(true)
    };

    let columns = [
        {
            title: 'Иконка',
            render: elem => <img src={elem.icon_link} className="img-fluid"/>,
            cellStyle: {
                width: "20%"
            },
        },
        {
            title: 'Название',
            field: 'custom_category_name',
        },
    ]

    const [rerender, setRerender] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState();
    const [showModal, setShowModal] = useState(false);


    let getData = async () => {
        let data = await api.getCustomCategoriesList();
        setCustom_categories_list(data.data.CustomCategories)
    };

    useEffect(() => {
        getData()
    }, [])

    let editQuestion = async (...any) => {
        let url;

        if (any[0]) {
            if (typeof any[0] === "string") {
                url = any[0]
            } else {
                let res = await api.uploadFile(any[0])
                url = await res.text();
            }
        }
        let newData = await api.editCustomQuestion({
            custom_question_id: any[7].custom_question_id,
            newDataQuestion: {
                question: formateTextForTg(any[1]),
                answers: any[2],
                right_answers: any[3],
            },
            image: url,
            is_editable: +any[8]
        });
        setQueueNotifications(queueNotifications.concat(["Вопрос обновлен!"]));
        setShowModal(false);
        setRerender(!rerender);
    }

    let selectCategory = (category) => {
        setSelectedCustomCategorie(category)
        setIndex(1)
    }


    let backButton = (index) => {
        setIndex(index)
    }

    const downloadExcelFile = async () => {
        const excel = await api.getExcelTemporaryCustomQuiestionFile()
            .then(async data => {
                let url = URL.createObjectURL(data);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'Выгрузка изменяемых вопросов.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();

                URL.revokeObjectURL(url);
            });
    };

    let [queueNotifications, setQueueNotifications] = useState([]);

    const addCustom = async (nameCustom) => {
        await api.addCustomCategory({custom_category_name: nameCustom})
            .then(() => {
                setShow(false);
                getData();
                setQueueNotifications(queueNotifications.concat(["Функционал создан!"]));
            })
            .catch(e => {
                setQueueNotifications(queueNotifications.concat(["Не получилось создать " + e]));
            })
    }

    let actions = [
        {
            icon: 'add',
            iconProps: {
                color: "primary"
            },
            tooltip: 'Добавить',
            isFreeAction: true,
            onClick: (event) => setShow(true)
        },
        {
            icon: () => <MDBIcon fas icon="file-upload"/>,
            iconProps: {
                color: "primary"
            },
            tooltip: 'Выгрузить изменяемые вопросы',
            isFreeAction: true,
            onClick: (event) => downloadExcelFile()
        }
    ];

    const editable = {
        onRowDelete: async oldData => {
            try {
                let res = await api.removeCustomCategory({custom_category_id: +oldData.custom_category_id});
                if (res) {
                    const data = custom_categories_list.filter( value => value.custom_category_id !== oldData.custom_category_id);
                    setCustom_categories_list(data)
                    setQueueNotifications(queueNotifications.concat(["Функционал успешно удален!"]));
                } else {
                    setQueueNotifications(queueNotifications.concat(["Произошла ошибка"]));
                }
            } catch (e) {
                setQueueNotifications(queueNotifications.concat(["Произошла ошибка"]));
            }
        },
        onRowUpdate: async (newData, oldData) => {
            let res = await api.editCustomCategory(newData.custom_category_id, newData.custom_category_name);
            if (res) {
                const dataUpdate = [...custom_categories_list];
                const index = custom_categories_list.findIndex(value => value.custom_category_id === newData.custom_category_id);
                dataUpdate[index].custom_category_name = newData.custom_category_name;
                setCustom_categories_list(dataUpdate);
                setQueueNotifications(queueNotifications.concat(["Название категории успешно изменено!"]));
            } else {
                setQueueNotifications(queueNotifications.concat(["Произошла ошибка"]));
            }
        }
    }

    return (
        <>
            <CustomEditModal show={showModal}
                             selectedQuestion={selectedQuestion}
                             toggle={setShowModal}
                             editQuestion={editQuestion}
            />
            <CustomModal show={show}
                         close={handleClose}
                         addCustom={addCustom}
            />
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <MDBContainer fluid>
                    <MDBCard className="mt-3">
                        <MDBCardBody className='p-0'>
                            <Notifications queueNotifications={queueNotifications}/>
                            <div className={index === 0 ? "" : "d-none"}>
                                <MaterialTable
                                    columns={columns}
                                    data={custom_categories_list}
                                    // isLoading={isLoading}
                                    editable={editable}
                                    title="Функционал"
                                    actions={actions}
                                    options={{
                                        paging: false,
                                        exportDelimiter: ";",
                                        padding: "dense",
                                        toolbarButtonAlignment: "left",
                                        actionsCellStyle: {
                                            padding: "0px 15px"
                                        }
                                    }}
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} of {count}'
                                        },
                                        grouping: {
                                            placeholder: "Перетащите заголовок сюда для группировки",
                                            groupedBy: "Групировать по:"
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} row(s) selected',
                                            searchTooltip: "Поиск",
                                            searchPlaceholder: "Поиск",
                                            exportTitle: "Экспорт",
                                            exportAriaLabel: "Экспорт",
                                            exportName: "Экспорт в CSV"
                                        },
                                        header: {
                                            actions: ''
                                        },
                                        body: {
                                            deleteTooltip: "Удалить",
                                            editRow: {
                                                deleteText: "Подтвердите удаление!"
                                            },
                                            emptyDataSourceMessage: "Загрузка данных"
                                        }
                                    }}
                                    onRowClick={(event, rowData) => {
                                        // if (event.target.childNodes.length !== 2)
                                        selectCategory(rowData);
                                    }}
                                />
                            </div>
                            <div className={index === 1 ? "" : "d-none"}>
                                <CustomQuestionsTable rerender={rerender} backButton={backButton}
                                                      selectedCustomCategorie={selectedCustomCategorie}
                                                      setSelectedQuestion={setSelectedQuestion}
                                                      setShowModal={setShowModal} hide={index !== 1}/>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </main>
        </>
    );
}
