import React, { useEffect, useState } from 'react';
import {
   MDBBtn,
   MDBCol,
   MDBInput,
   MDBModal,
   MDBModalBody, MDBModalFooter,
   MDBModalHeader,
} from "mdbreact";
import ApiService from "../../../services/ApiService";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import QuestionItem from "../components/QuestionItem/QuestionItem";

const api = new ApiService();

const EditVideoModal = (props) => {
   const [title, setTitle] = useState('');
   const [link, setLink] = useState('');
   const [success, setSuccess] = useState('');
   const [failure, setFailure] = useState('');
   const [questions, setQuestions] = useState([]);
   const [timer, setTimer] = useState(null);
   const [cookie, setCookie] = useState(0);

   useEffect(() => {
      if (props.video_training_id) {
         setLink(props.link)
         setTitle(props.title)
         setQuestions(JSON.parse(JSON.stringify(props.questionList)))
         setSuccess(props.success)
         setFailure(props.failure)
         setCookie(props.cookie)
         setTimer(props.timerTime);
      }
   }, [props]);

   const fetchDataVideo = async () => {
      try {
         await api.updateVideo({
            video_training_id: props.video_training_id,
            video_training_name: title,
            success: success,
            failure: failure,
            video: link,
            cookies: cookie,
            timer: dayjs(timer, 'mm:ss').format('00:mm:ss'),
         });

         if (JSON.stringify(props.questionList) !== JSON.stringify(questions)) {
            questions.map((value, index) => {
               questions[index].answers = value.answers.map((value) => value.replace(/"/ig, "\\\""))
            })
            const newQuestions = questions.map((value, index) => {
               return {
                  answers: value.answers,
                  question: value.question,
                  right_answers: value.right_answers,
               }
            })
            await api.updateVideoQuestions(props.video_training_id, newQuestions)
         }
         props.toggle();
         props.setQueueNotifications(props.queueNotifications.concat([`${props.title} обновлен`]))
      } catch (e) {
         props.toggle();
         props.setQueueNotifications(props.queueNotifications.concat([`Произошла ошибка!`]))
      }
   }

   const addQuestion = () => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy.push({
         question: "",
         answers: [""],
         right_answers: 0,
      });
      setQuestions(QuestionsCopy);
   };

   const removeQuestion = (id) => {
      let QuestionsCopy = questions.filter(function (elem, index) {
         return index !== id;
      });
      setQuestions(QuestionsCopy)
   };
   const OnChangeQuestion = (id, val) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[id].question = val;
      setQuestions(QuestionsCopy)
   };

   const addAnswer = (qwId) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[qwId].answers.push("");
      setQuestions(QuestionsCopy)
   };
   const removeAnswer = (qwId, ansId) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[qwId].answers = QuestionsCopy[qwId].answers.filter(function (elem, index) {
         return index !== ansId;
      });
      setQuestions(QuestionsCopy)
   };
   const OnChangeAnswer = (qwId, ansId, val) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[qwId].answers[ansId] = val;
      setQuestions(QuestionsCopy)
   };
   const OnChangeRightAnswer = (qwId, answerId) => {
      let QuestionsCopy = questions.slice();
      QuestionsCopy[qwId].right_answers = answerId;
      setQuestions(QuestionsCopy)
   };

   const onChangeTime = (time) => {
      setTimer(time);
   };

   const onChangeCookie = (e) => {
      const cookie = +e.target.value;
      if (cookie > 0) {
         setCookie(cookie);
      } else {
         setCookie(0);
      }
   };

   return (
     <MDBModal isOpen={props.modal} toggle={props.toggle} size="lg">
        <MDBModalHeader toggle={props.toggle}>Изменить видео</MDBModalHeader>

        <MDBModalBody className="pb-2">
           <MDBInput
             value={title}
             className='mb-3'
             onChange={(e) => setTitle(e.target.value)}
             label='Название'
             outline
           />
           <MDBInput
             value={link}
             className='mb-3'
             onChange={(e) => setLink(e.target.value)}
             label='Ссылка на видео'
             outline
           />
           <MDBInput
             value={success}
             type='textarea'
             rows={4}
             style={{
                width: '100%',
                resize: 'vertical'
             }}
             className='mb-3'
             onChange={(e) => setSuccess(e.target.value)}
             label='Сообщение при правильном ответе'
             outline
           />
           <MDBInput
             value={failure}
             type='textarea'
             rows={4}
             style={{
                width: '100%',
                resize: 'vertical'
             }}
             className='mb-3'
             onChange={(e) => setFailure(e.target.value)}
             label='Сообщение при неправильном ответе'
             outline
           />

           <MDBCol xl={'4'}>
              <div className='d-flex flex-column justify-content-center align-items-start mb-4'>
                 <label htmlFor="time">Продолжительность видео (минуты:секунды)</label>
                 <TimePicker
                   id="time"
                   allowClear={false}
                   value={timer}
                   format={'mm:ss'}
                   onChange={onChangeTime}
                 />
              </div>
           </MDBCol>

           <MDBCol xl={'2'} className='mb-3'>
              <MDBInput label='Печеньки'
                        id={props.video_training_id}
                        type='number'
                        value={cookie}
                        onChange={onChangeCookie}
                        min="0"
                        style={{ borderBottom: 'none', fontSize: 18 }}
              />
           </MDBCol>

           <MDBCol size={'12'} className="border-light border-bottom mb-4">
              <h4 className="font-weight-bolder mt-2">Вопросы:</h4>
           </MDBCol>

           <MDBCol size={12}>
              {
                questions && questions.map((elem, id) =>
                  <QuestionItem key={id}
                                answers={elem.answers}
                                right_answers={elem.right_answers}
                                question={elem.question}
                                number={id}
                                OnClickRemove={removeQuestion}
                                OnChangeQuestion={OnChangeQuestion}
                                OnAddAnswer={addAnswer}
                                OnRemoveAnswe={removeAnswer}
                                OnChangeAnswer={OnChangeAnswer}
                                OnChangeRightAnswer={OnChangeRightAnswer}
                  />
                )
              }
           </MDBCol>
           <MDBCol size={12} className="border-light border-top text-right">
              <MDBBtn onClick={() => addQuestion()} size="sm" className="mt-3" outline>Добавить вопрос</MDBBtn>
           </MDBCol>
        </MDBModalBody>

        <MDBModalFooter>
           <MDBBtn color="secondary" size="sm" onClick={props.toggle}>Закрыть</MDBBtn>
           <MDBBtn color="primary" size="sm" onClick={fetchDataVideo}>Изменить</MDBBtn>
        </MDBModalFooter>
     </MDBModal>
   );
};

export default EditVideoModal;