import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import ApiService, { BACK_URL } from "../../../services/ApiService";
import { MDBBtn, MDBCol } from "mdbreact";

const api = new ApiService();
const ProductModal = ({
                          show,
                          close,
                          selectedProduct,
                          addNewProduct,
                          editProduct,
                          setQueueNotifications,
                          queueNotifications
                      }) => {
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [incQuantity, setIncQuantity] = useState('');

    const fileRef = useRef();
    const [file, setFile] = useState();
    const [oldFile, setOldFile] = useState();

    const onUpload = async () => {
        setFile(fileRef.current.files[0]);
    };

    useEffect(() => {
        if (selectedProduct) {
            setProductName(selectedProduct.product_name);
            setDescription(selectedProduct.description);
            setPrice(selectedProduct.price);
            setQuantity(selectedProduct.quantity);
            setOldFile(selectedProduct.file_path);
            setIncQuantity('');

        } else {
            setProductName('');
            setDescription('');
            setPrice('');
            setQuantity('');
            setIncQuantity('');
            setFile(null);
            setOldFile(null);
        }
    }, [selectedProduct])

    const createProduct = async () => {
        let url;
        if (file) {
            let res = await api.uploadFile(file)
            url = await res.text();
        }
        api.addProduct({
            input: {
                product_name: productName,
                description: description,
                price: +price,
                quantity: +quantity
            },
            image: url,
        }).then((data) => {
            if (data.errors && data.errors[0]['message'] === "Ошибка при добавлении продукта") {
                setQueueNotifications(queueNotifications.concat(['Такой продукт уже существует']));
            } else {
                addNewProduct(data)
            }
        })

        setProductName("")
        setDescription("")
        setPrice('')
        setQuantity('')
        setIncQuantity('');
        setFile(null)
        setOldFile(null)
        close();
    }
    const editNewProduct = async () => {
        let url;
        if (file) {
            let res = await api.uploadFile(file)
            url = await res.text();

        }

        api.editProduct({
            product_id: selectedProduct.product_id,
            input: {
                product_name: productName,
                description: description,
                price: +price,
                quantity: +incQuantity,
            },
            image: url ? url : selectedProduct.file_path
        }).then((data) => editProduct(data));

        setProductName("");
        setDescription("");
        setPrice('');
        setQuantity('');
        setIncQuantity('');
        setFile(null);
        setOldFile(null);
        close();
    }

    return (
        <>
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton>
                    {selectedProduct ?
                        <Modal.Title>Редактировать</Modal.Title>
                        :
                        <Modal.Title>Создать</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Выберите файл</Form.Label>
                            <MDBCol size="12" className="py-2">
                                {
                                    oldFile ? (
                                        <div className="">
                                            <img src={BACK_URL + oldFile} width="140" height="140" alt={file}/>
                                            <MDBBtn
                                                color="warning" size="sm"
                                                className="m-0 mt-1 ml-2"
                                                outline
                                                onClick={() => {
                                                    setOldFile(null);
                                                    setFile(null);
                                                    selectedProduct.file_path = null;
                                                }}>Изменить</MDBBtn
                                            >
                                        </div>
                                    ) : (
                                        <MDBCol className="custom-file">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"
                                                          id="inputGroupFileAddon01"
                                                          onClick={() => setFile(null)} style={{ cursor: "pointer" }}
                                                    >
                                                      Отчистить
                                                    </span>
                                                </div>
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="inputGroupFile01"
                                                        aria-describedby="inputGroupFileAddon01"
                                                        onChange={onUpload}
                                                        ref={fileRef}
                                                    />
                                                    <label className="custom-file-label" htmlFor="inputGroupFile01"
                                                           style={{ cursor: "pointer" }}>
                                                        {file ? file.name : "Выберите файл или оставьте пустым"}
                                                    </label>
                                                </div>
                                            </div>
                                        </MDBCol>
                                    )
                                }
                            </MDBCol>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Название</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Название продукта"
                                autoFocus
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Описание</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Цена</Form.Label>
                            <Form.Control
                                type="number"
                                rows={3}
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Количество</Form.Label>
                            <Form.Control
                                type="number"
                                rows={3}
                                value={quantity}
                                readOnly={selectedProduct ? true : false}
                                onChange={(e) => setQuantity(e.target.value)}
                            />
                        </Form.Group>
                        {selectedProduct ?
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>Увеличить общее количество товара на:</Form.Label>
                                <Form.Control
                                    type="number"
                                    rows={3}
                                    value={incQuantity}
                                    onChange={(e) => setIncQuantity(e.target.value)}
                                />
                            </Form.Group> : null
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        selectedProduct ?
                            <Button
                                variant="danger"
                                onClick={editNewProduct}
                            >
                                Изменить
                            </Button>
                            :
                            <Button variant="secondary" onClick={createProduct}>
                                Создать
                            </Button>

                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProductModal;