import {useEffect, useState} from "react";
import ApiService, { BACK_URL } from "../services/ApiService";
import React from "react";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ChangeIngredientModal from './Modals/Menu/ChangeIngredientModal'
import MaterialTable from "material-table";
import { MDBBadge } from "mdbreact";

let options = {
    paging: false,
    draggable: false,
    padding: "dense",
    sorting: false,
    toolbarButtonAlignment: "left",
    actionsCellStyle: {
        padding: "0px 15px"
    },
};
let localization = {
    pagination: {
        labelDisplayedRows: '{from}-{to} of {count}'
    },
    grouping: {
        placeholder: "Перетащите заголовок сюда для группировки",
        groupedBy: "Групировать по:"
    },
    toolbar: {
        nRowsSelected: '{0} row(s) selected',
        searchTooltip: "Поиск",
        searchPlaceholder: "Поиск",
        exportTitle: "Экспорт",
        exportAriaLabel: "Экспорт",
        exportName: "Экспорт в CSV"
    },
    header: {
        actions: ''
    },
    body: {
        deleteTooltip: "Удалить",
        editRow: {
            deleteText: "Подтвердите удаление!"
        },
        emptyDataSourceMessage: "Загрузка данных"
    }
};

export default function DishesAndIngredients(props) {
    let [ingredientRows, setIngredientRows] = useState([]);
    let [isLoading, setIsLoading] = useState(true);
    let [showChangeModat, setShowChangeModat] = useState(false);
    let [selectedIngredient, setSelectedIngredient] = useState();

    let onClickChangeButton = (item) => {
        setShowChangeModat(true);
        setSelectedIngredient(item)
    };

    let onChangeIngredient = async (new_ingredient_id) => {
        let api = new ApiService();
        let res = await api.changeIngredient( {
            dish_category_id: props.selectedCategory.dish_category_id,
            old_ingredient_id: selectedIngredient.ingredient_id,
            new_ingredient_id
        });
        setShowChangeModat(false);
        props.categoryOpdater(props.selectedCategory)
    };

    let [dishRows, setDishRows] = useState([]);
    let dishesColumns = [
        {
            title: 'Фото',
            render: elem => <img src={elem.image} className="img-fluid"/>,
            cellStyle: {
                width: "20%"
            },
        },
        {
            title: 'Название',
            field: 'dish_name',
        },
        {
            title: 'Ингредиенты',
            render: elem => elem.ingredients.map( (val, index) => (
                <MDBBadge color="default" className="mr-1" key={index} >
                    { val.ingredient_name }
                </MDBBadge>
            )),
            cellStyle: {
                width: "40%"
            },
        },
        {
            title: 'Новое',
            render: elem => elem.is_new ? "✅" : "❌",
            align: 'justify',
        },
    ];
    let ingredientsColumns = [
        {
            title: 'Название',
            field: 'ingredient_name',

        }
    ];
    let actions = [
        rowData => ({
            icon: 'edite',
            tooltip: 'Изменить',
            onClick: (event, rowData) => {
                props.onClickDishSettings(rowData)
            }
        }),
        // {
        //     icon: 'add',
        //     iconProps: {
        //         color: "primary"
        //     },
        //     tooltip: 'Добавить',
        //     isFreeAction: true,
        //     onClick: (event) => setShowAddModal(true)
        // }
    ];
    let ingActions = [
        rowData => ({
            icon: () => <SyncAltIcon/>,
            tooltip: 'Заменить',
            onClick: (event, rowData) => {
                onClickChangeButton(rowData)
            }
        }),
        // {
        //     icon: 'add',
        //     iconProps: {
        //         color: "primary"
        //     },
        //     tooltip: 'Добавить',
        //     isFreeAction: true,
        //     onClick: (event) => setShowAddModal(true)
        // }
    ];
    let editable= {
        onRowDelete: props.removeDish
    };
    useEffect(() => {
        let api = new ApiService();
        let getdata = async () => {
            if (props.selectedCategory) {
                setIsLoading(true);
                let data = await api.getDishesByCategoryId( props.selectedCategory?.dish_category_id );
                if (data.data?.Dishes) {
                    setDishRows(data.data?.Dishes);
                    let set = new Set();
                    let ingredientsRows = [];
                    data.data.Dishes.forEach( item => item.ingredients.forEach( item => {
                            if (!set.has(item.ingredient_id)) {
                                ingredientsRows.push(item)
                                set.add(item.ingredient_id)
                            }
                        })
                    );
                    ingredientsRows.sort((a, b) => {
                        if (a.ingredient_name < b.ingredient_name) {
                            return -1;
                        }
                        if (a.ingredient_name > b.ingredient_name) {
                            return 1;
                        }
                        return 0;
                    });
                    setSelectedIngredient(ingredientsRows[0]);
                    setIngredientRows(ingredientsRows);
                }
                setIsLoading(false);
            }
        };
        getdata()
    }, [ props.selectedCategory?.dish_category_id, props.rerender ]);

    return (
        <>
            <ChangeIngredientModal
                    toggle={ setShowChangeModat }
                    show={ showChangeModat }
                    item={ selectedIngredient }
                    ingredients={ingredientRows}
                    onClick={ onChangeIngredient }
            />
            <div className={props.index === 0 ? "" : "d-none"}>
                <MaterialTable
                    columns={dishesColumns}
                    isLoading={isLoading}
                    title={"Категория: " + (props.selectedCategory ? props.selectedCategory.dish_category_name : "")}
                    editable={editable}
                    actions={actions}
                    localization={localization}
                    options={options}
                    data={dishRows}
                />
            </div>
            <div className={props.index === 1 ? "" : "d-none"}>
                <MaterialTable
                    columns={ingredientsColumns}
                    isLoading={isLoading}
                    title={"Категория: " + (props.selectedCategory ? props.selectedCategory.dish_category_name : "")}
                    // editable={editable}
                    actions={ingActions}
                    localization={localization}
                    options={options}
                    data={ingredientRows}
                />
            </div>
        </>
    )
}
