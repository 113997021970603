import { useEffect, useState } from "react";
import {
    MDBBtn,
    MDBCol,
    MDBIcon, MDBInput,
    MDBModal, MDBModalBody,
    MDBModalFooter, MDBModalHeader, MDBRow
} from "mdbreact";
import { Dropdown } from 'primereact/dropdown';
import ApiService from "../../../services/ApiService";
import React from "react";
import { MultiSelect } from "primereact/multiselect";
import arrayMove from "array-move";

export default function UserAddEditModal(props) {
    let api = new ApiService();

    //Запрашиваемые данные
    let [positions, setPositions] = useState([]);
    let [stores, setStores] = useState([]);
    let [territories, setTerritories] = useState([]);
    let [listInternshipCourses, setListInternshipCourse] = useState([]);
    const [structuredCoursesData, setStructuredCoursesData] = useState([]);
    //Вводимые данные
    let [user_id, setUser_id] = useState();
    let [user_name, setUser_name] = useState("");
    let [full_name, setFull_name] = useState("");
    let [guid, setGuid] = useState("");
    let [score, setScore] = useState(0);
    let [position_id, setPosition_id] = useState();
    let [store_id, setStore_id] = useState();
    let [territory_id, setTerritory_id] = useState();
    let [is_intern, setIs_intern] = useState();
    let [internship_courses, setInternship_courses] = useState([]);
    const [structuredCourses, setStructuredCourses] = useState([]);
    const [userCourses, setUserCourses] = useState([]);
    //Валидация
    let [validTelegram, setValidTelegram] = useState();
    let [validFio, setValidFio] = useState();
    let [validScore, setValidScore] = useState();

    function isValid() {
        let valid = 0;
        // user_name === "" ? setValidTelegram("invalid") : valid += 1;
        full_name === "" ? setValidFio("invalid") : valid += 1;
        // isNaN(Number(score)) || score === "" ? setValidScore("invalid") : valid += 1;
        position_id === undefined ? alert("Выберите должность") : valid += 1;
        // store_id === undefined ? alert("Выберите ТТ") : valid += 1;
        return valid === 2
    }

    let addUser = () => {
        if (isValid()) {
            api.addUser({
                    input: {
                        user_name,
                        full_name,
                        guid,
                        score,
                        position_id,
                        store_id
                    }
                }
            ).then((data) => {
                if (internship_courses.length !== 0) {
                    let coursesList = internship_courses.map((value, index) => {
                        return {
                            internship_course_id: value,
                            element_index: Number(index),
                            is_mandatory: Boolean(listInternshipCourses.find(value1 => value1.internship_course_id === value).default_is_mandatory),
                        }
                    })
                    return api.addInternshipsForUser(data.data.addUser.user_id, coursesList).then(r => props.addedNewUser(data))
                } else {
                    props.addedNewUser(data)
                }
            })
        }
    };

    let editUser = () => {
        if (isValid()) {
            api.editUser({
                    user_id,
                    input: {
                        user_name,
                        full_name,
                        guid,
                        score: Number(score),
                        position_id,
                        store_id,
                        is_intern: Boolean(is_intern),
                        userCourses
                    }
                }
            ).then((data) => {
                if (internship_courses.length !== 0) {
                    let coursesList = internship_courses.map((value, index) => {
                        return {
                            internship_course_id: value,
                            element_index: Number(index),
                            is_mandatory: Boolean(listInternshipCourses.find(value1 => value1.internship_course_id === value).default_is_mandatory),
                        }
                    })
                    return api.addInternshipsForUser(user_id, coursesList).then(r => props.editedUser(data))
                } else {
                    props.editedUser(data)
                }
            })
        }
    };

    let setDefault_is_mandatory = (id, value) => {
        let old_index = listInternshipCourses.findIndex(value1 => value1.internship_course_id === id)
        let copy = listInternshipCourses.slice()
        copy[old_index] = { ...listInternshipCourses[old_index], ...{ default_is_mandatory: value } }
        setListInternshipCourse(copy)
    }
    let onSortEnd = ({ oldIndex, newIndex }) => {
        setInternship_courses(arrayMove(internship_courses, oldIndex, newIndex))
    };

    useEffect(() => {
        let api = new ApiService();
        api.getSmallPositions().then(data => setPositions(data.data.Positions));
        api.getTerritoryes().then(data => setTerritories(data.data.Territoryes))
        api.getStructuredCourses().then(data => setStructuredCoursesData(data.data.StructuredCourses));
        api.graphQuery(`
            {
              StructuredCourses {
                structured_course_id
                structured_course_name
              }
            }
        `).then(data => {
            setListInternshipCourse(data.data.StructuredCourses);
        })
    }, []);

    // Когда выбрали территорию, подгружаем точки для нее
    useEffect(() => {
        if (territory_id) {
            api.getStoresByTerritoryId([territory_id]).then(data => setStores(data.data.Stores));
        }
    }, [territory_id]);

    useEffect(() => {
        if (props.user) {
            setUser_id(props.user.user_id);
            setUser_name(props.user.user_name);
            setFull_name(props.user.full_name);
            setScore(props.user.score);
            setGuid(props.user.guid);
            setPosition_id(props.user.position.position_id);
            setStore_id(props.user.store.store_id);
            setTerritory_id(props.user.store.territory.territory_id);
            setIs_intern(props.user.is_intern);
            setUserCourses([...props.user.curses_info.active_internship.map(val => val.structured_course_id), ...props.user.curses_info.completed_internships.map(val => val.structured_course_id)])
        }
    }, [props.user]);

    return (
        <MDBModal size="lg" isOpen={props.show} toggle={() => props.toggle(false)}>
            <MDBModalHeader toggle={() => props.toggle(false)}
                            className="py-3 shadow-sm">{props.user ? "Редактирование пользователя" : "Новый пользователь"}</MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    <MDBCol className="mx-auto px-3">
                        <form>
                            <div className="grey-text">
                                {
                                    props.user && (<MDBInput
                                        label="Телеграм логин"
                                        icon="telegram"
                                        iconBrand={true}
                                        group
                                        type="text"
                                        className={"mb-3"}
                                        value={user_name}
                                        onChange={(e) => setUser_name(e.target.value)}
                                    />)
                                }
                                <MDBInput
                                    label="ФИО"
                                    icon="user"
                                    group type="text"
                                    className={"mb-3 " + validFio}
                                    value={full_name}
                                    onChange={(e) => setFull_name(e.target.value)}
                                />
                                <MDBInput
                                    label="1С GUID"
                                    icon="dice-four"
                                    group type="text"
                                    className={"mb-3"}
                                    value={guid}
                                    onChange={(e) => setGuid(e.target.value)}
                                />
                                <MDBCol sm="12" className={!props.user ? "d-none" : "p-0"}>
                                    <MDBInput label="Печеньки" icon="cookie" group type="text"
                                              className={"mb-3"} value={score}
                                              onChange={(e) => setScore(e.target.value)}/>
                                </MDBCol>
                                <MDBRow className="mb-3">
                                    <MDBCol sm="1">
                                        <MDBIcon icon="user-cog" className="mt-1" style={{ fontSize: "1.7em" }}/>
                                    </MDBCol>
                                    <MDBCol sm="11">
                                        <Dropdown value={position_id}
                                                  options={positions}
                                                  optionLabel="position_name"
                                                  optionValue="position_id"
                                                  onChange={(e) => setPosition_id(e.target.value)}
                                                  placeholder="Должность"
                                                  className="w-100"
                                                  filter
                                                  filterPlaceholder="Поиск"
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="mb-3">
                                    <MDBCol sm="1">
                                        <MDBIcon icon="map-marked-alt" className="mt-1" style={{ fontSize: "1.7em" }}/>
                                    </MDBCol>
                                    <MDBCol sm="11">
                                        <Dropdown value={territory_id}
                                                  options={territories}
                                                  optionLabel="number"
                                                  optionValue="territory_id"
                                                  onChange={(e) => setTerritory_id(e.target.value)}
                                                  placeholder="Территория"
                                                  className="w-100"
                                                  filter
                                                  filterPlaceholder="Поиск"
                                        />
                                    </MDBCol>
                                </MDBRow>
                                {territory_id && (
                                  <MDBRow>
                                      <MDBCol sm="1">
                                          <MDBIcon icon="store" className="mt-1" style={{ fontSize: "1.7em" }}/>
                                      </MDBCol>
                                      <MDBCol sm="11">
                                          <Dropdown value={store_id}
                                                    options={stores}
                                                    optionLabel="number"
                                                    optionValue="store_id"
                                                    onChange={(e) => setStore_id(e.target.value)}
                                                    placeholder="ТТ"
                                                    className="w-100"
                                                    filter
                                                    filterPlaceholder="Поиск"
                                          />
                                      </MDBCol>
                                  </MDBRow>
                                )}

                                {
                                    props.user && (
                                        <MultiSelect optionLabel="structured_course_name" optionValue="structured_course_id"
                                                     options={structuredCoursesData} value={userCourses}
                                                     onChange={(e) => setUserCourses(e.value)}
                                                     placeholder="Курсы"
                                                     filter={true}
                                                     className="col-12 mb-3 mt-3"
                                                     filterPlaceholder="Поиск"
                                        />)
                                }

                                {/*<MDBCol sm="11" className="p-0 align-middle d-flex mt-3">*/}
                                {/*    <label htmlFor="switcher" className="mr-2 my-auto">Стажер</label>*/}
                                {/*    <InputSwitch checked={is_intern}*/}
                                {/*                 onChange={(e) => { setIs_intern(Number(e.value))} }*/}
                                {/*                 id="switcher"*/}
                                {/*    />*/}
                                {/*</MDBCol>*/}
                                {/*{*/}
                                {/*    is_intern ? (<>*/}
                                {/*        <MultiSelect optionLabel="structured_course_name" optionValue="structured_course_id"*/}
                                {/*                              options={listInternshipCourses} value={internship_courses}*/}
                                {/*                              onChange={(e) => setInternship_courses(e.value)}*/}
                                {/*                              placeholder="Адаптации"*/}
                                {/*                              filter={true}*/}
                                {/*                              className="col-12 mb-3 mt-3"*/}
                                {/*                              filterPlaceholder="Поиск"*/}
                                {/*        />*/}
                                {/*        <InternShipDragTable internship_courses={internship_courses}*/}
                                {/*                             listInternshipCourses={listInternshipCourses}*/}
                                {/*                             setDefault_is_mandatory={setDefault_is_mandatory}*/}
                                {/*                             onSortEnd={onSortEnd}*/}
                                {/*        />*/}
                                {/*        </>) : ""*/}
                                {/*}*/}

                            </div>
                        </form>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="py-1 shadow-sm">
                {
                    props.user ?
                        <MDBBtn color="primary" size="sm" onClick={() => editUser()}>Изменить</MDBBtn>
                        :
                        <MDBBtn color="primary" size="sm" onClick={() => addUser()}>Создать</MDBBtn>
                }

            </MDBModalFooter>
        </MDBModal>
    )
}
