import React from 'react';
import styles from './Login.module.css';
import { BACK_URL } from "../../services/ApiService";

const LoginPage = ({setIsAuth}) => {
    const [password, setPassword] = React.useState('');
    const [isValid, setValid] = React.useState();

    const fetchAuth = async () => {
        try {
            const res = await fetch(`${BACK_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({password})
            })
                .then(data => data.json())
                .catch(e => {
                    setValid(false)
                })

            if (res?.token) {
                setValid(true)
                setIsAuth(true);
            }

        } catch (e) {
            console.log(e)
        }
    }

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            await fetchAuth();
        }
    };
    return (
        <div className="container-sm my-5 px-4">
            <div className="card" style={{padding: '1.25rem'}}>
                <h3 className={styles["card__text"]}>Вход</h3>

                <input type="password"
                       value={password}
                       onChange={e => setPassword(e.target.value)}
                       className="form-control"
                       placeholder="Пароль"
                       onKeyDown={handleKeyDown}
                />

                {isValid === false && <p className='red-text'>Пароль неверный</p>}
                {isValid === true && <p className='green-text'>Пароль верный</p>}

                <div className="text-center">
                    <button type="button"
                            onClick={fetchAuth}
                            className="btn btn-outline-orange btn-rounded my-4 waves-effect"
                    >
                        Log in
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;