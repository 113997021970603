// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_card__text__2KUJm {\n    text-align: center;\n    font-size: 1.75rem;\n    font-weight: bold;\n    color: #4f4f4f;\n}", "",{"version":3,"sources":["webpack://src/Pages/Login/Login.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".card__text {\n    text-align: center;\n    font-size: 1.75rem;\n    font-weight: bold;\n    color: #4f4f4f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card__text": "Login_card__text__2KUJm"
};
export default ___CSS_LOADER_EXPORT___;
