import React, { useEffect, useState } from 'react';
import {
    MDBBtn, MDBCol,
    MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead,
} from "mdbreact";
import Panels from "./Panels";
import ApiService, { BACK_URL } from "../services/ApiService";
import RemoveButton from "./RemoveButton";
import InternShipQuestionSender from "./InternShipQuestionSender";
import EditButton from "./EditButton";
import indexDB from '../services/IndexedDbRepository';
import { Editor } from "primereact/editor";
import InfoPopup from "./InfoPopup";
import { Form } from "react-bootstrap";
import { formateTextForTg } from "../utils/formateTextForTg";


let DayItem = (props) => {
    return (
        <MDBRow className="no-gutters mb-3">
            <MDBCol size="11 pr-2">
                <MDBInput label="Название" icon="calendar-alt" group type="text" className="mb-3" value={props.name} onChange={ (e) => props.setValue(props.number, e.target.value) }/>
            </MDBCol>
            <MDBCol size="1" className="pt-3">
                <RemoveButton onClick={ () => props.delete(props.number)}/>
            </MDBCol>
            <MDBCol size={10} className="mx-auto mx-0 ">
                <MDBTable small striped className="white p-0 m-0" responsive>
                    <MDBTableHead >
                        <tr className="">
                            <th>Картинка</th>
                            <th>Вопрос</th>
                            <th>Ответы</th>
                            <th></th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            props.questions.map((value, index) => (
                                <tr key={index}>
                                    <td>
                                        <img src={value.file ? BACK_URL + URL.createObjectURL(value.file) : ""} alt="" className="img-fluid" style={{maxHeight: 120, maxWidth: 300}}/>
                                    </td>
                                    <td>
                                        <span dangerouslySetInnerHTML={createMarkup(value.question)}/>
                                    </td>
                                    <td >
                                        {value.answers.map((valueQ, index2) => (
                                            <>
                                                <Panels text={valueQ} key={index2} color={index2 === value.right_answer ? "success-color" : "red"} className={index2 > 0 ? "mt-1 z-depth-0" : "z-depth-0"} />
                                            </>
                                        ))}
                                    </td>
                                    <td>
                                        <div style={{ minWidth: "90px"}}>
                                            <EditButton onClick={props.setEditQuestion} elem={{...value, index, day_id: props.number, day_list_id: props.day_list_id}} className="mr-3" />
                                            <RemoveButton onClick={props.removeQuestion} elem={{...value, index, day_id: props.number, day_list_id: props.day_list_id}} />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </MDBTableBody>
                </MDBTable>
            </MDBCol>
        </MDBRow>
    )
}
function createMarkup(html) {
    return {__html: html?.replace(/<\/?span.*?>/ig, "").replace(/<br>/ig, "").replace(/<p>/ig, "").replace(/<\/p>/ig, "<br>")}
}

const header = (
    <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-strike" aria-label="Strike"></button>
        {/*{props.intern && <button className="ql-link" aria-label="Link"></button>}*/}

        {/*<button className="ql-code-block" aria-label="CodeBlock"></button>*/}
        {/*<button className="ql-color" aria-label="Color"></button>*/}
        {/*<button onClick={ () => setText("<b>#РЕКЛАМА</b>" + (text ? text : "" ))}>#РЕКЛАМА</button>*/}
    </span>
);

const api = new ApiService()
export default (props) => {
    const [db, setDB] = useState();
    const [textCourse, setTextCourse] = React.useState(null);

    useEffect(() => {
        let DB = new indexDB( 'id' )
        setDB(DB);
        let Name = localStorage.getItem("internshipName");
        DB.findAll().then( data => { setDays(data); } );
        if (Name) setInternshipName(Name);
    }, [])

    useEffect(() => {
        if (props.selectedForEdit) {
            let getData = async () => {
                let data = await api.getInternshipCoursesById(props.selectedForEdit);
                setInternshipName(data.data.InternshipCourses[0].internship_course_name)
                setDays(data.data.InternshipCourses[0].internship_days)
            };
            getData()
        }
    }, [props.selectedForEdit])

    //Вводимые данные
    let [internshipName, setInternshipName] = useState("");
    let [days, setDays] = useState([]);

    let [loading, setLoading] = useState(0)

    let [selectForEditQuestion, setEditQuestion] = useState();
    let [isEditable, setIsEditable] = useState(0);

    const [checkTimeCourse, setCheckTimeCourse] = React.useState();
    const [cookies, setCookies] = React.useState(50);

    let setDayName = async (number, name) => {
        const Days = days.slice();
        let data = await db.findById(number.id)
        await db.save({
             id: number.id,
             name: name,
             questions: data.questions
        }, number.id);
        Days[Days.findIndex(value => value.id === number.id)]['name'] = name;

        setDays(Days);
    }

    let addDay = async () => {
        const Days = days.slice();

        let data = await db.save({
            name: "Блок " + (Days.length + 1),
            questions: []
        });

        await db.save({
            id: data,
            name: "Блок " + (Days.length + 1),
            questions: []
        }, data);

        Days.push({
            name: "Блок " + (Days.length + 1),
            questions: [],
            id: data
        });

        setDays(Days);
    }

    let delDay = async (number) => {
        const Days = days.filter( (value,) => number.id !== value.id);
        setEditQuestion(null)
        await db.deleteById(number.id);
        setDays(Days);
    }

    let addQuestion = (day, file, question, answers, right_answer, is_editable, collback) => {
        const Days = days.slice();
        let myDeyIndex = Days.findIndex( ((value, index) => value.name === day))
        if (file) {
           Days[myDeyIndex]["questions"].push({
                file: file,
                question,
                answers,
                right_answer,
                is_editable: is_editable ? 1 : 0
            })
        } else {
            Days[myDeyIndex]["questions"].push({
                    question,
                    answers,
                    right_answer,
                    is_editable: is_editable ? 1 : 0
                })
        }
        db.save({
            id: Days[myDeyIndex].id,
            name: Days[myDeyIndex].name,
            questions: Days[myDeyIndex]["questions"]
        }, Days[myDeyIndex].id)
        setDays(Days);
        setIsEditable(is_editable ? 1 : 0);
        collback()
    }
    let removeQuestion = (qw) => {
        const Days = days.slice();
        Days[qw.day_list_id].questions = Days[qw.day_list_id].questions.filter( (value, index) => qw.index !== index);
        setDays(Days);
        db.save({
            id: Days[qw.day_list_id].id,
            name: Days[qw.day_list_id].name,
            questions: Days[qw.day_list_id]["questions"]
        }, qw.day_id.id)
        setEditQuestion(null)
    }
    let editQuestion = (file, question, answers, right_answer, day, q_number, collback) => {
        const Days = days.slice();
        if (file) {
            Days[day.day_list_id]['questions'][q_number] = {
                file: file,
                question,
                answers,
                right_answer
            }
        } else {
            Days[day.day_list_id]['questions'][q_number] = {
                question,
                answers,
                right_answer
            }
        }
        collback();
        db.save({
            id: Days[day.day_list_id].id,
            name: Days[day.day_list_id].name,
            questions: Days[day.day_list_id]["questions"]
        }, day.id);
        setEditQuestion(null);
        setDays(Days);
    }

    let sendMassage = async () => {
        if(!window.confirm("Создать курс?")) return;
        setLoading(1);
        localStorage.setItem("internshipName", "");
        const newTextCourse = formateTextForTg(textCourse);
        const {data: {addStructuredCourse}} = await api.addStructuredCourse(internshipName, newTextCourse, +cookies, checkTimeCourse);
        for (let day_id in days) {
            let {data: {addStructuredDay}} = await api.addStructuredDay(days[day_id].name, addStructuredCourse, Number(day_id));
            for (let question_id in days[day_id].questions) {
                let obQ = days[day_id].questions[question_id];
                let formData = new FormData();
                let url;
                if (obQ.file) {
                    let res = await api.uploadFile(obQ.file)
                    url = await res.text();
                }
                let newData = await api.addStructuredQuestion({
                    structured_day_id: addStructuredDay,
                    element_index: Number(question_id),
                    QuestionData: {
                        question: formateTextForTg(obQ.question),
                        answers: obQ.answers,
                        right_answers: obQ.right_answer,
                    },
                    image: url,
                    is_editable: obQ.is_editable
                }).catch( (e) => {
                    // addNotifications("Произошла ошибка!");
                    throw e
                });
            }
        }
        // Добавляем файл для курса если он был добавлен
       if (props.file) {
          const formData = new FormData();
          formData.append('file', props.file);
          formData.append('courseId', addStructuredCourse);
          await api.uploadFileCourse(formData);
       }
        setLoading(false);
        setDays([])
        setInternshipName("")
        let DB_days = await db.findAll();
        for (const value of DB_days) {
            await db.deleteById(value.id);
        }
        props.addedInternShip()
    };

    return (
        <MDBRow className="no-gutters p-0 m-0">
            <MDBCol size="12" className="mx-auto px-3 py-2 border-bottom">
                <div onClick={ () => {
                    props.backButton(1);
                }} style={{cursor: "pointer", fontSize: "1.25rem"}} className=""><MDBIcon icon="angle-left" className="mr-2 text-"/> Курсы</div>
            </MDBCol>
            <MDBCol size="12" className="mx-auto px-5 mt-4 mb-2">
                <MDBInput label="Название курса" icon="address-book" group type="text" value={internshipName} onChange={ (e) => {setInternshipName(e.target.value); localStorage.setItem("internshipName", e.target.value)} }/>
            </MDBCol>
            <MDBCol md="12" className="px-2 mb-2">
                <h4 className="text-primary mr-1 mb-0">Печеньки за курс</h4>
                <Form.Control
                    type="number"
                    rows={3}
                    value={cookies}
                    onChange={(e) => setCookies(e.target.value)}
                />
            </MDBCol>

            <MDBCol md="12" className="px-2 mb-2">
                <h4 className="text-primary">Приложенный файл</h4>
                 <div className="input-group">
                     <div className="custom-file">
                         <input
                           type="file"
                           multiple={false}
                           className="custom-file-input"
                           id="inputGroupFile01"
                           aria-describedby="inputGroupFileAddon01"
                           onChange={props.onUpload}
                         />
                         <label className="custom-file-label"
                                htmlFor="inputGroupFile01"
                                style={{ cursor: "pointer" }}
                         >
                            {props.file?.name ? props.file?.name : props.file ? props.file : "Выберите файл или оставьте пустым"}
                         </label>
                     </div>
                     <div className="input-group-prepend">
                         {
                           props.file &&
                           <span className="input-group-text"
                                 id="inputGroupFileAddon01"
                                 onClick={props.clearFileInput}
                                 style={{ cursor: "pointer" }}
                           >
                                   Удалить
                               </span>
                         }
                     </div>
                 </div>
            </MDBCol>

            <MDBCol md="12" className="px-2 mb-2 mt-2 d-flex align-items-center">
                <h4 className="text-primary mr-1 mb-0">Блокировка</h4>
                <InfoPopup text={'При включении после прохождения каждого блока пользователю необходимо будет подождать сутки, чтобы появился доступ к прохождению следующего.'}/>
                <Form.Check
                    value={checkTimeCourse}
                    onChange={(e) => setCheckTimeCourse(e.target.checked)}
                    type="checkbox"
                    checked={checkTimeCourse}
                    label=""
                    className='ml-2'
                />
            </MDBCol>
            <MDBCol size="12" className='d-flex align-items-center'>
                <h4 className="text-primary ml-2 mr-1 mb-0">Описание</h4>
                <InfoPopup text={'Если описание не заполнено, при назначении пользователь получит: "Тебе доступен новый курс: <НАЗВАНИЕ КУРСА>". При заполненном описании сообщение будет выглядеть следующим образом: "Тебе доступен новый курс <НАЗВАНИЕ КУРСА>, там ты узнаешь <ОПИСАНИЕ>"'}/>
            </MDBCol>
            <MDBCol size="12" className="px-2 mt-1 mb-2">
                <Editor style={{height: '150px', fontFamily: "'Roboto', sans-serif", fontSize: "12pt"}}
                        className={'mb-2'}
                        id="editorSuccess"
                        value={textCourse}
                        onTextChange={(e) => setTextCourse(e.htmlValue)}
                        headerTemplate={header}
                />
            </MDBCol>

            <MDBCol size="12" className="border-light border-bottom mb-2">
                <h4 className="text-primary ml-2">Блоки:</h4>
            </MDBCol>

            <MDBCol size={12} className="mx-auto px-5">
                {
                    days.map( (elem, id) => <DayItem name={elem.name} questions={elem.questions} number={elem} day_list_id={id} setValue={setDayName} delete={delDay} removeQuestion={removeQuestion} setEditQuestion={setEditQuestion}/>)
                }
                <MDBBtn onClick={ addDay } size="sm" className="mt-2" outline>Добавить блок</MDBBtn>
            </MDBCol>

            <MDBCol size="12" className="border-light border-bottom my-2">
                <h4 className="text-primary ml-2">{selectForEditQuestion ? "Изменение вопроса" : "Добавление вопроса:"}</h4>
            </MDBCol>

            <MDBCol size={12} className="mx-auto">
                <InternShipQuestionSender days={days.map((value => value.name))} addQuestion={addQuestion} selectForEditQuestion={selectForEditQuestion} editQuestion={editQuestion}/>
            </MDBCol>

            <MDBCol size={12} className="mt-3 grey lighten-4 border-top text-right pr-3">
                {
                    loading ?
                        <div className="spinner-border text-primary ml-3" role="status">
                            <span className="sr-only">Загрузка...</span>
                        </div>
                     : <MDBBtn size="sm" onClick={sendMassage} color="warning" disabled={
                            internshipName === ""
                        }>Создать курс</MDBBtn>
                }
            </MDBCol>
        </MDBRow>
    );
}
