import React from 'react';
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { MDBListGroup, MDBListGroupItem } from "mdbreact";

const MailingPksModal = ({show, close, reportDates, downloadReport}) => {
    return (
        <>
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Отчеты за 10 дней</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MDBListGroup style={{ minWidthL: '22rem' }} light>
                        {reportDates?.map((item) => (
                            <MDBListGroupItem
                                className='d-flex justify-content-between align-items-center'
                                key={item}
                            >
                                Отчет за {item}
                                <div>
                                    <Button onClick={() => downloadReport(item)}>Скачать</Button>
                                </div>
                            </MDBListGroupItem>
                        ))}
                    </MDBListGroup>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default MailingPksModal;