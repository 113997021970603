import React from 'react';

import NavBar from './NavBar/NavBar';
import SideBar from './SideBar/SideBar';
import { BACK_URL } from "../../services/ApiService";

export default function Header(props) {
    let signOut = async () => {
        const res = await fetch(`${BACK_URL}/sing_out`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            })
            .catch( (e) => { console.log(e); throw(e) });
        if (res) {
            window.location.reload()
        }
    };
    return (
        <header>
            <NavBar region={props.region} routes={props.routes} signOut={signOut}/>
            <SideBar region={props.region} routes={props.routes} signOut={signOut}/>
        </header>
    )
}
