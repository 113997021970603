export const traslateErrorTg = (error) => {
    let errorName;
    switch (error) {
        case 'Bad Request: chat not found':
            errorName = 'Пользователь не найден';
            break;
        case 'Bad request: user not found':
            errorName = 'Пользователь не найден';
            break;
        case 'Forbidden: user is deactivated':
            errorName = 'Пользователь деактивирован';
            break;
        case 'Forbidden: bot was kicked':
            errorName = 'Бот был заблокирован';
            break;
        case 'Too many requests':
            errorName = 'Много запросов';
            break;
        case 'Bad request: Invalid file id':
            errorName = 'file_id не найден';
            break;
        case 'Bad request: Message not modified':
            errorName = 'Сообщение не изменено';
            break;
        case 'Bad Request: message text is empty':
            errorName = 'Текст сообщения был пуст';
            break;
        case 'Forbidden: bot was blocked by the user':
            errorName = 'Бот был заблокирован пользователем';
            break;
        default:
            errorName = 'Неопределенная ошибка';
            break;
    }
    return errorName;
}