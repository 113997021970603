import React, { useEffect, useState } from 'react';
import UserPill from "../../components/UserPill";
import { useHistory } from "react-router-dom";
import { LineChart } from "@mui/x-charts/LineChart";
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBDropdown, MDBDropdownItem, MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon,
    MDBRow,
} from "mdbreact";
import './index.css';
import ApiService from "../../services/ApiService";
import MaterialTable from "@material-table/core";
import AtistationInfo from "../../components/Modals/Users/AtistationInfo";
import Notifications from "../../components/Modals/Notifications";
import UserAddEditModal from "../../components/Modals/Users/UserAddEditModal";
import QrCodeModal from "../../components/Modals/Users/QrCodeModal";
import { format, subDays } from "date-fns";
import CheckIcon from "@material-ui/icons/Check";

const api = new ApiService();

let Panels = (props) => (
    <MDBCard color={props.color ? props.color : "info-color"}
             text="white"
             className={"text-center p-0 z-depth-0 " + props.className}
             onClick={props.onClick}
    >
        <MDBCardBody className="p-1">
            {props.children}
        </MDBCardBody>
    </MDBCard>
)

function formatLot(dateString) {
    if (dateString !== null) {
        const [day, month, year, hour, minute] = dateString.split(/[.: ]/);
        return new Date(year, month - 1, day, hour, minute).getTime();
    }
    return -1;
}

function InternshipRows(elem) {
    let internships = []
    elem.active_internship ?
        internships.push(
            <Panels key="0" color="primary-color">
                <div className="border-bottom">
                    ▶️️
                    {" " + elem.active_internship.structured_course_name}
                </div>
                <div className="border-bottom">
                    {elem.active_internship.active_day.structured_day_name}
                </div>
                <div>
                    Вопрос №
                    {elem.active_internship.active_day.active_question.element_index + 1}
                </div>
            </Panels>) :
        elem.completed_internships.map((value, index) => internships.push(
            <Panels key={index} color="success-color" className="mt-1">
                {"✅ " + value.structured_course_name}
            </Panels>
        ))
    elem.missed_internships.map((value, index) => internships.push(
        <Panels key={index} color="warning-color" className="mt-1">
            {"↪️ " + value.structured_course_name}
        </Panels>
    ))
    return internships.map((value, index1) => value)
}

function CursesRows(elem) {
    let internships = [];
    elem.completed_internships.map(value => internships.push(
        <Panels key={value.structured_course_id} color="success-color" className="mt-1">
            {"✅ " + value.structured_course_name}
        </Panels>
    ));
    elem.active_internship.map((value, index) => internships.push(
        <Panels key={index} color="primary-color" className="mt-1">
            {"▶️️ " + value.structured_course_name}
        </Panels>));

    return (
        <MDBDropdown size="sm">
            <MDBDropdownToggle caret color="" className="fontSizerForDropDown m-0 p-2 z-depth-0"
                               disabled={internships.length === 0}>
                {elem.completed_internships.length + "/" + (elem.completed_internships.length + elem.active_internship.length)}
            </MDBDropdownToggle>
            <MDBDropdownMenu color="info" className="zIndexForDropDown p-2 z-depth-1 border-0">
                {
                    internships.map((value, index1) =>
                        <MDBDropdownItem disabled key={index1} className={"py-0 px-0"}>
                            {value}
                        </MDBDropdownItem>
                    )
                }
            </MDBDropdownMenu>
        </MDBDropdown>
    )
}

let options = {
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
    exportDelimiter: ";",
    exportAllData: true,
    exportButton: { pdf: false, csv: true },
    grouping: true,
    padding: "dense",
    toolbarButtonAlignment: "left",
    actionsCellStyle: {
        padding: "0px 15px"
    }
};

const xData = [
    "Page A",
    "Page B",
    "Page C",
    "Page D",
    "Page E",
    "Page F",
    "Page G",
];
export default function IndexPage(props) {
    let history = useHistory();

    const [users, setUsers] = useState(0);
    const [newUsers, setNewUsers] = useState(0);
    const [interns, setInterns] = useState(0);
    const [waits, setWaits] = useState(0);
    const [isComplited, setIsComplited] = useState(0);


    let [showAddModal, setShowAddModal] = useState(false);
    let [qModal, setQModal] = useState(false);
    let [infoIndex, setInfoIndex] = useState(0);

    let [selectedCertification, setSelectedCertification] = useState();

    let [selectedUser, setSelectedUser] = useState();
    let [showEditModal, setShowEditModal] = useState(false);

    let [queueNotifications, setQueueNotifications] = useState([]);

    // Работа с модалкой с qr кодом
    const [showQrModal, setShowQrModal] = useState(false);
    const [selectedUserLink, setSelectedUserLink] = useState({});

    const [reportDates, setReportDates] = useState([]);
    const [userActivity, setUserActivity] = useState([]);
    const [todayNewUsers, setTodayNewUsers] = useState([]);

    const toggleOpen = () => setShowQrModal(!showQrModal);

    //Вызывается при добавлении пользователя
    let addedNewUser = (data) => {
        setShowAddModal(false);
        let new_rows = userRows.concat([]);
        new_rows.unshift(data.data.addUser);
        setUserRows(new_rows);
        setQueueNotifications(queueNotifications.concat(["Пользователь успешно добавлен"]))
    };
    //Произошло изменение пользователя
    let editedUser = (data) => {
        setShowEditModal(false);
        let new_rows = userRows.concat([]);
        let old_item_index = new_rows.findIndex((item, index, array) => {
            if (item.user_id === data.data.editUser.user_id) {
                return true
            }
        });
        new_rows[old_item_index] = data.data.editUser;
        setUserRows(new_rows);
        setQueueNotifications(queueNotifications.concat(["Данные пользователя успешно изменены"]))
    };
    //Произошло удаление пользователя
    let removedUser = async (user) => {
        let api = new ApiService();
        api.removeUser({
                user_id: user.user_id,
            }
        ).then((data) => {
            let removeUserId = data.data.removeUser;
            if (removeUserId >= 0) {
                let new_rows = userRows.concat([]);
                let data = new_rows.filter(value => value.user_id !== removeUserId);
                setUserRows(data);
                setQueueNotifications(queueNotifications.concat(["Пользователь успешно удален"]));
            } else {
                window.alert("Произошла ошибка");
            }
        })
    };

    //Данные для таблицы
    let [userRows, setUserRows] = useState([]);
    let [linkUserRows, setLinkUserRows] = useState([]);
    let [internshipUserRows, setInternshipUserRows] = useState([]);

    let userColumns = [
        {
            title: 'Логин',
            grouping: false,
            field: 'user_name',
        },
        {
            title: 'ФИО',
            grouping: false,
            field: 'full_name',
        },
        {
            title: 'Тер.',
            field: 'store.territory.number',
        },
        {
            title: 'ТТ',
            field: 'store.number',
        },
        {
            title: 'Должность',
            field: 'position.position_name',
        },
        {
            title: 'Очки',
            field: 'score',
            grouping: false,
            type: 'numeric'
        },
        {
            title: 'Курсы',
            grouping: false,
            disableClick: true,
            type: 'numeric',
            render: elem => CursesRows(elem.curses_info),
            customSort: (a, b) => {
                if (a.curses_info.completed_internships.length === b.curses_info.completed_internships.length) {
                    return (a.curses_info.completed_internships.length + a.curses_info.active_internship.length) - (b.curses_info.completed_internships.length + b.curses_info.active_internship.length)
                }
                return a.curses_info.completed_internships.length - b.curses_info.completed_internships.length
            }
        },
        {
            title: 'Меню',
            grouping: false,
            render: rowData => (rowData.completed_dish_categories.no_completed.length === 0 ? "✅" : "❌") + ` (${rowData.completed_dish_categories.completed.length}/${rowData.completed_dish_categories.completed.length + rowData.completed_dish_categories.no_completed.length})`,
            customSort: (a, b) => a.completed_dish_categories.completed.length - b.completed_dish_categories.completed.length
        },
        {
            title: 'Функционал',
            grouping: false,
            render: elem => (elem.completed_custom_categories.no_completed.length === 0 ? "✅" : "❌") + ` (${elem.completed_custom_categories.completed.length}/${elem.completed_custom_categories.completed.length + elem.completed_custom_categories.no_completed.length})`,
            customSort: (a, b) => a.completed_custom_categories.completed.length - b.completed_custom_categories.completed.length
        },
        {
            title: 'Последняя активность',
            field: 'last_activity',
            grouping: false,
            sorting: true,
            customSort: (a, b) => formatLot(a.last_activity) - formatLot(b.last_activity),
        },
        {
            title: 'Видео',
            hidden: true,
            grouping: false,
            render: elem => (elem.completed_video_trainings.no_completed.length === 0 ? "✅" : "❌") + ` (${elem.completed_video_trainings.completed.length}/${elem.completed_video_trainings.completed.length + elem.completed_video_trainings.no_completed.length})`,
            customSort: (a, b) => a.completed_video_trainings.completed.length - b.completed_video_trainings.completed.length
        },
    ]
    let linkUserColumns = [
        {
            title: 'ФИО',
            grouping: false,
            field: 'full_name',
        },
        {
            title: 'Должность',
            field: 'position.position_name',
        },
        {
            title: 'Телефон',
            grouping: false,
            field: 'phone',
        },
        {
            title: 'Тер.',
            field: 'store.territory.number',
        },
        {
            title: 'ТТ',
            field: 'store.number',
        },
        {
            title: 'Дата добавления',
            field: 'became_member_date',
            type: "date",
            customSort: (a, b) => formatLot(a.became_member_date) - formatLot(b.became_member_date),
        },
        {
            title: 'Ссылка',
            field: 'link',
            grouping: false,
            render: rowData => (
                <Panels
                    className="text-bolder pointer"
                    onClick={() => {
                        setSelectedUserLink({ token: rowData.token, fullName: rowData.full_name })
                        setShowQrModal(true);
                    }}>
                    Получить ссылку или QR код
                </Panels>
            )
        }
    ]
    let internshipUserColumns = [
        {
            title: 'Статус',
            render: elem => elem.internship_info.active_internship ? "❌" : "✅"
        },
        {
            title: 'Логин',
            grouping: false,
            field: 'user_name',
        },
        {
            title: 'ФИО',
            grouping: false,
            field: 'full_name',
        },
        {
            title: 'Должность',
            field: 'position.position_name',
        },
        {
            title: 'Телефон',
            grouping: false,
            field: 'phone',
        },
        {
            title: 'Тер.',
            field: 'store.territory.number',
        },
        {
            title: 'ТТ',
            field: 'store.number',
        },
        {
            title: 'Дата начала',
            field: 'became_member_date',
            type: "date",
            customSort: (a, b) => formatLot(a.became_member_date) - formatLot(b.became_member_date)
        },
        {
            title: 'Последняя активность',
            field: 'last_activity',
            grouping: false,
            customSort: (a, b) => formatLot(a.last_activity) - formatLot(b.last_activity),
        },
        {
            title: 'Адаптации',
            grouping: false,
            sorting: false,
            render: elem => InternshipRows(elem.internship_info)
        },
    ]

    const editable = {
        onRowDelete: removedUser
    }
    const actionsForUser = [
        rowData => ({
            icon: 'edite',
            tooltip: 'Изменить',
            onClick: (event, rowData) => {
                setSelectedUser(rowData);
                setShowEditModal(true);
            }
        })
    ];
    const actionsForIntern = [
        rowData => ({
            icon: () => rowData.completed_at ? null : <CheckIcon/>,
            tooltip: 'Перевести в штат',
            onClick: (event, rowData) => transferInterOnStaff(rowData.user_id),
        }),
        rowData => ({
            icon: 'edite',
            tooltip: 'Изменить',
            onClick: (event, rowData) => {
                setSelectedUser(rowData);
                setShowEditModal(true);
            }
        }),
    ];
    const actionsForLink = [
        rowData => ({
            icon: 'edite',
            tooltip: 'Изменить',
            onClick: (event, rowData) => {
                setSelectedUser(rowData);
                setShowEditModal(true);
            }
        }),
        {
            icon: 'add',
            iconProps: {
                color: "primary"
            },
            tooltip: 'Добавить',
            isFreeAction: true,
            onClick: (event) => setShowAddModal(true)
        }
    ];

    //------------------------------------------

    const getUsers = async () => {
        await api.getUsers().then((data) => {
            setUsers(data.data.Users.length);
            setUserRows(data.data.Users);
            setIsComplited(prev => ++prev)
            history.location.state = { userRows }
        });
    }

    const getInterns = async () => {
        api.getInterns().then((data) => {
            setInterns(data.data.Users.length);
            setInternshipUserRows(data.data.Users);
            setIsComplited(prev => ++prev)
        });
    }

    const getLinkers = async () => {
        api.getLinkers().then((data) => {
            setWaits(data.data.Users.length);
            setLinkUserRows(data.data.Users);
            setIsComplited(prev => ++prev)
        });
    }

    useEffect(async () => {
        const initialDates = Array.from({ length: 10 }, (_, index) => format(subDays(new Date(), index), 'dd.MM')).reverse();
        setReportDates(initialDates);

        await getUsers();
        await getInterns();
        await getLinkers();
        api.getLengthNewUsers().then((data) => {
            setNewUsers(data.countUsers.new_users_count);
        })

        await api.getMetrics()
            .then(data => {
                const datesOne = initialDates;
                const datesTwo = initialDates;
                const yActivityUser = datesOne.map(date => data['userActivity'][date] || 0);
                const yNewUser = datesTwo.map(date => data['newUsers'][date] || 0);

                setUserActivity(yActivityUser);
                setTodayNewUsers(yNewUser);
            });
    }, []);

    const transferInterOnStaff = async (userId) => {
        if (!window.confirm("Вы точно хотите перевести сотрудника в штат ?")) {
            return;
        }
        const body = { userId };
        await api.transferOnStaff(body)
            .then(async data => {
                if (data['status'] === 'success') {
                    await getUsers();
                    await getInterns();
                    setIsComplited(prev => --prev);
                    setIsComplited(prev => --prev);
                } else {
                    console.log(data)
                }
            })
            .catch(e => {
                console.log(e);
            });

    }
    return (
        <>
            <QrCodeModal open={showQrModal}
                         toggleOpen={toggleOpen}
                         selectedUser={selectedUserLink}
            />
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <MDBContainer fluid className="mb-3">
                    <MDBRow className="mb-3 mt-xl-3 mt-3 mt-md-0">
                        <MDBCol xl="4" md="6" className="mb-xl-0 mb-md-3 mb-2">
                            <MDBCard className="cascading-admin-card">
                                <div className="admin-up">
                                    <MDBIcon icon="users" className="primary-color"/>
                                    <div className="data">
                                        <p>Пользователи</p>
                                        <h4>
                                            <strong>{users} <span style={{ fontSize: 18 }}>(+{newUsers} за месяц)</span></strong>
                                        </h4>
                                    </div>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol xl="4" md="6" className="mb-xl-0 mb-md-3 mb-2">
                            <MDBCard className="cascading-admin-card">
                                <div className="admin-up">
                                    <MDBIcon icon="user-graduate" className="warning-color"/>
                                    <div className="data">
                                        <p>Стажеры</p>
                                        <h4>
                                            <strong>{interns}</strong>
                                        </h4>
                                    </div>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol xl="4" md="6" className="">
                            <MDBCard className="cascading-admin-card">
                                <div className="admin-up">
                                    <MDBIcon icon="user-plus" className="info-color"/>
                                    <div className="data">
                                        <p>Ожидают авторизации</p>
                                        <h4>
                                            <strong>{waits}</strong>
                                        </h4>
                                    </div>
                                </div>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <MDBContainer fluid className="mb-3">
                    <MDBRow className="mb-3 mt-xl-3 mt-3 mt-md-0">
                        <MDBCol xl="6" md="12" className="mb-xl-0 mb-md-3 mb-2">
                            <MDBCard className="cascading-admin-card">
                                <LineChart
                                    xAxis={[{ data: reportDates, scaleType: "point" }]}
                                    series={[
                                        { curve: "linear", label: 'Активные', data: userActivity, color: '#4e79a7' },
                                    ]}
                                    height={200}
                                />
                            </MDBCard>
                        </MDBCol>

                        <MDBCol xl="6" md="6" className="mb-xl-0 mb-md-3 mb-2">
                            <MDBCard className="cascading-admin-card">
                                <LineChart
                                    xAxis={[{ data: reportDates, scaleType: "point" }]}
                                    series={[
                                        { curve: "linear", label: 'Новые', data: todayNewUsers, color: '#4e79a7' },
                                    ]}
                                    height={200}
                                />
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <AtistationInfo modalIsOpen={qModal} setModalIsOpen={setQModal} certification={selectedCertification}/>
                <Notifications queueNotifications={queueNotifications}/>
                <UserAddEditModal show={showAddModal} toggle={setShowAddModal} addedNewUser={addedNewUser}/>
                <UserAddEditModal show={showEditModal} toggle={setShowEditModal} user={selectedUser}
                                  editedUser={editedUser}/>

                <UserPill
                    setInfoIndex={setInfoIndex}
                    infoIndex={infoIndex}
                    userTable={
                        <MaterialTable
                            columns={userColumns}
                            isLoading={isComplited !== 3}
                            title="Пользователи"
                            editable={editable}
                            actions={actionsForUser}
                            localization={window.localization}
                            options={options}
                            data={userRows}
                            onRowClick={(event, rowData) => window.open("/user/" + rowData.user_id, '_blank')}
                        />
                    }
                    internshipTable={
                        <MaterialTable
                            columns={internshipUserColumns}
                            data={internshipUserRows}
                            isLoading={isComplited !== 3}
                            title="Стажеры"
                            editable={editable}
                            actions={actionsForIntern}
                            localization={window.localization}
                            options={options}
                        />
                    }
                    linkUserTable={
                        <MaterialTable
                            columns={linkUserColumns}
                            data={linkUserRows}
                            isLoading={isComplited !== 3}
                            title="Ожидают авторизации"
                            editable={editable}
                            actions={actionsForLink}
                            localization={window.localization}
                            options={options}
                        />
                    }
                />
            </main>
        </>
    );
}
