import {useState} from "react";
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBModal, MDBModalBody,
    MDBModalFooter, MDBModalHeader, MDBRow
} from "mdbreact";
import ApiService from "../../../services/ApiService";
import React from "react";

let api = new ApiService();
export default (props) => {

    let [internshipDay, setInternshipDay] = useState('');

    let clearFields = () => {
        setInternshipDay('');
    }

    let create = async () => {
        if (internshipDay.length == 0) return props.addNotifications('Введите название блока');
        const index = props.data.length === 0 ? 0 : props.data[props.data.length - 1].element_index + 1;
        await api.addStructuredDay(internshipDay, props.selectedCourse.structured_course_id, index)
            .then(() => {
                props.loadInternshipDaysData(); // обновляем список дней(блоков) у выбранного курса
                props.toggle(); // закрываем модалку
                props.addNotifications("Новый блок успешно добавлен");
            })
            .catch(err => {
                props.toggle();
                props.addNotifications("Новый блок успешно добавлен");
            })
    }

    return (
        <MDBModal isOpen={props.show} toggle={() => props.toggle(false)} hiddenModal={clearFields}>
            <MDBModalHeader toggle={() => props.toggle(false)} className="py-3 shadow-sm">
                Добавить новый день
            </MDBModalHeader>

            <MDBModalBody className="mb-0 pb-0">
                <MDBRow>
                    <MDBCol size={12}>
                        <MDBInput
                            label="Название"
                            icon="calendar-alt"
                            group
                            type="text"
                            className={"mb-3"}
                            value={internshipDay}
                            onChange={(e) => setInternshipDay(e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="py-1 shadow-sm">
                <MDBBtn
                    color="primary"
                    size="sm"
                    onClick={create}
                >Добавить</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    )
}
