import React, {useEffect, useState} from 'react';
import {
    MDBContainer
} from "mdbreact";
import SendMassages from '../../components/SendMassages'
import ApiService from "../../services/ApiService";
import PostsTable from '../../components/PostsTable'
import InfoModal from '../../components/Modals/Posts/InfoModal'
import Notifications from '../../components/Modals/Notifications'

const api = new ApiService();
export default function MassagesPage() {
    let [posts, setPosts] = useState([]);
    let [postsTemporary, setPostsTemporary] = useState([]);
    let [postsHistory, setPostsHistory] = useState([]);
    let [infoModalIsOpen, setInfoModalIsOpen] = useState(false)
    let [selectedPost, setSelectesPost] = useState();
    const [updatedPost, setUpdatedPost] = useState();

    //Очередь уведомлений
    let [queueNotifications, setQueueNotifications] = useState([]);

    const fetch_data = async () => {
        let posts = await api.getPosts();
        setPostsTemporary(posts.postsTemporary);
        setPostsHistory(posts.postsHistory);
    };

    useEffect(async () => {
        await fetch_data();
    }, []);

    let addPost = (post) => {
        let new_arr = post.post.concat(posts)
        setPosts(new_arr)
        let not_arr;
        if (post.post.length > 0) {
            not_arr = [`Сообщение успешно отправленно`]
            setQueueNotifications(queueNotifications.concat([`Сообщение успешно отправленно`]))
        }
        if (post.errors.length > 0 && post.errors[0]['description'] !== "Bad Request: chat not found" && post.errors[0]['description'] !== "Forbidden: bot was blocked by the user") {
            not_arr.push([`Возникла ошибка отправки: \n` + post.errors.map(val => `${val.name}: ${val.description}.`)])
        }
        post.post.length > 0 ? setQueueNotifications(queueNotifications.concat(not_arr)) : setQueueNotifications(queueNotifications.concat([`Сообщение не доставлено`]));
    }

    const selectUpdatedPost = (body, date, postId) => {
        const post = {
            body,
            date,
            postId
        };
        setUpdatedPost(post);
    }

    const deletePost = async (postId) => {
        if (!window.confirm(`Удалить рассылку?`)) return;
        await api.deletePost(postId)
            .then(data => {
                setQueueNotifications(queueNotifications.concat([data.success]));
                if (data.error) {
                    setQueueNotifications(queueNotifications.concat([data.error]))
                } else {
                    setQueueNotifications(queueNotifications.concat([data.success]))
                    fetch_data();
                }
            });
    }

    const deleteSentPost = async (postId) => {
        if (!window.confirm(`Удалить рассылку?`)) return;
        await api.deletePostInTg(postId)
            .then(data => {
                setQueueNotifications(queueNotifications.concat([data.success]));
                if (data.error) {
                    setQueueNotifications(queueNotifications.concat([data.error]))
                } else {
                    setQueueNotifications(queueNotifications.concat([data.success]))
                    fetch_data();
                }
            });
    }

    let onInfo = (post) => {
        setInfoModalIsOpen(true)
        setSelectesPost(post)
    };
    return (
        <main className="mt-5 mx-lg-3 mt-xl-0">
            <Notifications queueNotifications={queueNotifications}/>
            <InfoModal
                selectedPost={selectedPost}
                infoModalIsOpen={infoModalIsOpen}
                setInfoModalIsOpen={setInfoModalIsOpen}
            />
            <MDBContainer fluid>
                <SendMassages addPost={addPost}
                              updatePost={updatedPost}
                              setUodatePost={setUpdatedPost}
                              setQueueNotifications={setQueueNotifications}
                              queueNotifications={queueNotifications}
                              fetchPosts={fetch_data}
                />
                {/*<PostPanels posts={posts.slice(0, 4)} onInfo={onInfo}/>*/}
                <PostsTable posts={posts}
                            postsTemporary={postsTemporary}
                            postsHistory={postsHistory}
                            selectPost={selectUpdatedPost}
                            onInfo={onInfo}
                            deletePost={deletePost}
                            deleteSentPost={deleteSentPost}
                />
            </MDBContainer>
        </main>
    );
}
