export const formateTextForTg = (text) => {
    if (text) {
        return text
          .replace(/<\/?span.*?>/ig, "")
          .replace(/<br>/ig, "")
          .replace(/<p>/ig, "")
          .replace(/<\/p>/ig, "\n")
          .replace(/&nbsp;/g, ' ')
          .replace(/•/ig, "\n\t\t\t• ")
          .replace(/<ul>/ig, "")
          .replace(/<li>/ig, "\t\t\t• ")
          .replace(/<\/li>/ig, "\n")
          .replace(/<\/ul>/ig, "");
    }
    return "";
}