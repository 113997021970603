import React, { useEffect, useRef, useState } from 'react';
import "./IndexCss.css"
import {
    MDBBtn,
    MDBCard, MDBCardBody,
    MDBCardHeader, MDBCardTitle, MDBCol,
    MDBContainer, MDBIcon, MDBRow,
} from "mdbreact";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Notifications from "../../components/Modals/Notifications";
import MaterialTable from "material-table";
import ApiService, { BACK_URL } from "../../services/ApiService";
import ProductModal from "../../components/Modals/Product/ProductModal";
import CheckIcon from "@material-ui/icons/Check";
import { formateDateToString } from "../../utils/formateDateToString";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Skeleton } from "primereact/skeleton";
import { Editor } from "primereact/editor";
import { formateTextForTg } from "../../utils/formateTextForTg";

let options = {
    sorting: true,
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
    grouping: true,
    padding: "dense",
    toolbarButtonAlignment: "left",
    actionsCellStyle: {
        padding: "0px 15px"
    },
    rowStyle: rowData => ({
        backgroundColor:
            rowData.is_completed ? "rgba(141,255,141,0.44)" : "white"
    }),
    emptyRowsWhenPaging: true,
};

const header = (
    <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-strike" aria-label="Strike"></button>
        {/*{props.intern && <button className="ql-link" aria-label="Link"></button>}*/}

        {/*<button className="ql-code-block" aria-label="CodeBlock"></button>*/}
        {/*<button className="ql-color" aria-label="Color"></button>*/}
        {/*<button onClick={ () => setText("<b>#РЕКЛАМА</b>" + (text ? text : "" ))}>#РЕКЛАМА</button>*/}
    </span>
);

const api = new ApiService();

export default function Shop() {
    const [productsRows, setProductsRows] = useState([]); // массив продуктов
    const [bookingsRows, setBookingsRows] = useState([]); // массив заказов
    const [settingsRows, setSettingsRows] = useState([]); // массив настроек магазина
    const [successMessage, setSuccessMessage] = useState(''); // массив настроек магазина
    const [messageDeliveredOrder, setMessageDeliveredOrder] = useState(''); // массив настроек магазина

    const [selectedProduct, setSelectedProduct] = useState(); // Выбранные продукт
    const [show, setShow] = useState(false); // состояние для модального окна Продукта

    const [queueNotifications, setQueueNotifications] = useState([]); // Уведомление

    const [loading, setLoading] = useState(true);

    const fileRefSuccess = useRef();
    const fileRefDelivered = useRef();
    const [fileSuccess, setFileSuccess] = useState();
    const [fileDelivered, setFileDelivered] = useState();
    const [imageSuccess, setImageSuccess] = useState('');
    const [imageDelivered, setImageDelivered] = useState('');


    // Показываем и скрываем модалку товара
    const handleClose = () => {
        setSelectedProduct(null)
        setShow(false)
    };
    const handleShow = () => {
        setShow(true)
    };

    // Удаляем продукт
    const removeProduct = async (product) => {
        if (!window.confirm("Вы точно хотите удалить продукт ?")) {
            return;
        }
        api.removeProduct({ product_id: product.product_id })
            .then((data) => {
                updateGetProducts();
                setQueueNotifications(queueNotifications.concat(["Товар успешно удален"]));
            });
    };

    // Добавляем новый продукт
    const addNewProduct = (data) => {
        let new_rows = productsRows.concat([]);
        new_rows.push(data.data.addProduct);
        setProductsRows(new_rows);
        setQueueNotifications(queueNotifications.concat(["Товар успешно добавлен"]));
    };

    // Редактируем товар
    const editProduct = (data) => {
        let new_rows = productsRows.concat([]);

        let old_item_index = new_rows.findIndex((item, index, array) => {
            if (item.product_id === data.data.editProduct.product_id) {
                return true
            }
        });
        new_rows[old_item_index] = data.data.editProduct;
        updateGetProducts();
        setQueueNotifications(queueNotifications.concat(["Данные товара успешно изменены"]))
    };

    // Завершаем заказ
    const editBooking = ({ booking_id, product_id, user_id }) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        const formattedDatetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        api.editBooking(booking_id, product_id.product_id, user_id.user_id, 1, formattedDatetime)
            .then(data => {
                setQueueNotifications(queueNotifications.concat(["Заказ доставлен"]))
                updateGetBookings();
            })
            .catch(e => {
                setQueueNotifications(queueNotifications.concat(["Не получилось доставить"]))
                updateGetBookings();
            })
    };

    // Получаем ассортимент товаров
    const updateGetProducts = () => {
        api.getProducts().then((data) => {
            setProductsRows(data.data.Products)
        });
    }

    // Получаем заказы и сортируем их
    const updateGetBookings = () => {
        api.getBookings().then((data) => {
            const arr = [...data.data.Bookings];
            arr.sort((a, b) => {
                if (a.is_completed && !b.is_completed) {
                    return 1;
                }
                if (!a.is_completed && b.is_completed) {
                    return -1;
                }
                return b.completed_at - a.completed_at;
            });
            setBookingsRows(arr);
        });
    }

    // При первом запуске получаем продукты, заказы, настройки
    useEffect(() => {
        updateGetProducts();
        updateGetBookings();
        updateGetSettings();

        // если данные не придут в течение 5 сек, убираем спиннер
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, [])

    // когда приходят данные продуктов убираем спиннер
    useEffect(() => {
        if (productsRows.length > 0) {
            setLoading(false);
        }
    }, [productsRows,]);


    const shopBookingsColumn = [
        // {
        //     title: 'Фото',
        //     grouping: false,
        //     field: 'product_id.file_path',
        //     render: rowData => rowData.product_id.file_path ?
        //         <img style={{width: 140, height: 140}} src={rowData.product_id.file_path} alt={rowData.product_id.product_name}/> :
        //         <div style={{opacity: 0.5}}>фотография отсутствует</div>
        // },
        {
            title: 'Название',
            grouping: false,
            field: 'product_id.product_name',
        },
        {
            title: 'Заказчик',
            field: 'user_id.user_name',
        },
        {
            title: 'ФИО',
            field: 'user_id.full_name',
        },
        {
            title: 'Точка',
            field: 'user_id.store.number',
        },
        {
            title: 'Количество',
            field: 'quantity',
            cellStyle: { paddingLeft: 45 },
        },
        {
            title: 'Дата заказа',
            field: 'date',
            cellStyle: { fontSize: 14, width: 120 },
            render: rowData => formateDateToString(+rowData.date)
        },
        {
            title: 'Дата выдачи',
            field: 'date',
            cellStyle: { fontSize: 14 },
            render: rowData => rowData.completed_at ? formateDateToString(+rowData.completed_at) : '',
        },
    ];
    const editable = {
        onRowDelete: removeProduct
    }
    const actionsForBooking = [
        rowData => ({
            icon: () => rowData.completed_at ? null : <CheckIcon/>,
            tooltip: 'Доставить',
            onClick: (event, rowData) => rowData.completed_at ? null : editBooking(rowData),
        }),
    ];


    // Setting Shop
    // Получаем настройки магазина
    const updateGetSettings = () => {
        const api = new ApiService();
        api.getSettingsShop().then(data => {
            setSettingsRows(data.data.ShopSettings);
            setSuccessMessage(data.data.ShopSettings[0] ? data.data.ShopSettings[0]["success_message"]?.replace(/\n/ig, "<br>") : '');
            if (data.data.ShopSettings[0] && data.data.ShopSettings[0]["deliveredOrder_message"] !== null) {
                setMessageDeliveredOrder(data.data.ShopSettings[0]["deliveredOrder_message"]?.replace(/\n/ig, "<br>"));
            }
            setImageSuccess(data.data.ShopSettings[0] ? data.data.ShopSettings[0]["file_success"] : '');
            setImageDelivered(data.data.ShopSettings[0] ? data.data.ShopSettings[0]["file_deliveredOrder"] : '');
        })
    }

    // Сохраняем сообщение об успешном заказе
    const onSaveSettingMessage = async (e) => {
        e.preventDefault();
        const newMessageDeliveredOrder = formateTextForTg(messageDeliveredOrder);
        const newSuccessMessage = formateTextForTg(successMessage);
        let pathSuccess;
        let pathDelivered;
        if (fileSuccess !== undefined && fileSuccess?.length !== 0) {
            let res = await api.uploadFile(fileSuccess[0]);
            pathSuccess = await res.text();
        } else {
            pathSuccess = imageSuccess;
        }
        if (fileDelivered !== undefined && fileDelivered?.length !== 0) {
            let res = await api.uploadFile(fileDelivered[0]);
            pathDelivered = await res.text();
        } else {
            pathDelivered = imageDelivered;
        }

        api.editSettingsShop(newSuccessMessage, newMessageDeliveredOrder, pathSuccess, pathDelivered)
            .then(() => {
                setQueueNotifications(queueNotifications.concat(["Успешно изменили сообщение"]))
            })
            .catch(() => {
                setQueueNotifications(queueNotifications.concat(["Изменение произошло с ошибкой"]))
            })
    }

    const [hasBeen, setHasBeen] = useState(false);
    const onDragEnd = (result) => {
        if (!result.destination) {
            return; // Перетаскивание было отменено
        }

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        const updatedProducts = [...productsRows];
        const [movedItem] = updatedProducts.splice(startIndex, 1);
        updatedProducts.splice(endIndex, 0, movedItem);

        if (JSON.stringify(updatedProducts) !== JSON.stringify(productsRows)) {
            // Проверка на изменение
            setHasBeen(true);
        }

        updatedProducts.map((item, index) => {
            // Присваиваем новый индекс элементам
            item.element_index = index;
        });
        setProductsRows(updatedProducts);
    };

    const onUpdateIndexCourses = async () => {
        for (const item of productsRows) {
            await api
                .editProductIndex({
                    product_id: +item.product_id,
                    element_index: +item.element_index,
                })
                .catch((e) => {
                    setQueueNotifications(queueNotifications.concat(["Произошла ошибка " + e]))
                });
        }
        setHasBeen(false);
        setQueueNotifications(queueNotifications.concat(["Успешно изменен порядок"]))
    };

    const onUpload = () => {
        const fileArrSuccess = Array.from(fileRefSuccess.current.files);
        const fileArrDelivered = Array.from(fileRefDelivered.current.files);
        const containsSuccessImages = fileArrSuccess.some(
            (file) => file.type.startsWith("image/")
        );
        const containsDeliveredImages = fileArrDelivered.some(
            (file) => file.type.startsWith("image/")
        );

        if (containsSuccessImages || containsDeliveredImages) {
            setFileSuccess(fileRefSuccess.current.files);
            setFileDelivered(fileRefDelivered.current.files);
        } else {
            alert("Выберите только фотографии");
            const emptyFileList = new DataTransfer().files;
            fileRefSuccess.current.files = emptyFileList;
            fileRefDelivered.current.files = emptyFileList;
        }
    };

    const [index, setIndex] = useState(0);
    return (
        <>
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <ProductModal
                    show={show}
                    close={handleClose}
                    selectedProduct={selectedProduct}
                    editProduct={editProduct}
                    addNewProduct={addNewProduct}
                    setQueueNotifications={setQueueNotifications}
                    queueNotifications={queueNotifications}
                />
                <Notifications queueNotifications={queueNotifications}/>

                <MDBContainer fluid>
                    <MDBCard className="mb-2">
                        <MDBCardTitle className="p-3">
                            Магазин
                            {index === 0 && (
                                <>
                                    <MDBIcon
                                        fas
                                        icon="plus"
                                        onClick={() => {
                                            handleShow(true)
                                        }}
                                        style={{ cursor: "pointer", fontSize: "1.25rem" }}
                                        className="ml-3"
                                    />
                                    <MDBIcon
                                        fas
                                        icon="sync-alt"
                                        onClick={() => onUpdateIndexCourses()}
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "1.25rem",
                                            color: hasBeen ? "red" : "",
                                        }}
                                        className="ml-4"
                                    />
                                </>
                            )}
                        </MDBCardTitle>
                        <MDBCardHeader className="p-0 vert">
                            <Tabs
                                value={index}
                                onChange={(event, index) => setIndex(index)}
                                className="w-100"
                            >
                                <Tab label="Ассортимент" className="col" style={{ maxWidth: "none" }}/>
                                <Tab label="Заказы" className="col" style={{ maxWidth: "none" }}/>
                                <Tab label="Настройки" className="col" style={{ maxWidth: "none" }}/>
                            </Tabs>

                        </MDBCardHeader>
                    </MDBCard>
                    <MDBCard className="mb-2">
                        <MDBCardBody className="p-0">
                            <div className={index === 0 ? "" : "done"}>
                                <div className={index === 0 ? "" : "d-none"}>
                                    <MDBRow
                                        className="align-items-center justify-content-end font-weight-bold border-bottom"
                                        style={{
                                            width: "100%",
                                            // marginLeft: "20px"
                                        }}
                                    >
                                        <MDBCol md="1" className='border-right'>
                                            <span className='ml-3'>Действия</span>
                                        </MDBCol>
                                        <MDBCol
                                            md="2"
                                            className='border-right'
                                        >
                                            <span className='ml-4'>Картинка</span>
                                        </MDBCol>
                                        <MDBCol md="3" className='border-right'>
                                                <span className='font-weight-bold'
                                                      style={{ fontSize: '1.2em' }}>Название</span>
                                        </MDBCol>
                                        <MDBCol md="1" className='border-right'>
                                            <span style={{ fontSize: '1.2em' }}>Количество</span>
                                        </MDBCol>
                                        <MDBCol md="2" className='border-right'>
                                            <span style={{ fontSize: '1.2em' }}>Цена</span>
                                        </MDBCol>
                                        <MDBCol md="3">
                                            <MDBCardBody>
                                                <span style={{ fontSize: '1.2em' }}>Описание</span>
                                            </MDBCardBody>
                                        </MDBCol>
                                    </MDBRow>
                                    <DragDropContext onDragEnd={(res) => onDragEnd(res)}>
                                        <Droppable droppableId="questions">
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {productsRows.map((item, index) => (
                                                        <Draggable
                                                            key={item.product_id}
                                                            draggableId={item.product_id.toString()}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MDBRow
                                                                        className="align-items-center justify-content-start"
                                                                        style={{
                                                                            width: "100%",
                                                                            minHeight: "150px",
                                                                            marginBottom: "10px",
                                                                        }}
                                                                    >
                                                                        <MDBCol md="1">
                                                                            <MDBCardBody
                                                                                className="d-flex align-items-center justify-content-center">
                                                                                <MDBIcon
                                                                                    fas
                                                                                    icon="pen"
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        width: 30,
                                                                                        height: 30,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setSelectedProduct(item);
                                                                                        handleShow(true);
                                                                                    }}
                                                                                />
                                                                                <MDBIcon
                                                                                    fas
                                                                                    icon="trash-alt"
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        width: 30,
                                                                                        height: 30,
                                                                                    }}
                                                                                    onClick={() => removeProduct(item)}
                                                                                />
                                                                            </MDBCardBody>
                                                                        </MDBCol>
                                                                        <MDBCol
                                                                            md="2"
                                                                        >
                                                                            {item.file_path ? (
                                                                                <img
                                                                                    src={BACK_URL + item.file_path}
                                                                                    alt={item.product_id}
                                                                                    style={{
                                                                                        width: "70%",
                                                                                        borderRadius: "10%"
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <Skeleton height={'120'} width={'70%'}/>
                                                                            )}
                                                                        </MDBCol>
                                                                        <MDBCol md="3">
                                                                                <span
                                                                                    style={{ fontSize: '1.2em' }}>{item.product_name}</span>
                                                                        </MDBCol>
                                                                        <MDBCol md="1">
                                                                                <span
                                                                                    style={{ fontSize: '1.2em' }}>{item.quantity}шт.</span>
                                                                        </MDBCol>
                                                                        <MDBCol md="2">
                                                                                <span
                                                                                    style={{ fontSize: '1.2em' }}>{item.price}🍪</span>
                                                                        </MDBCol>
                                                                        <MDBCol md="3">
                                                                                <span
                                                                                    style={{ fontSize: '1.2em' }}>{item.description}</span>
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                                <div className={index === 1 ? "" : "d-none"}>
                                    <div className={index === 1 ? "" : "d-none"}>
                                        <MaterialTable
                                            columns={shopBookingsColumn}
                                            isLoading={loading}
                                            title="Заказы"
                                            // editable={editable}
                                            actions={actionsForBooking}
                                            localization={{
                                                body: {
                                                    emptyDataSourceMessage: 'Нет данных',
                                                },
                                                header: {
                                                    actions: 'Действия'
                                                }
                                            }}
                                            options={options}
                                            data={bookingsRows}
                                        />
                                    </div>
                                </div>
                                <div className={index === 2 ? "" : "d-none"}>
                                    <div className={index === 2 ? "settings" : "d-none"}>
                                        <form className={'form-success'}>
                                            <label style={{fontSize: "18px"}} htmlFor="editorSuccess">Сообщение при успешном заказе</label>
                                            <Editor style={{height: '150px', fontFamily: "'Roboto', sans-serif", fontSize: "12pt"}}
                                                    placeholder={'Сообщение при успешном заказе'}
                                                    className={'mb-2'}
                                                    id="editorSuccess"
                                                    value={successMessage}
                                                    onTextChange={(e) => setSuccessMessage(e.htmlValue)}
                                                    headerTemplate={header}
                                            />
                                            {(imageSuccess !== null) ?
                                                <img src={BACK_URL + imageSuccess}
                                                     style={{ width: 80, height: 80, marginRight: 5 }}
                                                     alt={`Изображение при успешном заказе`}
                                                     className='pointer image'
                                                     onClick={() => {
                                                         if (window.confirm('Удалить изображение?')) {
                                                             setImageSuccess(null);
                                                             setFileSuccess(undefined);
                                                         }
                                                     }
                                                     }
                                                /> : null}
                                            <div className="custom-file mb-2">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="inputGroupFile01"
                                                    aria-describedby="inputGroupFileAddon01"
                                                    onChange={onUpload}
                                                    ref={fileRefSuccess}
                                                />
                                                <label className="custom-file-label" htmlFor="inputGroupFile01"
                                                       style={{cursor: "pointer"}}>
                                                    {fileSuccess ? fileSuccess[0]?.name : "Выберите файл или оставьте пустым"}
                                                </label>
                                            </div>

                                            <label className={'mt-4'} style={{fontSize: "18px"}} htmlFor="editorDevilered">Сообщение при доставке</label>
                                            <Editor style={{height: '150px', fontFamily: "'Roboto', sans-serif", fontSize: "12pt"}}
                                                    id='editorDevilered'
                                                    className={'mb-2'}
                                                    value={messageDeliveredOrder}
                                                    onTextChange={(e) => setMessageDeliveredOrder(e.htmlValue)}
                                                    headerTemplate={header}
                                            />
                                            {(imageDelivered !== null) ?
                                                <img src={BACK_URL + imageDelivered}
                                                  style={{ width: 80, height: 80, marginRight: 5 }}
                                                  alt={`Изображение при доставке`}
                                                  className='pointer image'
                                                  onClick={() => {
                                                      if (window.confirm('Удалить изображение?')) {
                                                        setImageDelivered(null);
                                                        setFileDelivered(undefined);
                                                      }
                                                  }
                                                }
                                            /> : null}
                                            <div className="custom-file">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="inputGroupFile01"
                                                    aria-describedby="inputGroupFileAddon01"
                                                    onChange={onUpload}
                                                    ref={fileRefDelivered}
                                                />
                                                <label className="custom-file-label" htmlFor="inputGroupFile01"
                                                       style={{cursor: "pointer"}}>
                                                    {fileDelivered ? fileDelivered[0]?.name : "Выберите файл или оставьте пустым"}
                                                </label>
                                            </div>
                                            <MDBBtn
                                                type='submit'
                                                className='mb-4'
                                                onClick={onSaveSettingMessage}
                                            >
                                                Сохранить
                                            </MDBBtn>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </main>
        </>)
}