import React from 'react';
import {MDBCard, MDBCardBody} from "mdbreact";

const Panels = (props) => {
    return (
        <MDBCard color={props.color ? props.color : ""} className={(props.textAlign ? "text-left" : "text-center") + " px-2 " + props.className + " " + props.border}>
            <MDBCardBody className="p-1">
                {props.text}
            </MDBCardBody>
        </MDBCard>
    );
};

export default Panels;
