import React, {useEffect, useState} from 'react';
import {
    MDBCol,
    MDBContainer, MDBRow, MDBTypography,
} from "mdbreact";
import './index.css';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import ApiService from "../../services/ApiService";
import {Link, useHistory} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

function createMarkup(html) {
    return {__html: html.replace(/\n/ig, "<br>")}
}

export default () => {
    const [FAQPosts, setFAQPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    let history = useHistory()

    useEffect( () => {
        let api = new ApiService();
        async function get() {
            let {data: {FAQContent}} = await api.FAQContent();
            setFAQPosts(FAQContent);
            setLoading(false);
        }
        get()
    }, [])

    return (
        <>
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <MDBContainer fluid>
                    {
                        document.local.admin.permission === 1 ? (
                        <MDBRow>
                            <MDBCol size="12" className="mb-2">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    startIcon={<AddIcon/>}
                                    onClick={() => history.push('/addFAQ')}
                                >
                                    Добавить запись
                                </Button>
                            </MDBCol>
                        </MDBRow>) : ("")
                    }
                    <MDBRow>
                        <MDBCol size="12">
                            {
                                loading ? (
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <>
                                        <MDBTypography tag='h3'>
                                            <strong>FAQ</strong>
                                        </MDBTypography>
                                        {FAQPosts.map((value, index) => (
                                            <div>
                                                <Accordion className={index > 0 ? "mt-3 z-depth-0" : "z-depth-0"}>
                                                    <AccordionSummary
                                                        className="text-dark white my-0">
                                                        <Typography><strong>{value.faq_category_name}</strong></Typography>
                                                    </AccordionSummary>
                                                </Accordion>
                                                {
                                                    value.faq_content.map((value, index) => (
                                                        <Accordion key={index} className="pl-3 z-depth-0">
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon/>}
                                                            >
                                                                {
                                                                    document.local.admin.permission === 1 ? (<Link to={"/editFAQ/" + value.faq_content_id}>
                                                                        <IconButton aria-label="Изменить" color="inherit"
                                                                                    size="small" className="mr-1">
                                                                            <EditIcon fontSize="small"
                                                                                      style={{color: "#202120"}}/>
                                                                        </IconButton>
                                                                    </Link>) : ""
                                                                }
                                                                <Typography>{value.faq_content_question}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography>
                                                                    <span
                                                                        dangerouslySetInnerHTML={createMarkup(value.faq_content_answer)}/>
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                }
                                            </div>
                                        ))}

                                    </>
                                )
                            }
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </main>
        </>
    );
}
