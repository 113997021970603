import React, { useState } from 'react';
import QRCode from "react-qr-code";
import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter, MDBContainer, MDBCard, MDBCardBody
} from 'mdbreact';

let Panels = (props) => (
    <MDBCard color={props.color ? props.color : "info-color"}
             text="white"
             className={"text-center p-0 z-depth-0 " + props.className}
             onClick={props.onClick}
    >
        <MDBCardBody className="p-1" style={{fontSize: 21}}>
            {props.children}
        </MDBCardBody>
    </MDBCard>
)

const QrCodeModal = ({ selectedUser, open, toggleOpen }) => {
    const [isQrCode, setQrCode] = useState(false);
    const [isLink, setIsLink] = useState(false);

    const showQrCode = () => setQrCode(!isQrCode);
    const showLink = () => setIsLink(!isLink);

    const closeModal = () => {
        setQrCode(false);
        setIsLink(false);
        toggleOpen();
    }

    return (
        <>
            <MDBContainer>
                <MDBModal isOpen={open} toggle={closeModal}>
                    <MDBModalHeader toggle={closeModal}>Добавление пользователя в бот</MDBModalHeader>
                    <MDBModalBody className='d-flex flex-column align-items-center justify-content-center'>
                        <div className='text-center'>
                            Внимание! <br/>
                            QR код можно считать только один раз!<br/>
                            Это должен сделать <strong>{selectedUser.fullName}</strong> со своего телефона!<br/>
                            В случае, если отсутствует возможность считать QR код,<br/> необходимо передать сотруднику ссылку<br/>
                            Ссылку, как и qr код, можно открыть только один раз
                        </div>
                        {
                            isQrCode &&
                            <div className="mt-3" style={{width: '100%', height: '100%'}}>
                                <QRCode style={{width: '100%', height: '100%'}} value={"https://telegram.me/swquiz_bot?start=3-" + selectedUser.token}/>
                            </div>
                        }
                        {
                            isLink &&
                            <Panels
                                className="text-bolder mt-3">
                                {"https://telegram.me/swquiz_bot?start=3-" + selectedUser.token}
                            </Panels>
                        }
                    </MDBModalBody>
                    <MDBModalFooter className='d-flex justify-content-center' >
                        <MDBBtn style={{width: '44%'}} color="secondary" onClick={showQrCode}>QR код</MDBBtn>
                        <MDBBtn style={{width: '44%'}} color="secondary" onClick={showLink}>Ссылка</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        </>
    );
};

export default QrCodeModal;