// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VideoList_listElem__3jUhh {\n    text-decoration: none;\n    transition: 0.2s;\n}\n.VideoList_button__L4Nnz {\n    display: block;\n    background: none;\n    border: none;\n}\n\n", "",{"version":3,"sources":["webpack://src/Pages/VideoPage/VideoList/VideoList.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".listElem {\n    text-decoration: none;\n    transition: 0.2s;\n}\n.button {\n    display: block;\n    background: none;\n    border: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listElem": "VideoList_listElem__3jUhh",
	"button": "VideoList_button__L4Nnz"
};
export default ___CSS_LOADER_EXPORT___;
