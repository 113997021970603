import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBContainer, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle,
} from "mdbreact";
import "./IndexCss.css"
import MaterialTable from "material-table";
import ApiService from "../../services/ApiService";
import Notifications from "../../components/Modals/Notifications";
import PositionsAddEditModal from "../../components/Modals/Position/PositionsAddEditModal";
import PositionsWelcomeModal from "../../components/Modals/Position/PositionsWelcomeModal";
import PositionsWelcomeModalToIntern from "../../components/Modals/Position/PositionsWelcomeModalToIntern";

function DropDownMenus(props) {
    return (
        <MDBDropdown size="sm" dropright>
            <MDBDropdownToggle caret color="" className="fontSizerForDropDown m-0 p-2 z-depth-0" disabled={props.disable}>
                {props.title}
            </MDBDropdownToggle>
            <MDBDropdownMenu color="info" className="zIndexForDropDown p-0">
                { props.data.map( (val, index) => (
                    <MDBDropdownItem disabled key={index} className={"text-dark py-0 px-2 border-bottom " + (index % 2 > 0 ? "grey lighten-3" : "")}>{ val[props.path] }</MDBDropdownItem>
                )) }
            </MDBDropdownMenu>
        </MDBDropdown>
    )
}
function DropDownInternship(props) {
    return (
        <MDBDropdown size="sm" >
            <MDBDropdownToggle caret color="" className="fontSizerForDropDown m-0 p-2 z-depth-0" disabled={props.disable}>
                {props.title}
            </MDBDropdownToggle>
            <MDBDropdownMenu color="info" basic className="zIndexForDropDown p-0">
                { props.data.map( (val, index) => (
                    <MDBDropdownItem disabled key={index} className={"text-dark py-0 px-2 border-bottom " + (index % 2 > 0 ? "grey lighten-3" : "")}>{ val[props.path] } {val.default_is_mandatory ? "❗" : ""}</MDBDropdownItem>
                )) }
            </MDBDropdownMenu>
        </MDBDropdown>
    )
}

export default function PositionPage() {
    let [showAddModal, setShowAddModal] = useState(false);
    let [showEditModal, setShowEditModal] = useState(false);
    let [showWelcomeModal, setShowWelcomeModal] = useState(false);
    let [showWelcomeStateModal, setShowWelcomeStateModal] = useState(false);
    let [isLoading, setIsLoading] = useState(true)

    let [queueNotifications, setQueueNotifications] = useState([]);
    let [selectedPosition, setSelectedPosition] = useState();
    let [selectedPositionState, setSelectedPositionState] = useState();

    // Данные для таблицы
    let [rows, setRows] = useState([]);
    useEffect(() =>  {
        let api = new ApiService();
        let getdata = async () => {
            let data = await api.getPositions();
            let rows = [];
            data.data.Positions.forEach((elem, index) => {
                elem.is_master ?
                    rows.unshift(elem)
                    :
                    rows.push(elem);
            });
            setRows(rows);
            setIsLoading(false)
        };
        getdata()
    }, []);
    let columns = [
        {
            title: 'Название',
            field: 'position_name',
        },
        {
            title: 'Отправление рейтингов',
            render: elem => (<div className="mt-2 mb-0">{elem.send_reating ? "✔️" : "❌"}</div>)
        },
        {
            title: 'Меню',
            sorting: false,
            render: elem => (<DropDownMenus data={elem.dish_categories}
                                            title={elem.dish_categories.length > 0 ? elem.dish_categories.length : "0"}
                                            disable={!elem.dish_categories.length} path="dish_category_name"/>),
        },
        {
            title: 'Функционал',
            sorting: false,
            render: elem => (<DropDownMenus data={elem.custom_categories} title={elem.custom_categories.length ? elem.custom_categories.length : "0"}
                                            disable={!elem.custom_categories.length} path="custom_category_name"/>),
        },
        {
            title: 'Видео',
            sorting: false,
            render: elem => (<DropDownMenus data={elem.video_trainings}
                                            title={elem.video_trainings.length ? elem.video_trainings.length : "0"}
                                            disable={!elem.video_trainings.length} path="video_training_name"/>)
        },
        {
            title: 'Аттестации',
            sorting: false,
            render: elem => (<DropDownMenus data={elem.certifications} title={elem.certifications.length ? elem.certifications.length : "0"}
                                            disable={!elem.certifications.length}
                                            path="certification_name"/>)
        },
        {
            title: 'Курсы',
            sorting: false,
            render: elem => (<DropDownMenus data={elem.internship_courses.filter(val => !val.default_is_internship)}
                                            title={elem.internship_courses.filter(val => !val.default_is_internship).length ? elem.internship_courses.filter(val => !val.default_is_internship).length : "0"}
                                            disable={!elem.internship_courses.filter(val => !val.default_is_internship).length} path="structured_course_name"/>)
        },
        {
            title: 'Адаптации (❗️- обязательная)',
            sorting: false,
            render: elem => (<DropDownInternship data={elem.internship_courses.filter(val => val.default_is_internship)}
                                                 title={elem.internship_courses.filter(val => val.default_is_internship).length ? elem.internship_courses.filter(val => val.default_is_internship).length : "0"}
                                                 disable={!elem.internship_courses.filter(val => val.default_is_internship).length} path="structured_course_name"/>),
        },
        {
            title: 'Количество пользователей',
            field: 'countTodayActivity'
        },
        {
            title: 'Сообщение при стажировке',
            render: elem =>(<MDBBtn size="sm" color="" className="m-0 z-depth-0 welcomeButton" onClick={ () => {setShowWelcomeModal(true); setSelectedPosition(elem)}}>Просмотр</MDBBtn>),
            sorting: false,
        },
        {
            title: 'Сообщение при переводе в штат',
            render: elem =>(<MDBBtn size="sm" color="" className="m-0 z-depth-0 welcomeButton" onClick={ () => {setShowWelcomeStateModal(true); setSelectedPositionState(elem)}}>Просмотр</MDBBtn>),
            sorting: false,
        },
    ]

    //Настройки таблици
    let actions = [
        rowData => ({
            icon: 'edite',
            tooltip: 'Изменить',
            onClick: (event, rowData) => {
                setSelectedPosition(rowData);
                setShowEditModal(true);
            }
        }),
        {
            icon: 'add',
            iconProps: {
                color: "primary"
            },
            tooltip: 'Добавить',
            isFreeAction: true,
            onClick: (event) => setShowAddModal(true)
        }
    ]
    let editable = {
        onRowDelete: async oldData => {
            let api = new ApiService();
            try {
                let res = await api.removePosition( {position_id: oldData.position_id})
                if (res) {
                    let data = rows.filter( value => value.position_id !== oldData.position_id)
                    setRows(data)
                    setQueueNotifications(queueNotifications.concat(["Раздел успешно удален!"]))
                } else {
                    setQueueNotifications(queueNotifications.concat(["Произошла ошибка!"]))
                }
            } catch (e) {
                setQueueNotifications(queueNotifications.concat(["Произошла ошибка!"]))
            }
        },
    }

    //Вызывается при добавлении должности
    const addedNewPosition = async (data) => {
        setShowAddModal(false);
        let new_rows = rows.concat([]);
        new_rows.unshift(data.data.addPosition);
        setRows(new_rows);
        setQueueNotifications(queueNotifications.concat(["Должность успешно добавлена"]))
    };
    //Произошло изменение должности
    let editedPosition = (data) => {
        setShowEditModal(false);
        let new_rows = rows.concat([]);
        let old_item_index = new_rows.findIndex( (item, index, array) => {
            if (item.position_id === data.data.editPosition.position_id) {
                return true
            }
        });
        new_rows[old_item_index] = data.data.editPosition;
        setRows(new_rows);
        setQueueNotifications(queueNotifications.concat(["Должность успешно изменена"]))
    };
    //Добавлено велком сообщение
    let addedWelcomeMassage = () => {
        setQueueNotifications(queueNotifications.concat(["Сообщение добавлено"]))
    };
    return (
            <main className="mt-5 mx-lg-3 mt-xl-0">
                <Notifications queueNotifications={queueNotifications}/>
                <PositionsAddEditModal show={showAddModal} toggle={setShowAddModal} addedNewPosition={addedNewPosition} />
                <PositionsAddEditModal show={showEditModal} toggle={setShowEditModal} position={selectedPosition} editedPosition={ editedPosition }/>
                <PositionsWelcomeModal show={showWelcomeModal} toggle={setShowWelcomeModal} selectedPosition={selectedPosition} addedWelcomeMassage={addedWelcomeMassage}/>
                <PositionsWelcomeModalToIntern show={showWelcomeStateModal} toggle={setShowWelcomeStateModal} selectedPosition={selectedPositionState} addedWelcomeMassage={addedWelcomeMassage}/>
                <MDBContainer fluid>
                    <MDBCard>
                        <MDBCardBody className='p-0'>
                            <MaterialTable
                                columns={columns}
                                data={rows}
                                isLoading={isLoading}
                                title="Должности"
                                editable={editable}
                                actions={actions}
                                localization={window.localization}
                                options={{
                                    paging: false,
                                    padding: "dense",
                                    toolbarButtonAlignment: "left",
                                    actionsCellStyle: {
                                        padding: "0px 15px"
                                    }
                                }}
                            />
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </main>
    );
}
