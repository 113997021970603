import React from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCol, MDBInput, MDBRow } from "mdbreact";
import AnswerItem from "../AnswerItem/AnswerItem";

const QuestionItem = (props) => {
   return (
     <MDBCard className="mb-4">
        <MDBCardHeader color="" className="mb-0 py-1">
           <MDBRow className="no-gutters align-items-center">
              <MDBCol size={6} >Вопрос {props.number + 1}:</MDBCol>
              <MDBCol size={6} className="text-right"><MDBBtn onClick={() => { props.OnClickRemove(props.number) }} outline color="danger" size="sm">УДАЛИТЬ</MDBBtn></MDBCol>
           </MDBRow>
        </MDBCardHeader>

        <MDBCardBody className="pt-3 pb-0">

           <MDBInput label="Вопрос:" value={props.question} onChange={ (elem) => props.OnChangeQuestion(props.number, elem.target.value) } outline icon="question" />

           <MDBCol size={12} className="border-light border-bottom mb-2 mt-3">
              <h5 className="mt-2">Ответы:</h5>
           </MDBCol>

           {/* Ответы */}
           <MDBCol size={12} className="mb-4 px-0">
              {props.answers.map((elem, id) =>
                <AnswerItem answer={elem}
                            key={id}
                            number={id}
                            qwId={props.number}
                            OnRemoveAnswe={props.OnRemoveAnswe}
                            OnChangeAnswer={props.OnChangeAnswer}
                            OnChangeRightAnswer={ props.OnChangeRightAnswer }
                            right={ props.right_answers }
                />
              )}
           </MDBCol>

        </MDBCardBody>

        <MDBCardFooter small className="py-1">
           <MDBBtn onClick={ () => props.OnAddAnswer(props.number) } size="sm" outline>Добавить ответ</MDBBtn>
        </MDBCardFooter>
     </MDBCard>
   );
};

export default QuestionItem;