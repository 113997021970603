import {MDBBtn, MDBIcon} from "mdbreact";
import React from "react";

export default function EditButton(props) {
    return (
        <MDBBtn outline size="sm" className={"m-0 py-1 pl-2 pr-2 " + props.className} onClick={ () => { props.onClick(props.elem) } }>
            <MDBIcon icon={props.icon ? props.icon : "pen"} className="mx-auto ml-0" size="2x"/>
        </MDBBtn>
    )
}
